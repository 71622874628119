import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import * as fromApp from "../../ngrx/app.reducers";
import { RedirectService } from 'src/app/shared/services/redirect.service';
import { Params, ActivatedRoute } from '@angular/router';
import { ModalService } from 'src/app/shared/components/modal/modal.service';
import { AdminService } from 'src/app/shared/services/admin.service';

@Component({
  selector: 'app-admin-edit-phase-date',
  templateUrl: './editPhaseDate.component.html',
  styleUrls: ['./editPhaseDate.component.scss']
})
export class EditPhaseDateComponent implements OnInit {
  runningYear$: Subscription = new Subscription();
  runningYear: any;
  processId: string = '';
  isLoadingPhaseData: boolean = true;


  notes: string = '';
  endDate: Date = new Date();
  startDate: Date = new Date();

  today: Date =  new Date();

  constructor(
    public translate: TranslateService,
    public adminService: AdminService,
    public redirectService: RedirectService,
    private store: Store<fromApp.AppState>,
    private route: ActivatedRoute,
    private modalService: ModalService
  ) {
    // Salvo l'anno corrente
    this.runningYear$ = this.store.select(fromApp.getRunningYear).subscribe((runningYear) => {
      this.runningYear = runningYear;
      if (this.runningYear) {
        this.route.params
          .subscribe(
            (params: Params) => {
              this.processId = params.processId;
              if (!this.processId) {
                this.isLoadingPhaseData = false;
              } else {
                this.getPhaseData();
              }
            }
          );
      }
    });
  }

  ngOnInit() {
  }

  noteChanged(text: string) {
    this.notes = text;
  }

  getPhaseData() {
    setTimeout(() => {
      this.startDate = new Date();
      this.endDate = new Date();
      this.isLoadingPhaseData = false;
    }, 1500)
  }


  startDateChanged(newDate: Date){
    this.startDate = newDate;
  }

  endDateChanged(newDate: Date){
    this.endDate = newDate;
  }


  onConfirm() {
    this.modalService.open('confirm-edit');
  }

  closeConfirm(confirm?: boolean) {
    this.modalService.close('confirm-edit');
    if (confirm) {

    }
  }

  onCancel() {
    this.redirectService.goBackBrowser();
  }


  ngOnDestroy() {
    if (this.runningYear$) {
      this.runningYear$.unsubscribe();
    }
  }
}