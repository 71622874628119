/*
*    Dichiarazione dei moduli comuni e delle direttive
*/

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AppTranslateModule } from '../translate/translate.module';
import { PaginationComponent } from '../core/pagination/pagination.component';
import { NgxPaginationModule } from 'ngx-pagination';
import { MomentModule } from 'ngx-moment';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { OwlDateTimeModule, OwlNativeDateTimeModule } from '@busacca/ng-pick-datetime';
import { FileUploadModule } from 'ng2-file-upload';
import { FlexLayoutModule } from '@angular/flex-layout';
import { NgSelectModule } from '@ng-select/ng-select';
import { SafeHtmlPipe } from './pipes/safe-html.pipe';
import { ButtonsComponent } from './components/buttons/buttons.component';
import { InputContainerComponent } from './components/input-container/input-container.component';
import { EuropSelectComponent } from './components/alloy-select/alloy-select.component';
import { EuropInputComponent } from './components/alloy-input/alloy-input.component';
import { EuropSwitchComponent } from './components/alloy-switch/alloy-switch.component';
import { EuropCheckComponent } from './components/alloy-check/alloy-check.component';
import { EuropTextAreaComponent } from './components/alloy-text-area/alloy-text-area.component';
import { EuropRadioComponent } from './components/alloy-radio/alloy-radio.component';
import { EuropUploadComponent } from './components/alloy-upload/alloy-upload.component';
import { EuropDropdownItemComponent } from './components/alloy-dropdown-item/alloy-dropdown-item.component';
import { ModalComponent } from './components/modal/modal.component';
import { ModalTextContentComponent } from './components/modal-text-content/modal-text-content.component';
import { EuropDatepickerComponent } from '../core/alloy-datepicker/alloy-datepicker.component';
import { TagCompetenceComponent } from './components/tag-competence/tag-competence.component';
import { TooltipDirective } from './directive/alloy-tooltip.directive';
import { EuropPopUpComponent } from './components/alloy-pop-up/alloy-pop-up.component';
import { CardRadioComponent } from './components/card-radio/card-radio.component';
import { CardFeedbackComponent } from './components/card-feedback/card-feedback.component';
import { ItemListTeamStatusComponent } from './components/item-list-team-status/item-list-team-status.component';
import { ListDocItemComponent } from './components/list-doc-item/list-doc-item.component';
import { AvatarComponent } from './components/avatar-img/avatar-img.component';
import { PageContainerComponent } from './components/page-container/page-container.component';
import { SidenavInformationsComponent } from './components/sidenav-informations/sidenav-informations.component';
// import { KalturaPlayerComponent } from '../core/kaltura-player/kaltura-player.component';
import { ClickOutsideDirective } from './directive/click-outside.directive';
import { TeamSidebarComponent } from './components/team-sidebar/team-sidebar.component';
import { ItemListTeamComponent } from './components/item-list-team/item-list-team.component';
import { EuropAccordionComponent } from './components/alloy-accordion/alloy-accordion.component';
import { BadgeNotificationsComponent } from './components/badge-notifications/badge-notifications.component';
import { GoalCardComponent } from './components/goal-card/goal-card.component';
import { GoalCardDetailComponent } from './components/goal-card-detail/goal-card-detail.component';
import { TruncatePipe } from './directive/truncate.directive';
import { BannerHomeComponent } from './components/banner-home/banner-home.component';
import { BoxHomeComponent } from './components/box-home/box-home.component';
import { HeaderDossierComponent } from './components/header-dossier/header-dossier.component';
import { CareCardsComponent } from './components/care-cards/care-cards.component';
import { UserCardComponent } from './components/user-card/user-card.component';
import { TabDossierComponent } from './components/tab-dossier/tab-dossier.component';
import { ChildContentComponent } from './components/child-content/child-content.component';
import { NoDataPlaceholderComponent } from './components/no-data-placeholder/no-data-placeholder.component';
import { TabLightComponent } from './components/tab-light/tab-light.component';
import { TabSectionComponent } from './components/tab-section/tab-section.component';
import { GoalTitleIndicator } from './components/goals-title-indicator/goals-title-indicator.component';
import { UserInitialPipe } from './pipes/initials.pipe';
import { CardNotifyComponent } from './components/card-notify/card-notify.component';
import { ObjectiveTableComponent } from './components/objective-table/objective-table.component';
import { BadgeWrapperComponent } from './components/badge-wrapper/badge-wrapper.component';
import { CardActionComponent } from './components/card-action/card-action.component';
import { ProfilePageComponent } from '../profilePage/profilePage.component';
import { TeamListComponent } from '../peopleAppraisal/teamList/teamList.component';
import { ItemListTeamForTableComponent } from './components/item-list-team-for-table/item-list-team-for-table.component';
import { CardEvidenceComponent } from './components/card-evidence/card-evidence.component';
import { CreateUpdatePrivateNotePeopleAppraisalComponent } from '../peopleAppraisal/createUpdatePrivateNote/createUpdatePrivateNote.component';
import { CardNotesComponent } from './components/card-notes/card-notes.component';
import { CreateUpdatePrivateNoteComponent } from '../selfAssessment/createUpdatePrivateNote/createUpdatePrivateNote.component';
import { CardInterviewComponent } from './components/card-interview/card-interview.component';
import { CreateUpdatePeerFeedbackPeopleAppraisalComponent } from '../peopleAppraisal/createUpdatePeerFeedback/createUpdatePeerFeedback.component';
import { CreateUpdatePeerFeedbackComponent } from '../selfAssessment/createUpdatePeerFeedback/createUpdatePeerFeedback.component';
import { CardPeerComponent } from './components/card-peer/card-peer.component';
import { PeerFeedbackRequestComponent } from '../peopleAppraisal/peerFeedbackRequest/peerFeedbackRequest.component';
import { MidTermBoxHomeComponent } from './components/mid-term-box-home/mid-term-box-home.component';
import { ObjectiveForMidTermReview } from './components/objective-for-mid-term-review/objective-for-mid-term-review.component';
import { MidTermRecapComponent } from './components/mid-term-recap/mid-term-recap.component';
import { CKEditorModule } from 'ng2-ckeditor';
import { EvaluationComponent } from './components/evaluation-component/evaluation.component';
import { CardPerformanceComponent } from './components/card-performance-level/card-performance-level.component';
import { TagUserComponent } from './components/tag-user/tag-user.component';
import { CalibrationRecapComponent } from './components/calibration-recap/calibration-recap.component';
import { CalibrationLevelComponent } from './components/calibration-level/calibration-level.component';
import { StepperComponent } from './components/stepper/stepper.component';
import { StarRatingComponent } from './components/star-rating/star-rating.component';
import { TextGoalComponent } from './components/text-goal/text-goal.component';
import { CompetenceEvaluationComponent } from './components/competence-evaluation/competence-evaluation.component';
import { PerformerEvaluatorComponent } from './components/performer-evaluator/performer-evaluator.component';
import { PerformerEvaluatorInfoModalContentComponent } from './components/performer-evaluator-info-modal-content/performer-evaluator-info-modal-content.component';
import { EvaluationRatingComponent } from './components/evaluation-rating/evaluation-rating.component';
import { AnagFiltersComponent } from './components/anag-filters/anag-filters.component';
import { PercentageAccordion } from './components/percentage-accordion/percentage-accordion.component';
import { AzurePlayerComponent } from '../core/azure-player/azure-player.component';
import { SidebarComponent } from './components/sidebar/sidebar.component';
import { CompetenceAverageComponent } from './components/competence-average/competence-average.component';
import { CardPeerfeedbackComponent } from './components/card-peerfeedback/card-peerfeedback.component';
import { CardSmartComponent } from './components/card-smart/card-smart.component';
import { CardFeedbackHeaderComponent } from './components/card-feedback-header/card-feedback-header.component';
import { CardFeedbackToManagerComponent } from './components/card-feedback-to-manager/card-feedback-to-manager.component';


@NgModule({
  declarations: [
    PaginationComponent,
    SafeHtmlPipe,
    ButtonsComponent,
    InputContainerComponent,
    EuropSelectComponent,
    EuropInputComponent,
    EuropSwitchComponent,
    EuropCheckComponent,
    EuropTextAreaComponent,
    EuropRadioComponent,
    EuropDropdownItemComponent,
    ModalComponent,
    ModalTextContentComponent,
    EuropUploadComponent,
    EuropDropdownItemComponent,
    EuropDatepickerComponent,
    TagCompetenceComponent,
    CardRadioComponent,
    AvatarComponent,
    TooltipDirective,
    EuropPopUpComponent,
    CardRadioComponent,
    CardFeedbackComponent,
    ItemListTeamStatusComponent,
    ListDocItemComponent,
    PageContainerComponent,
    SidenavInformationsComponent,
    // KalturaPlayerComponent,
    TeamSidebarComponent,
    ItemListTeamComponent,
    ClickOutsideDirective,
    EuropAccordionComponent,
    BadgeNotificationsComponent,
    GoalCardComponent,
    GoalCardDetailComponent,
    TruncatePipe,
    BannerHomeComponent,
    BoxHomeComponent,
    HeaderDossierComponent,
    CareCardsComponent,
    UserCardComponent,
    TabDossierComponent,
    ChildContentComponent,
    NoDataPlaceholderComponent,
    TabLightComponent,
    TabSectionComponent,
    GoalTitleIndicator,
    UserInitialPipe,
    CardNotifyComponent,
    ObjectiveTableComponent,
    ObjectiveForMidTermReview,
    BadgeWrapperComponent,
    CardActionComponent,
    ProfilePageComponent,
    ItemListTeamForTableComponent,
    TeamListComponent,
    CardEvidenceComponent,
    CardNotesComponent,
    CreateUpdatePrivateNotePeopleAppraisalComponent,
    CreateUpdatePrivateNoteComponent,
    CardInterviewComponent,
    ProfilePageComponent,
    CreateUpdatePeerFeedbackPeopleAppraisalComponent,
    CreateUpdatePeerFeedbackComponent,
    CardPeerComponent,
    PeerFeedbackRequestComponent,
    MidTermBoxHomeComponent,
    MidTermRecapComponent,
    EvaluationComponent,
    CardPerformanceComponent,
    TagUserComponent,
    CalibrationRecapComponent,
    CalibrationLevelComponent,
    CardPeerfeedbackComponent,
    StepperComponent,
    StarRatingComponent,
    TextGoalComponent,
    CompetenceEvaluationComponent,
    PerformerEvaluatorComponent,
    PerformerEvaluatorInfoModalContentComponent,
    EvaluationRatingComponent,
    AnagFiltersComponent,
    PercentageAccordion,
    AzurePlayerComponent,
    SidebarComponent,
    CompetenceAverageComponent,
    CardSmartComponent,
    CardFeedbackHeaderComponent,
    CardFeedbackToManagerComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgxPaginationModule,
    FlexLayoutModule,
    AppTranslateModule.forRoot(),
    FileUploadModule,
    MomentModule,
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    AngularSvgIconModule,
    FormsModule,
    NgSelectModule,
    CKEditorModule,
  ],
  exports: [
    CommonModule,
    CardSmartComponent,
    AppTranslateModule,
    FormsModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    FileUploadModule,
    PaginationComponent,
    MomentModule,
    AngularSvgIconModule,
    NgSelectModule,
    SafeHtmlPipe,
    ButtonsComponent,
    InputContainerComponent,
    EuropSelectComponent,
    EuropInputComponent,
    EuropSwitchComponent,
    EuropCheckComponent,
    EuropTextAreaComponent,
    CardFeedbackToManagerComponent,
    EuropRadioComponent,
    EuropDropdownItemComponent,
    ModalComponent,
    ModalTextContentComponent,
    EuropUploadComponent,
    EuropDropdownItemComponent,
    EuropDatepickerComponent,
    TagCompetenceComponent,
    CardRadioComponent,
    AvatarComponent,
    TooltipDirective,
    EuropPopUpComponent,
    CardRadioComponent,
    CardFeedbackComponent,
    CardPeerfeedbackComponent,
    ItemListTeamStatusComponent,
    ListDocItemComponent,
    PageContainerComponent,
    SidenavInformationsComponent,
    // KalturaPlayerComponent,
    ClickOutsideDirective,
    TeamSidebarComponent,
    ItemListTeamComponent,
    EuropAccordionComponent,
    BadgeNotificationsComponent,
    GoalCardComponent,
    GoalCardDetailComponent,
    TruncatePipe,
    BannerHomeComponent,
    BoxHomeComponent,
    HeaderDossierComponent,
    CareCardsComponent,
    UserCardComponent,
    TabDossierComponent,
    ChildContentComponent,
    NoDataPlaceholderComponent,
    TabLightComponent,
    TabSectionComponent,
    GoalTitleIndicator,
    UserInitialPipe,
    CardNotifyComponent,
    ObjectiveTableComponent,
    ObjectiveForMidTermReview,
    BadgeWrapperComponent,
    CardActionComponent,
    ProfilePageComponent,
    ItemListTeamForTableComponent,
    TeamListComponent,
    CardEvidenceComponent,
    CardNotesComponent,
    CreateUpdatePrivateNotePeopleAppraisalComponent,
    CreateUpdatePrivateNoteComponent,
    CardInterviewComponent,
    ProfilePageComponent,
    CreateUpdatePeerFeedbackPeopleAppraisalComponent,
    CreateUpdatePeerFeedbackComponent,
    CardPeerComponent,
    PeerFeedbackRequestComponent,
    MidTermBoxHomeComponent,
    MidTermRecapComponent,
    CKEditorModule,
    EvaluationComponent,
    CardPerformanceComponent,
    TagUserComponent,
    CalibrationRecapComponent,
    CalibrationLevelComponent,
    StepperComponent,
    StarRatingComponent,
    TextGoalComponent,
    CompetenceEvaluationComponent,
    PerformerEvaluatorComponent,
    PerformerEvaluatorInfoModalContentComponent,
    EvaluationRatingComponent,
    AnagFiltersComponent,
    PercentageAccordion,
    AzurePlayerComponent,
    SidebarComponent,
    CompetenceAverageComponent,
    CardFeedbackHeaderComponent
  ]
})

export class SharedModule {
}
