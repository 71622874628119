import { Component, EventEmitter, Input, Output, OnInit, OnDestroy } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AnalyticsService } from '../../services/analytics.service';
import { SenecaResponse } from 'src/commonclasses';
import { ActivatedRoute, Params } from '@angular/router';
import { SharedService } from '../../services/shared.service';
import { ApplicationModalMessage } from 'src/app/core/ngrx/core.reducers';
import { Store } from '@ngrx/store';
import * as fromApp from "../../../ngrx/app.reducers";
import * as CoreActions from "../../../core/ngrx/core.actions";

@Component({
  selector: 'anag-filters',
  templateUrl: 'anag-filters.component.html',
  styleUrls: ['./anag-filters.component.scss']
})
export class AnagFiltersComponent implements OnInit, OnDestroy {
  @Input() processYear?: any;
  @Input() processYearCode?: any;
  @Input() selectedFilters: any;
  @Input() searchByTextPlaceholder?: string;
  @Input() noActiveFilterText?: string;
  @Input() oneFilterText?: string;
  @Input() moreFiltersText?: string;
  @Input() deleteFiltersText?: string;
  @Input() manageFiltersText?: string;
  @Input() applyFiltersText?: string;
  @Input() isLoadingUsers: boolean = false;
  @Input() showSubCompany: boolean = true;
  @Input() showCdc: boolean = true;
  @Input() showAreaRef: boolean = true;
  @Input() showDepartment: boolean = true;
  @Input() showTeam: boolean = true;
  @Input() showSubTeam: boolean = false;
  @Input() showPosition: boolean = true;
  @Input() showSupervisor: boolean = true;
  @Input() showContractual: boolean = true;
  @Input() showOffice: boolean = true;
  @Input() showSelfAssessmentStatus: boolean = true;
  @Input() showAppraisalStatus: boolean = true;
  @Input() showCalibrationStatus: boolean = true;
  @Input() searchEnabled: boolean = true;
  @Input() keepFilters: boolean = false; // salvare selectedFilters in 'savedFilters' nel session storage alla ricerca

  @Output() searchedTextChanged: EventEmitter<any> = new EventEmitter<any>();
  @Output() updateSelectedFilters: EventEmitter<any> = new EventEmitter<any>();
  @Output() updateSelectedFiltersNumber: EventEmitter<any> = new EventEmitter<any>();
  @Output() startSearch: EventEmitter<any> = new EventEmitter<any>();

  filtersObject: any = {};
  isFilterOpened: boolean = false;
  isLoadingFilters: boolean = true;
  getFiltersData$: any;
  translations: any;
  selectedFiltersNumber: number = 0;
  searchedText: string = '';
  isPeopleAppraisal: boolean = false;

  constructor(
    private store: Store<fromApp.AppState>,
    private sharedService: SharedService,
    private analyticsService: AnalyticsService,
    private translate: TranslateService,
    private route: ActivatedRoute
  ) {
  }

  ngOnInit() {
    this.translate.get([
      'filter.ALL',
      'filter.M',
      'filter.F',
      'monitoring.filters.SENT',
      'monitoring.filters.NOT_SENT',
      'monitoring.WAITING',
      'monitoring.TO_SEND',
      'monitoring.SENT'
    ]).subscribe((translations) => {
      this.translations = translations;

      this.route.params
        .subscribe(
          (params: Params) => {
            if (window.location.href.indexOf('peopleAppraisal') > -1) {
              this.isPeopleAppraisal = true;
            }
            // Se non mi arrivano i dati in input, li prendo dall'url
            if (!this.processYear) {
              this.processYear = params.processYear;
            }
            // Se non mi arrivano i dati in input, li prendo dall'url
            if (!this.processYearCode) {
              this.processYearCode = params.processYearCode;
            }
            this.getFilters();
          })
    })
  }

  onSearch() {
    // Invio un evento di tracciamento a Google Tag Manager per Google Analytics
    // this.analyticsService.sendSearchEvent(this.searchedText || '');
    this.emitStartSearch(true);
  }

  openFiltersSection() {
    this.isFilterOpened = !this.isFilterOpened;
  }

  applyFilters() {
    this.isFilterOpened = false;
    this.emitStartSearch(true);
  }

  // recupera il distinct dei filtri disponibili
  getFilters() {
    this.isLoadingFilters = true;

    if (this.getFiltersData$) {
      this.getFiltersData$.unsubscribe();
    }

    this.getFiltersData$ = this.sharedService.getDistinctFilters(this.processYear, this.processYearCode, this.isPeopleAppraisal)
      .subscribe((data: SenecaResponse<any>) => {
        if (data && data.error) {
          const messageObj: ApplicationModalMessage = {
            modalId: "cal007",
            title: this.translate.instant("generic.WARNING"),
            text: this.translate.instant("errors." + data.error)
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        } else {
          let all = { id: 'all', title: this.translations['filter.ALL'], value: null }
          let statuses = [
            all,
            {
              id: 'sent',
              title: this.translations['monitoring.filters.SENT'],
              value: 'sent',
            },
            {
              id: 'not-sent',
              title: this.translations['monitoring.filters.NOT_SENT'],
              value: 'not-sent',
            }
          ];

          let calibrationStatuses = [
            all,
            {
              id: 'WAITING',
              title: this.translations['monitoring.WAITING'],
              value: ['WAITING'],
            },
            {
              id: 'TO_CHECK',
              title: this.translations['monitoring.TO_SEND'],
              value: ['TO_CHECK', 'TO_SEND'],
            },
            {
              id: 'SENT',
              title: this.translations['monitoring.SENT'],
              value: ['SENT'],
            }
          ]
          this.filtersObject = {
            subcompany: [...[all], ...data.response.socDistacco.map((item: any) => { return { id: item, title: item, value: item } })],
            cdcfinance: [...[all], ...data.response.cdcFinance.map((item: any) => { return { id: item, title: item, value: item } })],
            areaReference: [...[all], ...data.response.ramo.map((item: any) => { return { id: item, title: item, value: item } })],
            department: [...[all], ...data.response.defTpDip.map((item: any) => { return { id: item, title: item, value: item } })],
            team: [...[all], ...data.response.team.map((item: any) => { return { id: item, title: item, value: item } })],
            subTeam: [...[all], ...data.response.subTeam.map((item: any) => { return { id: item, title: item, value: item } })],
            position: [...[all], ...data.response.chief.map((item: any) => { return { id: item, title: item, value: item } })],
            supervisor: [...[all], ...data.response.supervisor.map((item: any) => { return { id: (item.surname + ' ' + item.forename), title: (item.surname + ' ' + item.forename), value: item.userId } })],
            contractual: [...[all], ...data.response.tipologiaContratto.map((item: any) => { return { id: item, title: item, value: item } })],
            office: [...[all], ...data.response.sedeLavoro.map((item: any) => { return { id: item, title: item, value: item } })],
            selfStatus: statuses,
            peopleStatus: statuses,
            calibrationStatus: calibrationStatuses,
          }
          if (this.keepFilters) {
            let savedFilters = sessionStorage.getItem('savedFilters');
            let savedFiltersNumber = sessionStorage.getItem('savedFiltersNumber') || '0';
            if (savedFilters && savedFilters.length) {
              this.selectedFilters = JSON.parse(savedFilters);
              this.selectedFiltersNumber = parseInt(savedFiltersNumber);
            } else {
              this.selectedFilters = {
                subcompany: this.filtersObject.subcompany[0],
                cdcfinance: this.filtersObject.cdcfinance[0],
                areaReference: this.filtersObject.areaReference[0],
                department: this.filtersObject.department[0],
                team: this.filtersObject.team[0],
                subTeam: this.filtersObject.subTeam[0],
                position: this.filtersObject.position[0],
                supervisor: this.filtersObject.supervisor[0],
                contractual: this.filtersObject.contractual[0],
                office: this.filtersObject.office[0],
                selfStatus: this.filtersObject.selfStatus[0],
                peopleStatus: this.filtersObject.peopleStatus[0],
                calibrationStatus: this.filtersObject.calibrationStatus[0],
              }
              this.selectedFiltersNumber = 0;
            }
          } else {
            this.clearFilters(false);
          }
        }
        this.isLoadingFilters = false;
      }, (err?: any) => {
        const messageObj: ApplicationModalMessage = {
          modalId: "c008",
          title: this.translate.instant("generic.WARNING"),
          text: this.translate.instant("errors." + ((err && err.message) || err))
        }
        this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        this.isLoadingFilters = false;
      });
  }

  clearFilters(resetData: boolean) {
    this.selectedFilters = {
      subcompany: this.filtersObject.subcompany[0],
      cdcfinance: this.filtersObject.cdcfinance[0],
      areaReference: this.filtersObject.areaReference[0],
      department: this.filtersObject.department[0],
      team: this.filtersObject.team[0],
      subTeam: this.filtersObject.subTeam[0],
      position: this.filtersObject.position[0],
      supervisor: this.filtersObject.supervisor[0],
      contractual: this.filtersObject.contractual[0],
      office: this.filtersObject.office[0],
      selfStatus: this.filtersObject.selfStatus[0],
      peopleStatus: this.filtersObject.peopleStatus[0],
      calibrationStatus: this.filtersObject.calibrationStatus[0],
    }
    this.selectedFiltersNumber = 0;
    this.updateSelectedFiltersNumber.emit(0);
    this.updateSelectedFilters.emit(this.selectedFilters);
    this.emitStartSearch(resetData);
  }

  emitSearchedTextChanged(data?: any) {
    this.searchedTextChanged.emit(data);
  }

  emitStartSearch(data: any) {
    this.startSearch.emit(data);
  }

  selectFilter(data: any, id: any) {
    if (data.id === "all") {
      this.selectedFiltersNumber = this.selectedFiltersNumber - 1;
      this.updateSelectedFiltersNumber.emit(this.selectedFiltersNumber);
    }

    if (!this.selectedFilters[id].value && this.selectedFilters[id] != data && data.id != 'all') {
      this.selectedFiltersNumber = this.selectedFiltersNumber + 1;
      this.updateSelectedFiltersNumber.emit(this.selectedFiltersNumber);
    }
    this.selectedFilters[id] = data;

    this.updateSelectedFilters.emit(this.selectedFilters);
  }

  ngOnDestroy() {
    if (this.getFiltersData$) {
      this.getFiltersData$.unsubscribe();
    }
  }
}