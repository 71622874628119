import { Component, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, combineLatest, Subscription } from 'rxjs';
import * as fromApp from '../../ngrx/app.reducers';
import { TranslateService } from '@ngx-translate/core';
import { ApplicationModalMessage } from 'src/app/core/ngrx/core.reducers';
import { ModalService } from 'src/app/shared/components/modal/modal.service';
import { Router } from '@angular/router';
import { FormControl, Validators } from '@angular/forms';
import { AuthService } from '../services/auth.service';
import { SenecaResponse } from 'atfcore-commonclasses';

@Component({
  selector: 'app-sign-up',
  templateUrl: './sign-up.component.html',
  styleUrls: ['./sign-up.component.scss']
})
export class SignUpComponent implements OnDestroy {
  isFetchingLangs: boolean = false;
  langs: any;
  result$: any;
  login: boolean = true;
  iconRight: string = '';
  typeInput: string = 'password';
  iconRightEye: string = 'assets/img/icons/eye.svg'
  instructions: string = '';
  firstInstructions: string = '';
  registerUser$: Subscription = new Subscription();

  emailControl: FormControl = new FormControl;
  passwordControl: FormControl = new FormControl;

  usernameModalMessage: ApplicationModalMessage = {
    modalId: '---',
    title: '',
    text: ''
  }
  passwordModalMessage: ApplicationModalMessage = {
    modalId: '---',
    title: '',
    text: ''
  }
  signUpForm = {
    email: '',
    password: '',
    repeatPassword: ''
  }
  showLoader: boolean = false;

  constructor(
    private modalService: ModalService,
    private store: Store<fromApp.AppState>,
    public translate: TranslateService,
    private router: Router,
    private authService: AuthService) {
    // Sto in ascolto di quando il globalApplicationData cambia, così da sapere quando ci sono le lingue disponibili poiché, se non ci fossero, il pulsante per il login sarebbe disabilitato
    let availableLangs$: any = this.store.select(fromApp.getAvailableLangs);
    let isFetchingLangs$: Observable<boolean> = this.store.select(fromApp.isFetchingLangs);
    const combinedSelectes$ = combineLatest([availableLangs$, isFetchingLangs$]);
    this.result$ = combinedSelectes$.subscribe(
      ([langs, isFetchingLangs]) => {
        this.langs = langs;
        this.isFetchingLangs = isFetchingLangs;
      });
  }

  ngOnInit() {
    this.usernameModalMessage = {
      modalId: "143",
      title: this.translate.instant('login.modals.EMAIL'),
      subtitle: this.translate.instant('login.modals.EMAIL_SUBTITLE'),
      text: this.translate.instant('login.modals.SIGN_UP_EMAIL_DESCR')
    }
    this.passwordModalMessage = {
      modalId: "144",
      title: this.translate.instant('login.modals.PASSWORD'),
      subtitle: this.translate.instant('login.modals.PASSWORD_SUBTITLE'),
      text: this.translate.instant('login.modals.PASSWORD_DESCR')
    }

    this.emailControl = new FormControl('', { validators: [Validators.required, Validators.pattern('^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{1,4})+$')] });
    this.passwordControl = new FormControl('', { validators: [Validators.pattern('^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!"#$%&\'()*+,-./:;<=>?@[\]^_`{|}~]).{8,}$$')] });

  }

  ngOnDestroy() {
    this.result$.unsubscribe();
    if (this.registerUser$) {
      this.registerUser$.unsubscribe()
    }
  }

  // Quando scrivi sull'email
  emailChanged(text: string) {
    this.signUpForm.email = text;
  }

  // Quando scrivi sulla password
  passwordChanged(text: string) {
    this.signUpForm.password = text;
  }

  repeatPasswordChanged(text: string) {
    this.signUpForm.repeatPassword = text;
  }

  // Schiaccio pulsante accedi
  onSignUp() {
    this.registerUser()
  }


  // Pulsante signUp è disabilitato
  signUpButtonIsActive() {
    if (this.signUpForm.email && this.signUpForm.email.trim().length && this.signUpForm.password && this.signUpForm.password.trim().length && this.signUpForm.repeatPassword && this.signUpForm.repeatPassword.trim().length) {
      if (this.signUpForm.password == this.signUpForm.repeatPassword)
        return true;
    }
    return false;
  }

  // Valida l'email
  emailValidate() {
    let regex = /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{1,4})+$/;
    if (regex.test(this.signUpForm.email.trim())) {
      return true;
    }
    return false;
  }
  // Valida password
  passwordValidate() {
    let regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!"#$%&'()*+,-./:;<=>?@[\\\]^_`{|}~]).{8,}$/;
    if (regex.test(this.signUpForm.password.trim())) {
      return true;
    }
    return false;
  }

  // Valida password
  repeatPasswordValidate() {
    let regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!"#$%&'()*+,-./:;<=>?@[\\\]^_`{|}~]).{8,}$/;
    if (regex.test(this.signUpForm.repeatPassword.trim())) {
      return true;
    }
    return false;
  }

  //Nel caso in cui l'email non è valida e il l'input è touched mostra l'icona rossa
  emailError() {
    if (!this.emailValidate() && this.signUpForm.email && this.signUpForm.email.trim().length && this.emailControl.touched) {
      this.iconRight = "assets/img/icons/alert-triangle.svg"
      return true
    }
    this.iconRight = ''
    return false
  }

  //Nel caso in cui la password non è valida e il l'input è touched mostra l'icona rossa
  passwordError() {
    if (!this.passwordValidate() && this.signUpForm.password && this.signUpForm.password.trim().length && this.passwordControl.touched) {
      return true
    }
    return false

  }

  //Controlla che la seconda password non contenga errori 
  repeatPasswordError() {
    if (this.signUpForm.password !== this.signUpForm.repeatPassword && this.passwordControl.touched && this.signUpForm.repeatPassword.trim().length) {
      return true
    }
    else if (this.passwordError() && this.signUpForm.repeatPassword && this.signUpForm.repeatPassword.trim().length && this.passwordControl.touched) {
      return true
    }
    return false
  }

  //Controlla se entrambe le passoword sono corrette e coincidono
  isValid() {
    if (this.signUpForm.password == this.signUpForm.repeatPassword && this.passwordControl.touched && !this.repeatPasswordError() && !this.passwordError() && this.signUpForm.password.trim().length && this.signUpForm.repeatPassword.trim().length) {
      return true
    }
    return false
  }

  //Rende possibile la visualizzazione della password cambiando il tipo di Input
  changeTypeInput(type: string) {
    if (type && type === 'password') {
      this.typeInput = 'text'
      this.iconRightEye = 'assets/img/icons/eye-off.svg'
    }
    else if (type && type === 'text') {
      this.typeInput = 'password'
      this.iconRightEye = 'assets/img/icons/eye.svg'
    }
  }

  //Cambia pagina da accedi in registrati
  goToSignIn() {
    this.router.navigate(["/localLogin"]);
  }

  //Servizio che invia l'email di conferma registrazione
  registerUser() {
    this.registerUser$ = this.authService.registerUser(this.signUpForm.email, this.signUpForm.password)
      .subscribe((data: SenecaResponse<any>) => {
        if (data && data.error) {
          this.modalService.open('modalGenericError')
        } else {
          this.modalService.open('modalSignUpInProgress')
        }
      },
        (err: string) => {

        })
  }

  //Chiudo la modale
  closeSignUpInProgressModal() {
    this.modalService.close('modalSignUpInProgress');
  }
  closeGenericErrorModal() {
    this.modalService.close('modalGenericError')
  }
}

