import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { ApplicationModalMessage } from 'src/app/core/ngrx/core.reducers';
import { DeletePrivateNoteFeedbackForManagerResponse, SenecaResponse } from 'src/commonclasses';
import { PeopleAppraisalService } from 'src/app/shared/services/peopleAppraisal.service';
import { Subordinateservice } from '../services/subordinates.service';
import * as fromApp from '../../ngrx/app.reducers';
import * as CoreActions from "../../core/ngrx/core.actions";
import * as PeopleAppraisalActions from "../ngrx/peopleAppraisal.actions";
import { Subscription } from 'rxjs';
import { TabDossier } from 'src/app/utils/classes.utils';
import { RedirectService } from 'src/app/shared/services/redirect.service';
import { ModalService } from 'src/app/shared/components/modal/modal.service';
import * as moment from 'moment';
import { PerfAlloyAssessmentPhaseKeys, PerfAlloyUserGoal, PerfAlloyYearCodes, privateNoteAndSmartFeedback, RentStatusTypes, PerfAlloyFeedbackTypes, PerfAlloyPeerFeedbackParams, PerfAlloyPeerFeedback, PerfAlloyPrivateNoteFeedback, PerfAlloyCommonFeedback, PerfAlloySmartFeedback, PeerFeedbackServiceResponses, CountFeedbacksForManagerResponse, PerfAlloyFeedbackStatus, PerfAlloyFeedbackSorting, PerfAlloyManagerFeedback } from 'atfcore-commonclasses';
import { GuidedTour, GuidedTourService, Orientation, TourStep } from 'ngx-guided-tour';
import { AnalyticsService } from 'src/app/shared/services/analytics.service';
import { SharedService } from 'src/app/shared/services/shared.service';
import { AuthService } from 'src/app/auth/services/auth.service';
import * as CommonClasses from 'src/commonclasses'
import { take } from 'rxjs/operators';
import { RoundAverage } from 'src/app/utils/round-average.utils'

export const YEARS_FILTER = {
  LAST_YEAR: 'LAST_YEAR',
  LAST_2_YEARS: 'LAST_2_YEARS',
  LAST_3_YEARS: 'LAST_3_YEARS',
  LAST_4_YEARS: 'LAST_4_YEARS',
  ALL: 'ALL'
}
@Component({
  selector: 'app-peopleAppraisal-operations-personDetails',
  templateUrl: './personDetails.component.html',
  styleUrls: ['./personDetails.component.scss']
})
export class PeopleAppraisalPersonDetailsComponent implements OnInit, OnDestroy {
  getPersonDetails$: any;
  personDetails$!: Subscription;
  personDetails: any;
  isLoadingPersonDetails = false;
  routeParams$!: Subscription;
  userId: string = '';
  loggedUser$: any;

  getUserProfileTab$!: Subscription;
  isLoadingTab: boolean = true;
  contentTabList!: TabDossier[];
  currentContentTab!: TabDossier;
  competenceFeedbackChars: number = 600;
  maxTextAreaChars: number = 600;

  getRunningPhase$: Subscription = new Subscription();
  runningPhase: any;
  isGoalsUpdatePhase: boolean = false;
  getGoalUpdates$: Subscription = new Subscription();
  goalUpdatesData: any = [];
  saveGoalUpdatesForManager$: Subscription = new Subscription();
  updatesForManager: Array<{
    goalId: string;
    description: string;
  }> = [];
  textGoals: string[] = [];
  textExists: boolean = false;
  isLoadingGoalUpdates: boolean = true;

  // Anno selezionato per storico
  selectedYear: {
    perfYear: number;
    perfYearCode: string;
    name: string;
  } | null = null;
  getYearsList$: any;
  yearList: any[] = [];
  isLoadingYearList: boolean = false;

  // Variabili gestione stepper
  stepList: { id: string; referenceId: string; number: number; stepId: number; title: any; isWaiting?: boolean, isComplete?: boolean }[] = [];
  currentStep: { id: string; referenceId: string; number: number; stepId: number; title: any; isWaiting?: boolean, isComplete?: boolean } = { id: '', referenceId: '', number: 0, stepId: 0, title: '' };

  toEvaluateList: any[] = []
  isLoadingToEvaluateGoals: boolean = false;
  toEvaluateListCopy: any;

  stars: any[] = [];
  manageBehaviorComments: string = '';
  manageBehaviorCommentsCopy: string = '';


  techSkills: any = {};
  techSkillsCopy: any;
  isLoadingTechSkills: boolean = false;
  techSkillComment: string = '';
  techSkillCommentCopy: string = '';

  newObjectiveList: { id: string, title: string, isEdit?: boolean, isSelected?: boolean, tmpId?: string, cloned?: boolean, count?: number[], creationUser?:any}[] = [];
  isLoadingDefinedObjectives: boolean = false;
  objectiveToDelete: any;
  selectedObjective: any;
  recapDevelopmentDescription: any;
  isNoteSection: boolean = false;
  isFeedbackSection: boolean = false;
  translations: any;
  loggedUser: any;
  competenceForModal: any;

  counterTabs: PeerFeedbackServiceResponses.CountNotesAndFeedbacksForUser = { privateNotes: 0, feedbacks: 0 }
  // Filtri
  feedbackType: Array<string> = [];
  feedbackStatus: PerfAlloyFeedbackStatus | undefined = undefined;
  sorting: PerfAlloyFeedbackSorting = "DATE_DESC";
  filterList: Array<{ title: string, list: Array<{ id: string, title: string }>, selected?: { id: string, title: string } }> = [];

  filterCounter: number = 0;
  orderFilterSelected: string = '';
  isFeedbackFilterOpened: boolean = false;
  isPrivateNotesFilterOpened: boolean = false;
  menuOrderList: Array<{ id: string, title: string }> = []
  menuOrderYears: Array<{ id: string, title: string }> = [];
  privateNotesMinCreationDate: string = '';
  feedbackMinCreationDate: { id: string, date: string } = { id: YEARS_FILTER.LAST_YEAR, date: moment().subtract(1, 'years').toISOString() };

  orderPrivateNotesFilterSelected: string = '';

  selectedInfoTabPerformerKey: string | undefined = '';
  hrRemarks: string = 'Sed scelerisque nisl laoreet ex aliquam, sed scelerisque lorem rutrum. Donec bibendum neque a rhoncus tristique. Vestibulum in felis risus. Pellentesque euismod lectus ac sem faucibus rutrum. Proin tincidunt ipsum risus, vitae porttitor odio gravida ut. Maecenas ut purus dolor.';
  noteIdToDelete: string = '';

  privateNotesAndSmartFeedbackList: privateNoteAndSmartFeedback[] = [];
  getPrivateNotesAndSmartFeedbackList$: Subscription = new Subscription();
  smartFeedbackDataList: { isSmartFeedback: boolean, smartFeedbackType?: string }[] = [];

  // Al momento lascio any da cambiare (non ho ancora i nuovi type)
  feedbackList: Array<PerfAlloyPeerFeedback> = [];
  isLoadingFeedback: boolean = false;

  privateNotesList: Array<any> = [];
  isLoadingPrivateNotes: boolean = false;

  menuButtons: any = [];
  menuTabs: any = [];

  getUserGoalToEvaluate$: Subscription = new Subscription();
  getUserGoalToDefine$: Subscription = new Subscription();
  createOrUpdatePerformanceAssessmentGoalsForManager$: Subscription = new Subscription();
  deletePerformanceAssessmentGoalsForManager$: Subscription = new Subscription();
  isLoadingBehaviours: boolean = false;
  parentBehaviours: any;
  parentBehaviourIds: any;
  competenceArray: any;
  competenceArrayCopy: any;

  techSkillArray: any;
  techSkillArrayCopy: any;
  techSkillParentBehaviourIds: any;
  techSkillCompetenceToChange: any;
  techSkillsFinalAvg: any;
  softSkillsFinalAvg: any;

  intro!: GuidedTour;
  steps?: TourStep[];

  insertByUser: string = '';
  insertByManager: string = '';

  SENT_TO: string = 'INVIATO';
  RECEIVED_FROM: string = 'RICEVUTO';

  titleRightSection = {
    textLeft: 'Self-assessment',
    textRight: 'Tua valutazione'
  }
  createOrUpdatePerformanceAssessmentBehaviorEvaluationForManager$: any;
  starsModel: any = {
    5: [
      {
        id: 0,
        isClicked: false
      },
      {
        id: 1,
        isClicked: false
      },
      {
        id: 2,
        isClicked: false
      },
      {
        id: 3,
        isClicked: false
      },
      {
        id: 4,
        isClicked: false
      }
    ],
    3: [
      {
        id: 0,
        isClicked: false
      },
      {
        id: 1,
        isClicked: false
      },
      {
        id: 2,
        isClicked: false
      }
    ]
  }
  techSkillRatingScale: any;
  getCompetenceToEvaluateForManager$: Subscription = new Subscription;
  skipObjectiveCheck: boolean = false;
  isLoadingFinalEvaluationData: boolean = false;
  getFinalEvaluationData$: Subscription = new Subscription;
  finalEvaluation: any = {};
  tempStep: any;
  newObjectiveListCopy: any;
  isLoadingUserGoalToDefine: any;

  textAreaActions: any[] = [];
  isScheduleShared: boolean = false;
  editFinalEvaluationComment: boolean = false;
  editBehaviorComment: boolean = false;
  editCompetenceComment: boolean = false;
  objectiveToDeleteFromEdit: boolean | undefined;

  createOrUpdatePerformanceAssessmentGalEvaluationForManager$: any;
  getBehavioursToEvaluateForManager$: any;
  recapSubordinateDevelopmentDescription: any;
  isPostCalibration: boolean = false;
  finalShareDescripton: string = '';
  feedbackCheck: boolean = false;
  finalShareModalDescription: any;
  finalShare$: Subscription = new Subscription;
  isFinalStepCompleted: boolean = false;
  isManagement: boolean = false;
  yearsWithSpecialisticGoalsToEvaluate: boolean = false;
  isLoadingMySubordinateUserData: boolean = false;
  subordinatesWithPhaseStatuses$: any;
  getMySubordinateUserData$: any;
  newObjectiveMessage: string = '';
  @ViewChild('newObjectiveContainer') newObjectiveContainer: any;
  forcePasStructureTutorial: string | null;
  forcePasTutorialStep: { id: string; referenceId: string; number: number; stepId: number; title: any; isWaiting?: boolean, isComplete?: boolean } = { id: '', referenceId: '', number: 0, stepId: 0, title: '' };

  behaviourCompetenceToChange: any;

  objectivesToDelete: any[] = [];

  finalEvaluationCommentCopy: any;
  finalEvaluationBehaviourCommentCopy: any;
  finalEvaluationDataCopy: any;
  finalEvaluationCompetenceCommentCopy: any;
  finalDefinedObjectivesCopy: any;
  finalSelfDefinedObjectivesCopy: any;
  finalSelfDefinedObjectivesToDelete: any;
  finalDefinedObjectivesToDelete: any;

  modalDataList!: CommonClasses.PerfAlloyGoalUpdatePhase.PerfAlloyUserGoalUpdate[];

  isLoadingReproposeObjData: boolean = false;
  reproposeObjData: any;
  openReproposeObjModal$: any;
  selectedReproposeObj: any;
  clonePerformanceAssessmentGoalsByGoalIds$: any;
  editObjectiveData: any;
  objectiveToApprove: any;

  titlesRightSection = {
    left: 'Self-assessment',
    right: 'Tua valutazione'
  }

  /** Se true, sto attualmente impersonificando l'utente. In questo caso non dovrò mostrare gli appunti privati */
  public isImpersonating: boolean = false;

  constructor(private route: ActivatedRoute,
    private store: Store<fromApp.AppState>,
    private translate: TranslateService,
    private subordinatesService: Subordinateservice,
    private peopleAppraisalService: PeopleAppraisalService,
    private router: Router,
    private modalService: ModalService,
    private authService: AuthService,
    private guidedTour: GuidedTourService,
    public redirectService: RedirectService,
    private sharedService: SharedService,
    private analyticsService: AnalyticsService,
    private cdr: ChangeDetectorRef
  ) {
    // Invio un evento di tracciamento a Google Tag Manager per Google Analytics
    // this.analyticsService.sendVirtualPageViewEvent(this.router.url, "PeopleAppraisal - Scheda utente");
    const openPasStructureTutorial = sessionStorage.getItem('openPasStructureTutorial');
    const openSetGoalsTutorial = sessionStorage.getItem('openSetGoalsTutorial');
    const openTechSkillEvaluationTutorial = sessionStorage.getItem('openTechSkillEvaluationTutorial');
    const openOrganizationalBehaviorsTutorial = sessionStorage.getItem('openOrganizationalBehaviorsTutorial');
    this.forcePasStructureTutorial = openPasStructureTutorial || openSetGoalsTutorial || openTechSkillEvaluationTutorial || openOrganizationalBehaviorsTutorial;

    // Mi interessa sapere se sto impersonificando l'utente, in modo da non recuperare gli appunti privati
    this.store.select(fromApp.getShowImpesonificaitonBanner)
      .pipe(take(1))
      .subscribe(isImpersonating => {
        this.isImpersonating = isImpersonating;
      });
  }

  ngOnInit() {
    // Reset dati finalEvaluation per problema cambio utente
    this.finalSelfDefinedObjectivesCopy = undefined;
    this.translate.get(
      [
        'accordion.CHALLENGE',
        "modals.CHALLENGE.TITLE",
        "modals.CHALLENGE.SUBTITLE",
        "modals.CHALLENGE.TEXT",
        "modals.ROLE.TITLE",
        "modals.ROLE.TEXT",
        "peopleAppraisalOperations.CARE_TITLE",
        "peopleAppraisalOperations.SHARED_COMPETENCE",
        'peopleAppraisalOperations.SHARED_COMPETENCE',
        "modals.OPERATIONS.TITLE",
        "modals.OPERATIONS.SUBTITLE",
        "modals.OPERATIONS.TEXT",
        'accordion.GUIDELINES_STI',
        "performance.ACCORDION_TITLE",
        "performance.ACCORDION_TOOLTIP",
        "performance.statuses.TO_DEFINE",
        "performance.statuses.TO_CONFIRM",
        "performance.statuses.COMPLETED",
        "calibration.statuses.TO_CONFIRM",
        'finalEvaluation.ASSIGNED_LIST_1',
        'finalEvaluation.ASSIGNED_LIST_2',
        "stepper.OBJECTIVE_EVALUATION",
        "stepper.BEHAVIOUR_EVALUATION",
        "stepper.TECH_SKILL_EVALUATION",
        "stepper.DEFINE_OBJECTIVES",
        "stepper.FINAL_EVALUATION",
        "objectiveEvaluation.MODAL_TITLE",
        "objectiveEvaluation.MODAL_SUB",
        "objectiveEvaluation.MODAL_TEXT",
        "generic.NO_COMMENT_INSERTED",
        "finalEvaluation.FINAL_SHARE_DESCR_1",
        "finalEvaluation.FINAL_SHARE_DESCR_2",
        "stepper.TECH_OBJECTIVES",
        'finalEvaluation.SELF_ASSIGNED_LIST_1',
        'finalEvaluation.SELF_ASSIGNED_LIST_2',
        'finalEvaluation.modals.DELETE_DESCR_1',
        'finalEvaluation.modals.DELETE_DESCR_2',
        'finalEvaluation.modals.APPROVE_DESCR_1',
        'finalEvaluation.modals.APPROVE_DESCR_2',
        'feedbackSection.smartFeedback.filters.TYPE',
        'feedbackSection.smartFeedback.filters.RECIPIENT',
        'feedbackSection.smartFeedback.filters.ORDER_LIST',
        'feedbackSection.smartFeedback.filters.FILTER_ALL',
        'feedbackSection.smartFeedback.filters.FILTER_SMART_FEEDBACK',
        'feedbackSection.smartFeedback.filters.FILTER_PEER_FEEDBACK',
        'feedbackSection.smartFeedback.filters.FILTER_TO_MANAGER',
        'feedbackSection.smartFeedback.filters.FILTER_SENT',
        'feedbackSection.smartFeedback.filters.FILTER_RECEIVED',
        'feedbackSection.smartFeedback.filters.FILTER_RECENT',
        'feedbackSection.smartFeedback.filters.FILTER_LATE',
        'feedbackSection.smartFeedback.filters.FILTER_WAITING',
        'feedbackSection.smartFeedback.filters.FILTER_TO_SEND',
        'feedbackSection.smartFeedback.filters.PERIOD',
        'feedbackSection.smartFeedback.filters.FILTER_LAST_YEAR',
        'feedbackSection.smartFeedback.filters.FILTER_LAST_2_YEARS',
        'feedbackSection.smartFeedback.filters.FILTER_LAST_3_YEARS',
        'feedbackSection.smartFeedback.filters.FILTER_LAST_4_YEARS',
        'feedbackSection.smartFeedback.filters.ALL_YEARS',
        'privateNotes.filters.FILTER_LAST_YEAR',
        'privateNotes.filters.FILTER_LAST_2_YEARS',
        'privateNotes.filters.FILTER_LAST_3_YEARS',
        'privateNotes.filters.FILTER_LAST_4_YEARS'
      ])
      .subscribe(translations => {
        this.translations = translations;

        this.textAreaActions = [
          {
            id: 'delete',
            label: "generic.DELETE",
            class: 'secondary'
          },
          {
            id: 'save',
            label: "generic.SAVE",
            class: 'primary'
          }
        ];

        this.filterList = [
          {
            title: this.translations["feedbackSection.smartFeedback.filters.PERIOD"],
            list: [
              {
                id: YEARS_FILTER.LAST_YEAR,
                title: this.translations["feedbackSection.smartFeedback.filters.FILTER_LAST_YEAR"]
              },
              {
                id: YEARS_FILTER.LAST_2_YEARS,
                title: this.translations["feedbackSection.smartFeedback.filters.FILTER_LAST_2_YEARS"]
              },
              {
                id: YEARS_FILTER.LAST_3_YEARS,
                title: this.translations["feedbackSection.smartFeedback.filters.FILTER_LAST_3_YEARS"]
              },
              {
                id: YEARS_FILTER.LAST_4_YEARS,
                title: this.translations["feedbackSection.smartFeedback.filters.FILTER_LAST_4_YEARS"]
              },
              {
                id: YEARS_FILTER.ALL,
                title: this.translations["feedbackSection.smartFeedback.filters.ALL_YEARS"]
              }
            ],
          },
          {
            title: this.translations["feedbackSection.smartFeedback.filters.TYPE"],
            list: [
              {
                id: 'ALL_TYPE',
                title: this.translations["feedbackSection.smartFeedback.filters.FILTER_ALL"]
              },
              {
                id: PerfAlloyFeedbackTypes.PERFALLOY_SMART_FEEDBACK,
                title: this.translations["feedbackSection.smartFeedback.filters.FILTER_SMART_FEEDBACK"]
              },
              {
                id: PerfAlloyFeedbackTypes.PERFALLOY_PEER_FEEDBACK,
                title: this.translations["feedbackSection.smartFeedback.filters.FILTER_PEER_FEEDBACK"]
              },
              {
                id: PerfAlloyFeedbackTypes.PERFALLOY_MANAGER_FEEDBACK,
                title: this.translations["feedbackSection.smartFeedback.filters.FILTER_TO_MANAGER"]
              }
            ],
          },
          {
            title: this.translations["feedbackSection.smartFeedback.filters.RECIPIENT"],
            list: [
              {
                id: 'ALL_STATUS',
                title: this.translations["feedbackSection.smartFeedback.filters.FILTER_ALL"]
              },
              {
                id: CommonClasses.PERFALLOY_FEEDBACK_STATUS.SENT,
                title: this.translations["feedbackSection.smartFeedback.filters.FILTER_SENT"]
              },
              {
                id: CommonClasses.PERFALLOY_FEEDBACK_STATUS.RECEIVED,
                title: this.translations["feedbackSection.smartFeedback.filters.FILTER_RECEIVED"]
              },
              {
                id: CommonClasses.PERFALLOY_FEEDBACK_STATUS.WAITING,
                title: this.translations["feedbackSection.smartFeedback.filters.FILTER_WAITING"]
              },
              {
                id: CommonClasses.PERFALLOY_FEEDBACK_STATUS.TO_SEND,
                title: this.translations["feedbackSection.smartFeedback.filters.FILTER_TO_SEND"]
              },
            ]
          }
        ];

        this.menuOrderList = [
          {
            id: CommonClasses.PERFALLOY_FEEDBACK_SORTING.DATE_ASC,
            title: translations['feedbackSection.smartFeedback.filters.FILTER_LATE']
          },
          {
            id: CommonClasses.PERFALLOY_FEEDBACK_SORTING.DATE_DESC,
            title: translations['feedbackSection.smartFeedback.filters.FILTER_RECENT']
          }
        ]

        this.menuOrderYears = [
          {
            id: YEARS_FILTER.LAST_YEAR,
            title: translations['privateNotes.filters.FILTER_LAST_YEAR']
          },
          {
            id: YEARS_FILTER.LAST_2_YEARS,
            title: translations['privateNotes.filters.FILTER_LAST_2_YEARS']
          },
          {
            id: YEARS_FILTER.LAST_3_YEARS,
            title: translations['privateNotes.filters.FILTER_LAST_3_YEARS']
          },
          {
            id: YEARS_FILTER.LAST_4_YEARS,
            title: translations['privateNotes.filters.FILTER_LAST_4_YEARS']
          }
        ]

        this.menuTabs = [
          {
            id: 'pas',
            name: this.translate.instant('headerDossier.CARD')
          },
          {
            id: 'feedback',
            name: this.translate.instant('headerDossier.FEEDBACK_TITLE')
          },
          {
            id: 'notes',
            name: this.translate.instant('headerDossier.PRIVATE_NOTE')
          }]


        for (let i = 0; i < this.filterList.length; i++) {
          this.filterList[i].selected = this.filterList[i].list[0];
        }

        this.contentTabList = [];


        this.routeParams$ = this.route.params
          .subscribe(
            (params: Params) => {
              // Se ho cambiato utente (dalla sidebar) il componente non entrerà nell'onDestroy; quindi devo eliminare qui ogni referenza all'apertura automatica del tutorial dal session storage
              if (this.userId && params.userId && this.userId !== params.userId) {
                this.editFinalEvaluationComment = false;
                this.removeTutorialsRefFromSessionStorage();
              }
              this.loggedUser$ = this.store.select(fromApp.getLoggedUser).subscribe((loggedUser) => {
                if (loggedUser && loggedUser.user) {

                  this.loggedUser = loggedUser && loggedUser.user;
                  if (!this.isLoadingMySubordinateUserData) {
                    this.isLoadingMySubordinateUserData = true;
                  }
                }
              })
              this.userId = params.userId;
              if (params.perfYear) {
                this.selectedYear = {
                  perfYear: params.perfYear,
                  perfYearCode: params.perfYearCode,
                  name: params.perfYearCode + ' ' + params.perfYear
                }

              } else {
                this.selectedYear = {
                  perfYear: 2021,
                  perfYearCode: PerfAlloyYearCodes.H1,
                  name: 'H1 ' + 2021
                }
              }

              // Una volta impostato l'anno attuale, recupero le info sulla fase da mostrare (NOTA: potrebbe essere la fase di goal update del processo precedente)
              this.getPerformanceAssessmentRunningPhase();

              this.store.dispatch(PeopleAppraisalActions.SetActiveUserId({ payload: this.userId }));
              this.getPerformanceAssessmentYears();
              // this.getCounterFeedbackTabs();

              this.isNoteSection = window.location.href.indexOf('myNotes') > -1;
              this.isFeedbackSection = window.location.href.indexOf('feedback') > -1;

            }
          );


        this.store.dispatch(PeopleAppraisalActions.SetActiveUserId({ payload: this.userId }));
        this.getPerformanceAssessmentYears();
      }
      )
  };

  getMySubordinateUserData() {
    this.isLoadingMySubordinateUserData = true;
    this.subordinatesWithPhaseStatuses$ = this.store.select(fromApp.getSubordinatesWithPhaseStatuses).subscribe((subordinatesWithPhaseStatuses) => {
      subordinatesWithPhaseStatuses;

      if (subordinatesWithPhaseStatuses && subordinatesWithPhaseStatuses.length && this.selectedYear) {
        if (this.getMySubordinateUserData$) {
          this.getMySubordinateUserData$.unsubscribe();
        }

        this.getMySubordinateUserData$ = this.sharedService.getMySubordinateUserData(subordinatesWithPhaseStatuses[0].userId, this.selectedYear.perfYear, this.selectedYear.perfYearCode)
          .subscribe((res: any) => {
            if (res && res.error) {
              const messageObj: ApplicationModalMessage = {
                modalId: "pn017",
                text: this.translate.instant("errors." + res.error),
                title: this.translate.instant("generic.WARNING")
              }
              this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
            } else {
              const techYears = res.response && res.response.yearsWithSpecialisticGoalsToEvaluate && res.response.yearsWithSpecialisticGoalsToEvaluate;
              this.isThisSpecialTechYear(techYears);
            }
            this.isLoadingMySubordinateUserData = false;
          }, (err?: any) => {
            this.isLoadingMySubordinateUserData = false;
            const messageObj: ApplicationModalMessage = {
              modalId: "109",
              text: this.translate.instant("errors." + ((err && err.message) || err)),
              title: this.translate.instant("generic.WARNING")
            }
            this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          });
      } else {
        this.isLoadingMySubordinateUserData = false;
      }
    })
  }

  isThisSpecialTechYear(techYears: any) {
    if (techYears && techYears.length) {
      for (let i = 0, yearsLength = techYears.length; i < yearsLength; i++) {
        const currentSpecialYear = techYears[i];

        if (currentSpecialYear.year && currentSpecialYear.year === this.selectedYear?.perfYear?.toString() && currentSpecialYear.yearCode === this.selectedYear?.perfYearCode) {
          this.yearsWithSpecialisticGoalsToEvaluate = true;
          break;
        }
      }
    }
  }

  getPersonDetails() {
    if (!this.selectedYear) {
      console.log("No selected year");
      return;
    }

    this.isLoadingPersonDetails = true;
    this.isLoadingTab = true;

    if (this.getPersonDetails$) {
      this.getPersonDetails$.unsubscribe();
    }
    this.getPersonDetails$ = this.sharedService.getMySubordinateUserData(this.userId, this.selectedYear.perfYear, this.selectedYear.perfYearCode)
      .subscribe((personDetailsData: any) => {
        if (personDetailsData && personDetailsData.error) {
          this.isLoadingPersonDetails = false;
          this.redirectService.goToErrorPage(personDetailsData && personDetailsData.error);
        } else {
          this.personDetails = personDetailsData.response;
          this.recapDevelopmentDescription = this.translate.instant('finalEvaluation.ASSIGNED_LIST_1') + this.personDetails.forename + this.translate.instant('finalEvaluation.ASSIGNED_LIST_2');
          this.recapSubordinateDevelopmentDescription = this.translate.instant('finalEvaluation.SELF_ASSIGNED_LIST_1') + this.personDetails.forename + this.translate.instant('finalEvaluation.SELF_ASSIGNED_LIST_2');
          this.finalShareDescripton = this.translate.instant('finalEvaluation.FINAL_SHARE_DESCR_1') + this.personDetails.forename + this.translate.instant('finalEvaluation.FINAL_SHARE_DESCR_2');
          this.finalShareModalDescription = this.translate.instant('finalEvaluation.CONFIRM_SHARE_DESCR_1') + this.personDetails.forename + this.translate.instant('finalEvaluation.CONFIRM_SHARE_DESCR_2');
          this.newObjectiveMessage = this.translate.instant('finalEvaluation.NOTICE_MESSAGE_1') + this.personDetails.forename + ' ' + this.personDetails.surname + this.translate.instant('finalEvaluation.NOTICE_MESSAGE_2');
          this.isManagement = false;

          this.yearsWithSpecialisticGoalsToEvaluate = false;
          const techYears = personDetailsData.response && personDetailsData.response.yearsWithSpecialisticGoalsToEvaluate && personDetailsData.response.yearsWithSpecialisticGoalsToEvaluate;
          this.isThisSpecialTechYear(techYears);


          if (this.personDetails && this.personDetails.userId) {
            this.recapDevelopmentDescription = this.translate.instant('finalEvaluation.ASSIGNED_LIST_1') + this.personDetails.forename + this.translate.instant('finalEvaluation.ASSIGNED_LIST_2');
            if (this.isNoteSection) {
              this.isLoadingTab = false;
              this.getPrivateNoteForManager()
            } else if (this.isFeedbackSection) {
              this.isLoadingTab = false;
              this.getFeedbacksForManager();
            } else {
              this.getUserProfileTab();
            }
          } else {
            this.isLoadingTab = false;
          }
          this.isLoadingPersonDetails = false;
        }
      }, (err?: any) => {
        this.isLoadingPersonDetails = false;
        this.isLoadingTab = false;
        const messageObj: ApplicationModalMessage = {
          modalId: "109",
          text: this.translate.instant("errors." + ((err && err.message) || err)),
          title: this.translate.instant("generic.WARNING")
        }
        this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
      });
  }

  onMenuPrivateNotesFilterListClicked(item: { id: string, title: string }) {
    // Salvo il nome del filtro selezionato
    this.orderPrivateNotesFilterSelected = item.title;

    // Imposto il filtro dell'anno
    if (item.id == YEARS_FILTER.LAST_YEAR) {
      this.privateNotesMinCreationDate = moment().subtract(1, 'years').toISOString();
    } else if (item.id == YEARS_FILTER.LAST_2_YEARS) {
      this.privateNotesMinCreationDate = moment().subtract(2, 'years').toISOString();
    } else if (item.id == YEARS_FILTER.LAST_3_YEARS) {
      this.privateNotesMinCreationDate = moment().subtract(3, 'years').toISOString();
    } else if (item.id == YEARS_FILTER.LAST_4_YEARS) {
      this.privateNotesMinCreationDate = moment().subtract(4, 'years').toISOString();
    } else {
      this.privateNotesMinCreationDate = '';
    }

    this.isPrivateNotesFilterOpened = false;
    this.getPrivateNoteForManager();
  }

  getPrivateNoteForManager() {
    if (!this.selectedYear) {
      console.log("No selected year");
      return;
    }
    //Faccio partire il loader
    this.isLoadingPrivateNotes = true;

    // Nel caso stia impersonificando, devo recuperare solo gli smart feedback
    const onlySmartFeedback: boolean = this.isImpersonating;

    // Inizio a fare la chiamata per le note private
    this.peopleAppraisalService.getPrivateNoteFeedbackForManager(this.selectedYear.perfYear, this.selectedYear.perfYearCode, this.userId)
      .subscribe((data: SenecaResponse<Array<PerfAlloyPrivateNoteFeedback>>) => {
        if (data && data.response) {
          this.privateNotesList = data.response;
          this.isLoadingPrivateNotes = false;
        } else {
          const messageObj: ApplicationModalMessage = {
            modalId: "year000",
            text: this.translate.instant("errors." + data.error),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          this.isLoadingYearList = false;
        }
      }, (err?: any) => {
        this.isLoadingPersonDetails = false;
        this.isLoadingTab = false;
        const messageObj: ApplicationModalMessage = {
          modalId: "109",
          text: this.translate.instant("errors." + ((err && err.message) || err)),
          title: this.translate.instant("generic.WARNING")
        }
        this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        this.isLoadingFeedback = false;
      });
  }

  // Apro i filtri dal più recente al meno recente FEEDBACK
  openFeedbackFiltersSection(close?: boolean) {
    if (close) {
      this.isFeedbackFilterOpened = false;
    } else {
      this.isFeedbackFilterOpened = !this.isFeedbackFilterOpened;
    }
  }
  // Apro i filtri per anno APPUNTI PRIVATI
  openPrivateNotesFiltersSection(close?: boolean) {
    if (close) {
      this.isPrivateNotesFilterOpened = false;
    } else {
      this.isPrivateNotesFilterOpened = !this.isPrivateNotesFilterOpened;
    }
  }

  onMenuFilterListClicked(item: { id: string, title: string }) {
    // Salvo il nome del filtro selezionato
    this.orderFilterSelected = item.title;

    // Filtro recenti
    if (item.id == CommonClasses.PERFALLOY_FEEDBACK_SORTING.DATE_DESC) {

      // Incremento il counter
      if (this.sorting == CommonClasses.PERFALLOY_FEEDBACK_SORTING.DATE_ASC) {
        this.filterCounter--;
      }

      this.sorting = item.id;
    }

    // Filtro meno recente
    if (item.id == CommonClasses.PERFALLOY_FEEDBACK_SORTING.DATE_ASC) {

      // Incremento il counter
      if (this.sorting == CommonClasses.PERFALLOY_FEEDBACK_SORTING.DATE_DESC) {
        this.filterCounter++;
      }

      // Assegno il nuovo valore 
      this.sorting = item.id;
    }
    this.isFeedbackFilterOpened = false;
    this.getFeedbacksForManager();
  }

  // Recupero i feedback lato manager
  getFeedbacksForManager() {
    if (!this.selectedYear) {
      console.log("No selected year");
      return;
    }
    //Faccio partire il loader
    this.isLoadingFeedback = true;

    // Inizio a fare la chiamata per le note private
    this.peopleAppraisalService.getFeedbacksForManager(this.selectedYear.perfYear, this.selectedYear.perfYearCode, this.userId, this.feedbackType, this.feedbackStatus, this.sorting, this.feedbackMinCreationDate.date)
      .subscribe((data: SenecaResponse<Array<PerfAlloyPeerFeedback>>) => {
        if (data && data.response) {
          this.feedbackList = data.response;
        } else {
          const messageObj: ApplicationModalMessage = {
            modalId: "year000",
            text: this.translate.instant("errors." + data.error),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          this.isLoadingYearList = false;
        }
        this.isLoadingFeedback = false;
      }, (err?: any) => {
        this.isLoadingPersonDetails = false;
        this.isLoadingTab = false;
        const messageObj: ApplicationModalMessage = {
          modalId: "109",
          text: this.translate.instant("errors." + ((err && err.message) || err)),
          title: this.translate.instant("generic.WARNING")
        }
        this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        this.isLoadingFeedback = false;
      });
  }

  goToSmartFeedback() {
    this.router.navigate(['/peopleAppraisal/sendApplause']);
  }

  getPerformanceAssessmentYears() {
    this.isLoadingYearList = true;

    if (this.getYearsList$) {
      this.getYearsList$.unsubscribe();
    }
    this.getYearsList$ = this.peopleAppraisalService.getPerformanceAssessmentYears(this.userId)
      .subscribe((data: any) => {
        if (data && data.error) {
          const messageObj: ApplicationModalMessage = {
            modalId: "year000",
            text: this.translate.instant("errors." + data.error),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          this.isLoadingYearList = false;
        } else {
          this.yearList = [];
          for (let i = (data.response.length - 1); i >= 0; i--) {
            this.yearList.push({
              id: data.response[i].assessmentYear,
              code: data.response[i].assessmentYearCode,
              name: data.response[i].assessmentYearCode + ' ' + data.response[i].assessmentYear,
              disabled: !data.response[i].isInProcess
            });
          }
          this.isLoadingYearList = false;

          this.currentStep = { id: '', referenceId: '', number: 0, stepId: 0, title: '' };
          this.getPersonDetails();
        }
      }, (err?: any) => {
        const messageObj: ApplicationModalMessage = {
          modalId: "year000",
          text: this.translate.instant("errors." + err?.message),
          title: this.translate.instant("generic.WARNING")
        }
        this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        this.isLoadingYearList = false;
      });
  }

  getPerformanceAssessmentRunningPhase() {
    if (!this.selectedYear) {
      console.log("No selected year");
      return;
    }

    this.isLoadingGoalUpdates = true;
    this.textExists = false;
    if (this.getRunningPhase$) {
      this.getRunningPhase$.unsubscribe();
    }

    this.getRunningPhase$ = this.authService.getPerformanceAssessmentRunningPhase(this.selectedYear.perfYear, this.selectedYear.perfYearCode)
      .subscribe((data) => {
        if (data && data.error) {
          const messageObj: ApplicationModalMessage = {
            modalId: "phase000",
            text: this.translate.instant("errors." + data.error),
            title: this.translate.instant("generic.WARNING")
          }
          this.isLoadingGoalUpdates = false;
        } else {
          if (!this.selectedYear) {
            console.log("No selected year");
            return;
          }

          if (data && moment().isBetween(moment(data.response.goalUpdateStartDate), moment(data.response.goalUpdateEndDate)) && data.response.prevProcessHasGoalUpdateAvailable) {
            if (window.location.href.indexOf('myNotes') < 0) {
              this.isGoalsUpdatePhase = true;

              if (this.getGoalUpdates$) {
                this.getGoalUpdates$.unsubscribe();
              }
              let serviceYear = this.getEvaluationYear();

              this.getGoalUpdates$ = this.peopleAppraisalService.getUserGoalListToEvaluate(serviceYear.year, serviceYear.code, [this.userId], true)
                .subscribe((data: SenecaResponse<any>) => {
                  if (data && data.error) {
                  } else {
                    this.goalUpdatesData = data.response;
                    this.isLoadingGoalUpdates = false;
                  }
                })
            }
            this.isLoadingGoalUpdates = false;
          } else {
            this.isGoalsUpdatePhase = false;
          }
        }
        this.isLoadingGoalUpdates = false;
      });
  }

  changeSelectedYear(year: {
    id: number;
    code: string;
    name: string;
    disabled: boolean;
    isChecked: boolean;
  }) {
    let previous = this.selectedYear?.perfYear;
    this.selectedYear = {
      perfYear: year.id,
      perfYearCode: year.code,
      name: `${year.code} ${year.code}`
    };
    let url = this.router.url.split(('/' + previous))[0];
    const newUrl: string = url + '/' + this.selectedYear?.perfYear + '/' + this.selectedYear.perfYearCode;
    this.router.navigate([newUrl])
  }

  // Ritorna le tab con numero massimo obiettivi e peso
  getUserProfileTab(updateOnly?: boolean) {
    if (!this.selectedYear) {
      console.log("No selected year");
      return;
    }

    if (!updateOnly) {
      this.isLoadingTab = true;
    }
    if (this.getUserProfileTab$) {
      this.getUserProfileTab$.unsubscribe();
    }
    this.isScheduleShared = false;
    this.isPostCalibration = false;
    this.getUserProfileTab$ = this.peopleAppraisalService.getUserPhaseDataForManager(this.selectedYear.perfYear, this.selectedYear.perfYearCode, this.userId)
      .subscribe((data: SenecaResponse<any>) => {
        if (data && (data.error || !data.response)) {
          this.getUserProfileTab$.unsubscribe();
          if (window.location.href.indexOf('personDetails') > -1) {
            this.redirectService.goToErrorPage(data && data.error);
          }
        } else {
          this.contentTabList = []
          let stepNumber = 1;
          this.stepList = [];
          let tempStep = data.response.find((step: any) => step.phaseKey == PerfAlloyAssessmentPhaseKeys.GOAL_EVALUATION)
          if (tempStep) {
            this.stepList.push(
              {
                id: 'objectiveEvaluation',
                referenceId: tempStep.referenceId,
                number: stepNumber,
                stepId: 1,
                title: this.translations["stepper.OBJECTIVE_EVALUATION"],
                isComplete: tempStep.statusType == RentStatusTypes.COMPLETED || tempStep.statusType == "SYSTEM_COMPLETED"
              }
            )
          }
          tempStep = data.response.find((step: any) => step.phaseKey == PerfAlloyAssessmentPhaseKeys.BEHAVIORS_EVALUATION)
          if (tempStep) {
            stepNumber += 1;
            this.stepList.push(
              {
                id: 'behaviourEvaluation',
                referenceId: tempStep.referenceId,
                number: stepNumber,
                stepId: 2,
                title: this.translations["stepper.BEHAVIOUR_EVALUATION"],
                isComplete: tempStep.statusType == RentStatusTypes.COMPLETED
              }
            )
          }
          tempStep = data.response.find((step: any) => step.phaseKey == PerfAlloyAssessmentPhaseKeys.COMPETENCES_EVALUATION)
          if (tempStep) {
            stepNumber += 1;
            if (this.yearsWithSpecialisticGoalsToEvaluate) {
              this.stepList.push(
                {
                  id: 'techEvaluation',
                  referenceId: tempStep.referenceId,
                  number: stepNumber,
                  stepId: 3,
                  title: this.translations["stepper.TECH_OBJECTIVES"],
                  isComplete: tempStep.statusType == RentStatusTypes.COMPLETED
                }
              )
            } else {
              this.stepList.push(
                {
                  id: 'techEvaluation',
                  referenceId: tempStep.referenceId,
                  number: stepNumber,
                  stepId: 3,
                  title: this.translations["stepper.TECH_SKILL_EVALUATION"],
                  isComplete: tempStep.statusType == RentStatusTypes.COMPLETED
                }
              )
            }
          } else {
            this.isManagement = true;
          }
          tempStep = data.response.find((step: any) => step.phaseKey == PerfAlloyAssessmentPhaseKeys.GOAL_DEFINITION)
          if (tempStep) {
            stepNumber += 1;
            this.stepList.push(
              {
                id: 'defineObjectives',
                referenceId: tempStep.referenceId,
                number: stepNumber,
                stepId: 4,
                title: this.translations["stepper.DEFINE_OBJECTIVES"],
                isComplete: tempStep.statusType == RentStatusTypes.COMPLETED
              }
            )
          }
          tempStep = data.response.find((step: any) => step.phaseKey == PerfAlloyAssessmentPhaseKeys.FINAL_EVALUATION)
          if (tempStep) {
            stepNumber += 1;
            this.stepList.push(
              {
                id: 'finalEvaluation',
                referenceId: tempStep.referenceId,
                number: stepNumber,
                stepId: 5,
                title: this.translations["stepper.FINAL_EVALUATION"],
                isWaiting: tempStep.statusType == RentStatusTypes.SHARED_FINAL_FORM,
                isComplete: tempStep.statusType == RentStatusTypes.COMPLETED
              }
            )
            this.isScheduleShared = tempStep.statusType == RentStatusTypes.SHARED_FINAL_FORM;
            this.isPostCalibration = tempStep.statusType == 'RECEIVED_FINAL_FORM'; //TODO aggiorna con common class appena c'è
            this.isFinalStepCompleted = tempStep.statusType == RentStatusTypes.COMPLETED;
          }
          this.hasToOpenTutorial();
          if (!updateOnly && !this.forcePasStructureTutorial) {
            for (let i = 0; i < this.stepList.length; i++) {
              if (!this.stepList[i].isComplete) {
                this.currentStep = this.stepList[i];
                break;
              }
            }
            if (!this.currentStep.stepId) {
              this.currentStep = this.stepList[this.stepList.length - 1];
            }
          } else if (this.forcePasStructureTutorial) {
            // Nel caso del tutorial della valutazione obiettivi bisogna prima caricare i dati, quindi passo direttamente lo step anche se non è stato preselezionato
            if (!this.forcePasTutorialStep.id) {
              this.currentStep = this.stepList[0];
            } else {
              this.currentStep = this.forcePasTutorialStep;
            }
          }
        }
        this.cdr.detectChanges();
        this.isLoadingTab = false;
        this.isLoadingPersonDetails = false;

        if (!updateOnly && !this.forcePasStructureTutorial) {
          this.onStepClicked(this.currentStep, this.forcePasStructureTutorial === "true");
        }
      }, (err?: any) => {
        const messageObj: ApplicationModalMessage = {
          modalId: "112",
          text: this.translate.instant("errors." + ((err && err.message) || err)),
          title: this.translate.instant("generic.WARNING")
        }
        this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        this.isLoadingTab = false;
        this.isLoadingPersonDetails = false;
      });
  }

  // Cambio scheda header dossier
  changeButtonHeaderDossier(id: string) {
    if (!this.selectedYear) {
      console.log("No selected year");
      return;
    }

    switch (id) {
      case 'pas':
        this.router.navigate(['/peopleAppraisal/personDetails/', this.userId, this.selectedYear.perfYear, this.selectedYear.perfYearCode])
        break;
      case 'feedback':
        this.router.navigate(['/peopleAppraisal/personDetails/feedback/', this.userId, this.selectedYear.perfYear, this.selectedYear.perfYearCode])
        break;
      case 'notes':
        this.router.navigate(['/peopleAppraisal/personDetails/myNotes/', this.userId, this.selectedYear.perfYear, this.selectedYear.perfYearCode])
        break;
    }
  }

  // Cambio tab
  onContentTabChanged(tab: TabDossier) {
    this.currentContentTab = tab;
  }

  // Cambio step
  onStepClicked(step: any, skipCheck?: boolean) {
    this.tempStep = step;
    if (!skipCheck && this.currentStep.stepId == 1 && this.anyEvaluationChangesToSave()) {
      this.modalService.open('save-objective-modal');
    } else if (!skipCheck && this.currentStep.stepId == 2 && this.anyBehaviourEvaluationChangesToSave()) {
      this.modalService.open('save-behaviours-modal');
    } else if (!skipCheck && this.currentStep.stepId == 3 && this.anyTechSkillEvaluationChangesToSave()) {
      this.modalService.open('save-tech-skills-modal');
    } else if (!skipCheck && this.currentStep.stepId == 4 && this.anyDefineObjectiveChangesToSave()) {
      this.modalService.open('save-defined-objective-modal');
    } else if (!skipCheck && this.currentStep.stepId == 5 && this.anythingToSaveInFinalEvaluation()) {
      this.modalService.open('save-final-evaluation-modal');
    } else {
      this.currentStep = step;
      if (this.currentStep.stepId == 1 || this.isGoalsUpdatePhase) {
        this.getUserGoalListToEvaluate();
      } else if (this.currentStep.stepId == 2) {
        this.getBehavioursToEvaluateForManager();
      } else if (this.currentStep.stepId == 3) {
        this.getCompetenceToEvaluateForManager();
      } else if (this.currentStep.stepId == 4) {
        this.getUserGoalListToDefine();
      } else if (this.currentStep.stepId == 5) {
        this.getFinalEvaluationData();
      }
    }
  }

  closeSaveObjectivesModal(confirm: boolean, closeOnly?: boolean) {
    this.modalService.close('save-objective-modal');
    if (!closeOnly) {
      if (confirm) {
        this.saveObjectiveEvaluation();
      } else {
        this.currentStep = this.tempStep;
        this.onStepClicked(this.tempStep, this.tempStep.number == 1);
      }
    }
  }

  closeSaveBehavioursModal(confirm: boolean, closeOnly?: boolean) {
    this.modalService.close('save-behaviours-modal');
    if (!closeOnly) {
      if (confirm) {
        this.saveBehavioursRatings();
      } else {
        this.currentStep = this.tempStep;
        this.onStepClicked(this.tempStep, this.tempStep.number == 2);
      }
    }
  }

  closeSaveTechSkillsModal(confirm: boolean, closeOnly?: boolean) {
    this.modalService.close('save-tech-skills-modal');
    if (!closeOnly) {
      if (confirm) {
        this.saveTechSkillsRatings();
      } else {
        this.currentStep = this.tempStep;
        this.onStepClicked(this.tempStep, this.tempStep.number == 3);
      }
    }
  }

  openHelpModal(stepId: string) {
    switch (stepId) {
      case 'objectiveEvaluation':
        const messageObjEva: ApplicationModalMessage = {
          modalId: "infoObjEv",
          title: this.translations["objectiveEvaluation.MODAL_TITLE"],
          subtitle: this.translations["objectiveEvaluation.MODAL_SUB"],
          text: this.translations["objectiveEvaluation.MODAL_TEXT"],
        }
        this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObjEva }));
        break;
      case 'techEvaluation':
        this.modalService.open('competence-dictionary');
        break;
      case 'notes':
        break;
      default:
        break;
    }
  }

  // Da aggioranre passando l'oggetto dell'obiettivo
  onEvaluationChanged(text: string, objective: any) {
    objective.evaluation = text;
  }
  onGoalsUpdateChanged(text: string, index: number) {
    this.textGoals[index] = text;
    if (this.textGoals?.length && !this.textGoals.every(val => val === "")) {
      this.textExists = true;
    }
    else {
      this.textExists = false;
    }

  }
  openUpdatesModal(index: number) {
    let serviceYear = this.getEvaluationYear();

    this.getGoalUpdates$ = this.peopleAppraisalService.getUserGoalListToEvaluate(serviceYear.year, serviceYear.code, [this.userId], true)
      .subscribe((data: SenecaResponse<any>) => {
        if (data && data.error) {
        } else {
          this.goalUpdatesData = data.response;
          this.isLoadingGoalUpdates = false;
        }
      })

    this.modalDataList = this.goalUpdatesData[index].goalUpdates;
    this.modalService.open('update-added')
  }
  closeUpdateModal() {
    this.modalService.close('update-added')
  }

  // Salva la valutazione degli obiettivi 
  saveObjectiveEvaluation(isUpdate?: boolean) {
    this.isLoadingToEvaluateGoals = true;
    let evaluationForService: { goalId: string, evaluationText: string }[] = [];
    if (isUpdate) {
      for (let i = 0; i < this.finalEvaluation.evaluatedData.length; i++) {
        evaluationForService.push({
          goalId: this.finalEvaluation.evaluatedData[i].goalId,
          evaluationText: this.finalEvaluation.evaluatedData[i].evaluation.trim()
        })
      }
    } else {
      if (this.toEvaluateList && this.toEvaluateList.length) {
        for (let i = 0; i < this.toEvaluateList.length; i++) {
          evaluationForService.push({
            goalId: this.toEvaluateList[i].goalId,
            evaluationText: this.toEvaluateList[i].evaluation?.trim()
          })
        }
      }
    }
    this.createOrUpdatePerformanceAssessmentGalEvaluationForManager$ = this.peopleAppraisalService.createOrUpdatePerformanceAssessmentGalEvaluationForManager(this.userId, evaluationForService)
      .subscribe((data: SenecaResponse<boolean>) => {
        this.isLoadingToEvaluateGoals = false;
        if (data && data.error) {
          const messageObj: ApplicationModalMessage = {
            modalId: "pdm001",
            text: this.translate.instant("errors." + data.error),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        } else {
          this.toEvaluateListCopy = JSON.parse(JSON.stringify(this.toEvaluateList));
          window.scroll({ top: 0, left: 0, behavior: 'smooth' });
          this.updateUserPhaseStatus();
          this.showSaveSuccessfulToaster();
          this.getUserProfileTab(true);
          this.goToNextStep();
        }
      }, (err?: any) => {
        this.isLoadingToEvaluateGoals = false;
        const messageObj: ApplicationModalMessage = {
          modalId: "109",
          text: this.translate.instant("errors." + ((err && err.message) || err)),
          title: this.translate.instant("generic.WARNING")
        }
        this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
      });
  }

  openSaveGoalUpdatesForManagerModal() {
    this.modalService.open('confirm-update-added');
  }

  //Salvo gli aggiornamenti degli obiettivi
  saveGoalUpdatesForManager() {
    this.isLoadingGoalUpdates = true;
    //Controllo se la chiamata esiste
    if (this.saveGoalUpdatesForManager$) {
      this.saveGoalUpdatesForManager$.unsubscribe();
    }

    //Inserisco tutti gli aggiornamenti di ogni obiettivo
    for (let i = 0; i < this.goalUpdatesData.length; i++) {
      if (this.textGoals[i]) {
        this.updatesForManager.push({
          goalId: this.goalUpdatesData[i].goalId,
          description: this.textGoals[i]
        })
      }
    }

    //Eseguo la chiamata passando gli aggiornamenti di ogni obiettivo
    this.saveGoalUpdatesForManager$ = this.peopleAppraisalService.addOrUpdateGoalsForManager(this.goalUpdatesData[0].targetUserId, this.updatesForManager, this.goalUpdatesData[0].perfYear, this.goalUpdatesData[0].perfYearCode)
      .subscribe((data: SenecaResponse<any[]>) => {
        if (data && data.error) {
          const messageObj: ApplicationModalMessage = {
            modalId: "updateGoals001",
            text: this.translate.instant("errors." + data.error),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        } else {
          //Resetto le varibili e faccio un refresh della pagina
          this.updatesForManager = [];
          this.textGoals = [];
          this.getPerformanceAssessmentRunningPhase()
        }
        this.isLoadingGoalUpdates = false;
      });
  }
  confirmSaveGoalUpdatesForManagerModal() {
    this.modalService.close('confirm-update-added');
    this.saveGoalUpdatesForManager();
  }

  closeSaveGoalUpdatesForManagerModal() {
    this.modalService.close('confirm-update-added');
  }

  openCompetenceDictionaryModal(competence: any) {
    this.competenceForModal = competence;
    this.modalService.open("competence-dictionary");
  }

  closeCompetenceDictionaryModal() {
    this.modalService.close("competence-dictionary");
  }

  // generica per i commenti nelle textarea, prende testo e variabile dove salvarlo
  onTextAreaChanged(text: string, object: string) {
    object = text;
  }

  // Definizione obiettivi
  // Aggiungi nuovo obiettivo
  addNewObjective() {
    this.newObjectiveList.push({
      tmpId: this.newObjectiveList.length.toString(),
      id: '',
      title: '',
      isEdit: false
    })
  }

  onReproposeObjChecked(obj: any) {
    if (obj) {
      if (obj.isSelected) {
        // Obiettivo già selezionato, quindi lo devo rimuovere
        for (let i = 0, selectedReproposeObjLength = this.selectedReproposeObj.length; i < selectedReproposeObjLength; i++) {
          if (this.selectedReproposeObj[i].goalId === obj.goalId) {
            this.selectedReproposeObj.splice(i, 1);
            break;
          }
        }
      } else {
        // Obiettivo che è appena stato selezionato, quindi lo aggiungo tra i selezionati
        if (!this.selectedReproposeObj) {
          this.selectedReproposeObj = [];
        }

        this.selectedReproposeObj.push(obj);
      }
      obj.isSelected = !obj.isSelected;
    }
  }

  // Apre la modale per riproporre un obiettivo
  openReproposeObjModal() {
    this.reproposeObjData = null;
    this.selectedReproposeObj = null;
    this.modalService.open('repropose-obj');

    this.isLoadingReproposeObjData = true;
    if (this.openReproposeObjModal$) {
      this.openReproposeObjModal$.unsubscribe();
    }
    let serviceYear = this.getEvaluationYear();
    this.openReproposeObjModal$ = this.peopleAppraisalService.getUserGoalListToEvaluate(serviceYear.year, serviceYear.code, [this.userId], this.isGoalsUpdatePhase)
      .subscribe((data: SenecaResponse<any[]>) => {
        if (data && data.error) {
          this.closeReproposeObjModal();
          const messageObj: ApplicationModalMessage = {
            modalId: "pdm005",
            text: this.translate.instant("errors." + data.error),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          this.isLoadingReproposeObjData = false;
        } else {
          this.reproposeObjData = [];
          this.selectedReproposeObj = [];

          if (data.response && data.response.length) {
            for (let i = 0; i < data.response.length; i++) {
              let currentData = data.response[i];
              if (currentData.creationUser) {
                let formattedCreationUser = '';
                if (currentData.creationUser.forename) {
                  formattedCreationUser = currentData.creationUser.forename;
                }
                if (currentData.creationUser.surname) {
                  formattedCreationUser = formattedCreationUser + ' ' + currentData.creationUser.surname;
                }

                currentData.formattedCreationUser = formattedCreationUser;
              } else if (currentData.creationUserId) {
                if (currentData.creationUserId === '**SYSTEM**') {
                  currentData.enteredBySystem = true;
                } else if (currentData.creationUserId === '**MANUAL**') {
                  currentData.manuallyEntered = true;
                }
              }

              this.reproposeObjData.push({
                ...currentData,
                isSelected: false
              })
            }
          }
          this.isLoadingReproposeObjData = false;
        }
      }, (err?: any) => {
        this.closeReproposeObjModal();
        const messageObj: ApplicationModalMessage = {
          modalId: "pdm006",
          text: this.translate.instant("errors." + err?.message),
          title: this.translate.instant("generic.WARNING")
        }
        this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        this.isLoadingReproposeObjData = false;
      });
  }

  // Chiude la modale per riproporre un obiettivo
  closeReproposeObjModal(confirm?: boolean) {
    this.modalService.close('repropose-obj');
    if (confirm) {
      this.isLoadingDefinedObjectives = true;
      if (this.clonePerformanceAssessmentGoalsByGoalIds$) {
        this.clonePerformanceAssessmentGoalsByGoalIds$.unsubscribe();
      }

      let goalIds = [];

      for (let i = 0, selectedReproposeObjLength = this.selectedReproposeObj.length; i < selectedReproposeObjLength; i++) {
        goalIds.push(this.selectedReproposeObj[i].goalId);
      }

      let serviceYear = this.getEvaluationYear();

      this.clonePerformanceAssessmentGoalsByGoalIds$ = this.peopleAppraisalService.clonePerformanceAssessmentGoalsByGoalIds(this.userId, goalIds, serviceYear.year, serviceYear.code)
        .subscribe((data: SenecaResponse<any>) => {
          if (data && data.error) {
            this.closeReproposeObjModal();
            const messageObj: ApplicationModalMessage = {
              modalId: "pdm005",
              text: this.translate.instant("errors." + data.error),
              title: this.translate.instant("generic.WARNING")
            }
            this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          } else {
            // Gli obiettivi riproposti sono stati salvati. Quindi li aggiungi nell'array di obiettivi già esistenti (potrebbe essere che, prima di riproporre, l'utente abbia aggiunto qualche obiettivo custom senza salvare)
            const addedGoals = data.response && data.response.createdUserGoals || [];
            const toastrToShow = addedGoals && addedGoals.length && addedGoals.length === 1 ? "objectiveReproposed" : "objectivesReproposed";
            let x: any = document.getElementById(toastrToShow);
            if (x) {
              x.className = "show";
              setTimeout(() => { x.className = x.className.replace("show", ""); }, 2000);
            }

            let objsRemovedWithoutSaving = this.newObjectiveListCopy.length != this.newObjectiveList.length;
            let hasCustomGoalToSave;
            if (this.newObjectiveList && this.newObjectiveList.length) {
              for (let q = 0, goalsListLength = this.newObjectiveList.length; q < goalsListLength; q++) {
                if (this.newObjectiveList[q].tmpId) {
                  hasCustomGoalToSave = true;
                  break;
                }
              }
            }

            if ((hasCustomGoalToSave || objsRemovedWithoutSaving || (this.objectivesToDelete && this.objectivesToDelete.length)) && addedGoals && addedGoals.length) {
              for (let m = 0, resLength = addedGoals.length; m < resLength; m++) {
                const currentRes = addedGoals[m];
                const formattedGoal = {
                  id: currentRes.goalId,
                  title: currentRes.text,
                  cloned: currentRes.cloned,
                  count: [m + 1]
                };
                this.newObjectiveList.push(formattedGoal);
                this.newObjectiveListCopy.push(formattedGoal);
              }
            } else {
              if (this.newObjectiveList && this.newObjectiveList.length) {
                this.newObjectiveList.length = 0;
              }
              this.newObjectiveListCopy = this.newObjectiveList.length ? JSON.parse(JSON.stringify(this.newObjectiveList)) : [];
              this.updateUserPhaseStatus();
              this.getUserGoalListToDefine();
              this.getUserProfileTab(true);
            }

            /* Da decommentare se si vuole fare in modo che, dopo il riproponi, venga fatto il refresh dello step
              if (this.newObjectiveList && this.newObjectiveList.length) {
                this.newObjectiveList.length = 0;
              }
              this.newObjectiveListCopy = this.newObjectiveList.length ? JSON.parse(JSON.stringify(this.newObjectiveList)) : [];
              this.updateUserPhaseStatus();
              this.getUserGoalListToDefine();
              this.showSaveSuccessfulToaster();
              this.getUserProfileTab(true); */
          }
          this.reproposeObjData = null;
          this.selectedReproposeObj = null;
          this.isLoadingDefinedObjectives = false;
        }, (err?: any) => {
          this.reproposeObjData = null;
          this.selectedReproposeObj = null;
          const messageObj: ApplicationModalMessage = {
            modalId: "pdm006",
            text: this.translate.instant("errors." + err?.message),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          this.reproposeObjData = null;
          this.selectedReproposeObj = null;
          this.isLoadingDefinedObjectives = false;
        });
    } else {
      this.reproposeObjData = null;
      this.selectedReproposeObj = null;
    }
  }

  // Elimina definitivamente tutti gli obiettivi
  deleteAllSelectedObjectives() {
    // Salvo i valori del rating
    let promises = [];
    for (let j = 0, objectivesToDeleteLength = this.objectivesToDelete.length; j < objectivesToDeleteLength; j++) {
      const objectivesToDelete = this.objectivesToDelete[j];

      promises.push(new Promise((resolve: Function, reject: Function) => {
        this.peopleAppraisalService.deletePerformanceAssessmentGoalsForManager(this.userId, [objectivesToDelete.id])
          .subscribe((data: SenecaResponse<boolean>) => {
            if (data && data.error) {
              const messageObj: ApplicationModalMessage = {
                modalId: "pdm017",
                text: this.translate.instant("errors." + data.error),
                title: this.translate.instant("generic.WARNING")
              }
              this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
              resolve();
            } else {
              resolve();
            }
          }, (err?: any) => {
            resolve();
            const messageObj: ApplicationModalMessage = {
              modalId: "pdm018",
              text: this.translate.instant("errors." + err?.message),
              title: this.translate.instant("generic.WARNING")
            }
            this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
            resolve();
          });
      }));
    }
    // Risolvo le promesse coi dati recuperati
    return Promise.all(promises);
  }

  // salva gli obiettivi aggiunti
  saveDefinedObjective() {
    this.isLoadingDefinedObjectives = true;

    let goals = [];
    for (let i = 0; i < this.newObjectiveList.length; i++) {
      if (!this.newObjectiveList[i].cloned) {
        goals.push({
          goalId: this.newObjectiveList[i].id,
          text: this.newObjectiveList[i].title.trim()
        });
      }
    }

    // Prima cancello anche gli eventi obiettivi da cancellare
    if (this.objectivesToDelete && this.objectivesToDelete.length) {
      let deleteAllSelectedObjectivesPromise = this.deleteAllSelectedObjectives();
      deleteAllSelectedObjectivesPromise.then(() => {
        this.objectivesToDelete.length = 0;
        this.saveDefinedObjective();
      })
    } else if (this.newObjectiveList && this.newObjectiveList.length && goals?.length) {
      /* for (let i = 0; i < this.newObjectiveList.length; i++) {
         this.newObjectiveList[i].id = 'saved' + (this.newObjectiveList.length + i);
         this.newObjectiveList[i].isEdit = false;
       }*/

      if (this.createOrUpdatePerformanceAssessmentGoalsForManager$) {
        this.createOrUpdatePerformanceAssessmentGoalsForManager$.unsubscribe();
      }
      this.createOrUpdatePerformanceAssessmentGoalsForManager$ = this.peopleAppraisalService.createOrUpdatePerformanceAssessmentGoalsForManager(this.userId, goals)
        .subscribe((data: SenecaResponse<any>) => {
          if (data && data.error) {
            const messageObj: ApplicationModalMessage = {
              modalId: "pdm001",
              text: this.translate.instant("errors." + data.error),
              title: this.translate.instant("generic.WARNING")
            }
            this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          } else {
            if (this.newObjectiveList && this.newObjectiveList.length) {
              this.newObjectiveList.length = 0;
            }
            this.newObjectiveListCopy = this.newObjectiveList.length ? JSON.parse(JSON.stringify(this.newObjectiveList)) : [];
            this.updateUserPhaseStatus();
            this.getUserGoalListToDefine();
            this.showSaveSuccessfulToaster();
            this.getUserProfileTab(true);
            // const messageObj = {
            //   modalId: "mt005",
            //   title: this.translate.instant("stepper.DEFINE_OBJECTIVES"),
            //   text: this.translate.instant("stepper.OBJECTIVES_SAVED")
            // }
            // this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));

            // Vado allo step successivo
            this.goToNextStep();
          }
          this.isLoadingDefinedObjectives = false;
        }, (err?: any) => {
          this.isLoadingDefinedObjectives = false;
          const messageObj: ApplicationModalMessage = {
            modalId: "pdm002",
            text: this.translate.instant("errors." + err?.message),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        });
    } else {
      this.isLoadingDefinedObjectives = false;
      this.newObjectiveListCopy = this.newObjectiveList.length ? JSON.parse(JSON.stringify(this.newObjectiveList)) : [];
      this.goToNextStep();
    }
  }

  anyDefineObjectiveChangesToSave() {
    if (!this.isLoadingDefinedObjectives && this.newObjectiveList && this.newObjectiveListCopy) {
      if ((this.newObjectiveList.length != this.newObjectiveListCopy.length) || (this.objectivesToDelete && this.objectivesToDelete.length)) {
        return true;
      } else {
        for (let i = 0; i < this.newObjectiveList.length; i++) {
          if (this.newObjectiveList[i].title != this.newObjectiveListCopy[i].title) {
            return true;
          }
        }
      }
    }
    return false;
  }

  closeSaveDefinedObjectivesModal(confirm: boolean, closeOnly?: boolean) {
    this.modalService.close('save-defined-objective-modal');
    if (!closeOnly) {
      if (confirm) {
        this.saveDefinedObjective();
      } else {
        this.currentStep = this.tempStep;
        this.onStepClicked(this.tempStep, this.tempStep.number == 4);
      }
    }
  }

  closeSaveFinalEvaluationModal(confirm: boolean, closeOnly?: boolean) {
    this.modalService.close('save-final-evaluation-modal');
    if (!closeOnly) {
      if (confirm) {
        this.saveFinalEvaluationStep();
      } else {
        this.currentStep = this.tempStep;
        this.onStepClicked(this.tempStep, this.tempStep.number == 5);
      }
    }
  }

  deleteObjective(objective: any) {
    // momentaneo per testare
    if (!objective.id && objective.tmpId) {
      // Se non ha un id, significa che non è ancora stato salvato, quindi mi baso sul tmpId
      this.newObjectiveList = this.newObjectiveList.filter((x: any) => x.tmpId != objective.tmpId);
    } else {
      // In caso contrario devo chiamare i servizi per l'eliminazione vera e propria
      if (this.deletePerformanceAssessmentGoalsForManager$) {
        this.deletePerformanceAssessmentGoalsForManager$.unsubscribe();
      }
      this.deletePerformanceAssessmentGoalsForManager$ = this.peopleAppraisalService.deletePerformanceAssessmentGoalsForManager(this.userId, [objective.id])
        .subscribe((data: SenecaResponse<any>) => {
          if (data && data.error) {
            const messageObj: ApplicationModalMessage = {
              modalId: "pdm003",
              text: this.translate.instant("errors." + data.error),
              title: this.translate.instant("generic.WARNING")
            }
            this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          } else {
            if (this.newObjectiveList && this.newObjectiveList.length) {
              this.newObjectiveList.length = 0;
            }
            this.showSaveSuccessfulToaster();
            this.getUserProfileTab(true);
            if (this.currentStep.stepId == 5) {
              this.getFinalEvaluationData();
            } else {
              this.getUserGoalListToDefine();
            }
          }
          this.isLoadingDefinedObjectives = false;
        }, (err?: any) => {
          this.isLoadingDefinedObjectives = false;
          const messageObj: ApplicationModalMessage = {
            modalId: "pdm004",
            text: this.translate.instant("errors." + err?.message),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        });
    }
  }

  onObjectiveTitleChanged(text: string, objective: any) {
    objective.title = text
  }

  onMenuOptionClicked(option: string, objective: any) {
    if (option == 'edit') {
      objective.isEdit = true;
    } else {
      if (!objective.id && !objective.isEdit) {
        this.deleteObjective(objective);
      } else {
        // this.openDeleteSavedObjective(objective);
        for (let i = 0, objectivesLength = this.newObjectiveList.length; i < objectivesLength; i++) {
          const currentObjective = this.newObjectiveList[i];

          if (currentObjective && currentObjective.id && objective && objective.id
            && currentObjective.id === objective.id) {
            this.objectivesToDelete.push(currentObjective);
            this.newObjectiveList.splice(i, 1);
            break;
          }
        }
      }
    }
  }

  // Apre la modale con la spiegazione dei performer
  openPerformerModal(performerKey?: string) {
    this.selectedInfoTabPerformerKey = performerKey;
    this.modalService.open("performerEvaluatorInfoModal");
  }

  // Chiude la modale con la spiegazione dei performer
  closePerformerModal() {
    this.modalService.close("performerEvaluatorInfoModal");
    this.selectedInfoTabPerformerKey = '';
  }

  selectObjectiveFinalEvaluation(objective: any) {
    this.selectedObjective = objective;
  }

  openDeleteNoteModal(id: string) {
    this.noteIdToDelete = id;
    this.modalService.open('deleteNoteModal');
  }

  closeDeleteNoteModal(confirmDelete?: boolean) {
    if (!this.selectedYear) {
      console.log("No selected year");
      return;
    }

    this.modalService.close('deleteNoteModal');
    if (confirmDelete) {
      this.isLoadingPrivateNotes = true;
      this.peopleAppraisalService.deletePrivateNote(this.selectedYear.perfYear, this.selectedYear.perfYearCode, this.noteIdToDelete, this.userId)
        .subscribe((data: SenecaResponse<DeletePrivateNoteFeedbackForManagerResponse>) => {
          if (data && data.error) {
            const messageObj: ApplicationModalMessage = {
              modalId: "pn023",
              text: this.translate.instant("errors." + data.error),
              title: this.translate.instant("generic.WARNING")
            }
            this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          } else {
            const messageObj: ApplicationModalMessage = {
              modalId: "pn026",
              title: this.translate.instant("privateNotes.modals.NOTE_DELETED"),
              text: this.translate.instant("privateNotes.modals.NOTE_DELETED_DESCR")
            }
            this.isLoadingPrivateNotes = false;
            this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          }
          // this.getCounterFeedbackTabs();
          this.getPrivateNoteForManager();
        }, (err) => {
          const messageObj: ApplicationModalMessage = {
            modalId: "pn024",
            text: this.translate.instant("errors." + err),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        })
    }
  }

  goToEditPrivateNote(noteId: string) {
    this.redirectService.goToCreatePrivateNoteForPeopleAppraisal(this.personDetails.userId, noteId);
  }


  createPrivateNote() {
    this.redirectService.goToCreatePrivateNoteForPeopleAppraisal(this.personDetails.userId, '');
  }

  /** Ritorna il processo precendente a quello attuale */
  getEvaluationYear() {
    if (this.selectedYear?.perfYearCode == PerfAlloyYearCodes.H1) {
      return {
        year: this.selectedYear.perfYear - 1,
        code: PerfAlloyYearCodes.H2
      }
    } else {
      return {
        year: <number>this.selectedYear?.perfYear,
        code: PerfAlloyYearCodes.H1
      }
    }
  }

  getUserGoalListToEvaluate() {
    this.isLoadingToEvaluateGoals = true;
    if (this.getUserGoalToEvaluate$) {
      this.getUserGoalToEvaluate$.unsubscribe();
    }
    let serviceYear = this.getEvaluationYear();
    this.getUserGoalToEvaluate$ = this.peopleAppraisalService.getUserGoalListToEvaluate(serviceYear.year, serviceYear.code, [this.userId], true)
      .subscribe((data: SenecaResponse<PerfAlloyUserGoal[]>) => {
        if (data && data.error) {
          const messageObj: ApplicationModalMessage = {
            modalId: "pdm005",
            text: this.translate.instant("errors." + data.error),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          this.isLoadingToEvaluateGoals = false;
        } else {
          this.toEvaluateList = [];
          for (let i = 0; i < data.response.length; i++) {
            this.toEvaluateList.push({
              ...data.response[i],
              isSelected: false,
              count: [i + 1],
              forename: this.insertByManager
            })
          }
          this.toEvaluateListCopy = JSON.parse(JSON.stringify(this.toEvaluateList));
          if (this.currentStep.isComplete == true) {
            window.scroll({ top: 0, left: 0, behavior: 'smooth' });
          }
          this.isLoadingToEvaluateGoals = false;
        }
        // Controllo se devo aprire automaticamente un tutorial (siamo nel caso in cui provengo dalla pagina di tutorial)
        this.hasToOpenTutorial(true);
      }, (err?: any) => {
        const messageObj: ApplicationModalMessage = {
          modalId: "pdm006",
          text: this.translate.instant("errors." + err?.message),
          title: this.translate.instant("generic.WARNING")
        }
        this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        this.isLoadingToEvaluateGoals = false;
      });
  }

  anyEvaluationChangesToSave() {
    if (this.toEvaluateList && this.toEvaluateList.length && this.toEvaluateListCopy.length) {
      for (let i = 0; i < this.toEvaluateList.length; i++) {
        if (this.toEvaluateList[i].evaluation != this.toEvaluateListCopy[i].evaluation) {
          return true;
        }
      }
    }
    return false;
  }

  // Recupera gli obiettivi da definire
  getUserGoalListToDefine() {
    if (!this.selectedYear) {
      console.log("No selected year");
      return;
    }

    this.isLoadingUserGoalToDefine = true;

    if (this.getUserGoalToDefine$) {
      this.getUserGoalToDefine$.unsubscribe();
    }

    this.newObjectiveList = [];

    this.getUserGoalToDefine$ = this.peopleAppraisalService.getUserGoalList(this.selectedYear.perfYear, this.selectedYear.perfYearCode, [this.userId])
      .subscribe((data: SenecaResponse<any>) => {
        if (data && data.error) {
          const messageObj: ApplicationModalMessage = {
            modalId: "pdm007",
            text: this.translate.instant("errors." + data.error),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        } else if (data.response && data.response.length) {
          for (let m = 0, resLength = data.response.length; m < resLength; m++) {
            const currentRes = data.response[m];
            this.newObjectiveList.push({
              id: currentRes.goalId,
              title: currentRes.text,
              cloned: currentRes.cloned,
              count: [m + 1],
              creationUser: currentRes.creationUser
            });
          }
        }
        this.hasToOpenTutorial();
        this.newObjectiveListCopy = this.newObjectiveList.length ? JSON.parse(JSON.stringify(this.newObjectiveList)) : [];
        if (this.currentStep.isComplete == true) {
          window.scroll({ top: 0, left: 0, behavior: 'smooth' });
        }
        this.isLoadingUserGoalToDefine = false;
      }, (err?: any) => {
        this.isLoadingUserGoalToDefine = false;
        const messageObj: ApplicationModalMessage = {
          modalId: "pdm008",
          text: this.translate.instant("errors." + err?.message),
          title: this.translate.instant("generic.WARNING")
        }
        this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
      });
  }

  getBehavioursToEvaluateForManager() {
    if (!this.selectedYear) {
      console.log("No selected year");
      return;
    }

    this.manageBehaviorComments = '';
    this.manageBehaviorCommentsCopy = '';
    this.behaviourCompetenceToChange = null;
    this.softSkillsFinalAvg = null;
    this.isLoadingBehaviours = true;
    this.getBehavioursToEvaluateForManager$ = this.peopleAppraisalService.getBehavioursToEvaluateForManager(this.selectedYear.perfYear, this.selectedYear.perfYearCode, this.userId)
      .subscribe((data: SenecaResponse<any>) => {
        if (data && data.error) {
          this.isLoadingBehaviours = false;
          const messageObj: ApplicationModalMessage = {
            modalId: "pdm009",
            text: this.translate.instant("errors." + data.error),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        } else {
          let ratingCounter = 0;
          let ratingSum = 0;
          let selfRatingCounter = 0;
          let selfRatingSum = 0;
          let ratingDoneCounter = 0;

          let parentBehaviours = data.response && data.response.behaviors && data.response.behaviors.map((x: any) => {
            return {
              behaviorId: x.parentBehavior.behaviorId,
              title: x.parentBehavior.text
            }
          })
          this.parentBehaviourIds = parentBehaviours.map((item: any) => item.behaviorId)
            .filter((value: any, index: number, self: any) => self.indexOf(value) === index);
          let tempBehaviour: any = {};
          for (let i = 0; i < this.parentBehaviourIds.length; i++) {
            for (let j = 0; j < data.response.behaviors.length; j++) {
              let behavior = data.response.behaviors[j];
              if (behavior.parentBehavior.behaviorId == this.parentBehaviourIds[i]) {
                if (tempBehaviour[this.parentBehaviourIds[i]] && tempBehaviour[this.parentBehaviourIds[i]].length && !tempBehaviour[this.parentBehaviourIds[i]].includes(behavior)) {
                  tempBehaviour[this.parentBehaviourIds[i]].push(behavior);
                } else if (!tempBehaviour[this.parentBehaviourIds[i]] || !tempBehaviour[this.parentBehaviourIds[i]].length) {
                  tempBehaviour[this.parentBehaviourIds[i]] = [behavior];
                }
              }
            }
          }
          this.competenceArray = [];
          let isPostCalibrationOrFinalStepCompleted = this.isPostCalibration || this.isFinalStepCompleted;

          for (let i = 0; i < this.parentBehaviourIds.length; i++) {
            let currentCompetenceRatingCounter = 0;
            let currentCompetenceRatingSum = 0;
            let currentCompetenceSelfRatingCounter = 0;
            let currentCompetenceSelfRatingSum = 0;

            let currentCompetenceRatingAvgNum = 0;
            let currentCompetenceSelfRatingAvgNum = 0;
            let currentCompetenceRatingAvgString = null;
            let currentCompetenceSelfRatingAvgString = null;
            let currentCompetenceRatingDoneCounter = 0;

            let parent = tempBehaviour[this.parentBehaviourIds[i]] && tempBehaviour[this.parentBehaviourIds[i]].length && tempBehaviour[this.parentBehaviourIds[i]][0].parentBehavior;
            let tempCompetence = [];
            if (isPostCalibrationOrFinalStepCompleted) {
              for (let j = 0; j < tempBehaviour[this.parentBehaviourIds[i]].length; j++) {
                const currentRating = tempBehaviour[this.parentBehaviourIds[i]][j].evaluationRating;
                const currentSelfRating = tempBehaviour[this.parentBehaviourIds[i]][j].colleagueEvaluationRating;

                let formattedTitle = tempBehaviour[this.parentBehaviourIds[i]][j].title || '';
                if (formattedTitle && formattedTitle.length && formattedTitle.includes('|')) {
                  formattedTitle = formattedTitle.split('|')[0];
                }

                tempCompetence.push({
                  ...tempBehaviour[this.parentBehaviourIds[i]][j],
                  title: formattedTitle,
                  rating: this.getBehaviourRating(currentRating, tempBehaviour[this.parentBehaviourIds[i]][j].evaluationScale.length),
                  selfRating: this.getBehaviourRating(currentSelfRating, tempBehaviour[this.parentBehaviourIds[i]][j].evaluationScale.length)
                });

                // Media finale
                ratingCounter++;
                selfRatingCounter++;
                ratingSum = ratingSum + (currentRating || 0);
                selfRatingSum = selfRatingSum + (currentSelfRating || 0);

                // Media parziale
                currentCompetenceRatingCounter++;
                currentCompetenceSelfRatingCounter++;
                currentCompetenceRatingSum = currentCompetenceRatingSum + (currentRating || 0);
                currentCompetenceSelfRatingSum = currentCompetenceSelfRatingSum + (currentSelfRating || 0);

                if (currentRating && currentRating > 0) {
                  currentCompetenceRatingDoneCounter++;
                  ratingDoneCounter++;
                }
              }
            } else {
              for (let j = 0; j < tempBehaviour[this.parentBehaviourIds[i]].length; j++) {
                const currentRating = tempBehaviour[this.parentBehaviourIds[i]][j].evaluationRating;

                let formattedTitle = tempBehaviour[this.parentBehaviourIds[i]][j].title || '';
                if (formattedTitle && formattedTitle.length && formattedTitle.includes('|')) {
                  formattedTitle = formattedTitle.split('|')[0];
                }

                tempCompetence.push({
                  ...tempBehaviour[this.parentBehaviourIds[i]][j],
                  title: formattedTitle,
                  rating: this.getBehaviourRating(currentRating, tempBehaviour[this.parentBehaviourIds[i]][j].evaluationScale.length)
                });

                // Media finale
                ratingCounter++;
                ratingSum = ratingSum + (currentRating || 0);

                // Media parziale
                currentCompetenceRatingCounter++;
                currentCompetenceRatingSum = currentCompetenceRatingSum + (currentRating || 0);

                if (currentRating && currentRating > 0) {
                  currentCompetenceRatingDoneCounter++;
                  ratingDoneCounter++;
                }
              }
            }

            // Alla fine, aggiungo la media parziale
            if (tempCompetence && tempCompetence.length) {
              currentCompetenceRatingAvgNum = +(currentCompetenceRatingSum / currentCompetenceRatingCounter).toFixed(2) || 0;
              currentCompetenceSelfRatingAvgNum = +(currentCompetenceSelfRatingSum / currentCompetenceSelfRatingCounter).toFixed(2) || 0;
              const currentCompetenceRatingEvalScale = this.techSkillArray && this.techSkillArray[0] && this.techSkillArray[0].evaluationScale && this.techSkillArray[0].evaluationScale.length || 5;

              currentCompetenceRatingAvgString = currentCompetenceRatingAvgNum.toString();
              if (currentCompetenceRatingAvgString && currentCompetenceRatingAvgString.indexOf(".") >= 0) {
                currentCompetenceRatingAvgString = currentCompetenceRatingAvgString.replace(".", ",");
              }

              currentCompetenceSelfRatingAvgString = currentCompetenceSelfRatingAvgNum.toString();
              if (currentCompetenceSelfRatingAvgString && currentCompetenceSelfRatingAvgString.indexOf(".") >= 0) {
                currentCompetenceSelfRatingAvgString = currentCompetenceSelfRatingAvgString.replace(".", ",");
              }

              tempCompetence.push({
                disabled: true,
                isPartialAverage: true,
                avgTitle: this.translate.instant("generic.PARTIL_AVG"),
                ratingScale: currentCompetenceRatingEvalScale || 5,
                ratingAvgNum: currentCompetenceRatingAvgNum,
                ratingStars: Math.round(currentCompetenceRatingAvgNum),
                selfRatingAvgNum: currentCompetenceSelfRatingAvgNum,
                selfRatingStars: Math.round(currentCompetenceSelfRatingAvgNum),
                ratingAvgString: currentCompetenceRatingAvgString,
                selfRatingAvgString: currentCompetenceSelfRatingAvgString,
                allCompetencesValuated: currentCompetenceRatingDoneCounter && currentCompetenceRatingDoneCounter === currentCompetenceRatingCounter
              });
            }

            if (parent.text && parent.text.length && parent.text.includes('|')) {
              parent.text = parent.text.split('|')[0];
            }
            this.competenceArray.push({
              category: parent.text,
              competences: tempCompetence,
              ratingScale: tempCompetence[0].evaluationScale.length || 5
            })
          }
          console.log("competenceArray", this.competenceArray);

          // Media finale
          let ratingAvgNum;
          let selfRatingAvgNum;
          let ratingAvgString;
          let selfRatingAvgString;

          ratingAvgNum = +(ratingSum / ratingCounter).toFixed(2) || 0;
          selfRatingAvgNum = +(selfRatingSum / selfRatingCounter).toFixed(2) || 0;

          const ratingEvalScale = this.competenceArray && this.competenceArray[0] && this.competenceArray[0].evaluationScale && this.competenceArray[0].evaluationScale.length || 5;

          ratingAvgString = ratingAvgNum.toString();
          if (ratingAvgString && ratingAvgString.indexOf(".") >= 0) {
            ratingAvgString = ratingAvgString.replace(".", ",");
          }

          selfRatingAvgString = selfRatingAvgNum.toString();
          if (selfRatingAvgString && selfRatingAvgString.indexOf(".") >= 0) {
            selfRatingAvgString = selfRatingAvgString.replace(".", ",");
          }

          // Indica se tutti i comportamenti hanno avuto almeno una valutazione
          let allBehavioursValuated = ratingDoneCounter && ratingDoneCounter === ratingCounter;

          this.softSkillsFinalAvg = {
            allBehavioursValuated: allBehavioursValuated,
            title: this.translate.instant('generic.FINAL_AVG'),
            subtitle: this.translate.instant('generic.SOFT_COMPETENCES'),
            ratingAvgNum: ratingAvgNum,
            ratingStars: Math.round(ratingAvgNum),
            selfRatingAvgNum: selfRatingAvgNum,
            selfRatingStars: Math.round(selfRatingAvgNum),
            ratingAvgString: ratingAvgString,
            selfRatingAvgString: selfRatingAvgString,
            ratingScale: ratingEvalScale,
            isPostCalibrationOrFinalStepCompleted: isPostCalibrationOrFinalStepCompleted
          }

          this.manageBehaviorComments = data.response && data.response.overallComment || '';
          if (this.manageBehaviorComments) {
            this.manageBehaviorCommentsCopy = JSON.parse(JSON.stringify(this.manageBehaviorComments));
          }

          this.competenceArrayCopy = JSON.parse(JSON.stringify(this.competenceArray));
          this.isLoadingBehaviours = false;
          // Controllo se devo aprire automaticamente un tutorial (siamo nel caso in cui provengo dalla pagina di tutorial)
          this.hasToOpenTutorial();

          // Calcolo le medie parziali di ogni comportamento


          /*
                    let getCalculatedStarsCountByAvgPromise = this.getCalculatedStarsCountByAvg(this.competenceArray);
                    getCalculatedStarsCountByAvgPromise.then(() => {
          
                      if (isPostCalibrationOrFinalStepCompleted) {
                        // Calcolo le medie parziali del self assessment
                        let getCalculatedStarsCountByAvgSelfPromise = this.getCalculatedStarsCountByAvg(this.competenceArray, true);
                        getCalculatedStarsCountByAvgSelfPromise.then(() => {
                          this.competenceArrayCopy = JSON.parse(JSON.stringify(this.competenceArray));
          
                          // Se tutti i comportamentis sono stati valorizzati, calcolo la media finale
                          if (allBehavioursValuated) {
                            let getFinalAvgStarRatingPromise = this.getFinalAvgStarRating(this.softSkillsFinalAvg);
                            getFinalAvgStarRatingPromise.then(() => {
                              // Calcolo la media finale del self assessment
                              let getFinalAvgStarRatingSelfPromise = this.getFinalAvgStarRating(this.softSkillsFinalAvg, true);
                              getFinalAvgStarRatingSelfPromise.then(() => {
                                this.isLoadingBehaviours = false;
          
                                // Controllo se devo aprire automaticamente un tutorial (siamo nel caso in cui provengo dalla pagina di tutorial)
                                this.hasToOpenTutorial();
                              });
                            });
                          } else {
                            this.isLoadingBehaviours = false;
          
                            // Controllo se devo aprire automaticamente un tutorial (siamo nel caso in cui provengo dalla pagina di tutorial)
                            this.hasToOpenTutorial();
                          }
                        });
                      } else {
                        // Se tutti i comportamentis sono stati valorizzati, calcolo la media finale
                        if (allBehavioursValuated) {
                          let getFinalAvgStarRatingPromise = this.getFinalAvgStarRating(this.softSkillsFinalAvg);
                          getFinalAvgStarRatingPromise.then(() => {
                            this.competenceArrayCopy = JSON.parse(JSON.stringify(this.competenceArray));
                            this.isLoadingBehaviours = false;
          
                            // Controllo se devo aprire automaticamente un tutorial (siamo nel caso in cui provengo dalla pagina di tutorial)
                            this.hasToOpenTutorial();
                          });
                        } else {
                          this.competenceArrayCopy = JSON.parse(JSON.stringify(this.competenceArray));
                          this.isLoadingBehaviours = false;
          
                          // Controllo se devo aprire automaticamente un tutorial (siamo nel caso in cui provengo dalla pagina di tutorial)
                          this.hasToOpenTutorial();
                        }
                      }
                    });
          
          
                    */
        }
      }, (err?: any) => {
        const messageObj: ApplicationModalMessage = {
          modalId: "pdm010",
          text: this.translate.instant("errors." + err?.message),
          title: this.translate.instant("generic.WARNING")
        }
        this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        this.isLoadingBehaviours = false;
      });
  }

  // Controlla se aprire automaticamente un tutorial (siamo nel caso in cui provengo dalla pagina di tutorial)
  hasToOpenTutorial(checkPersonalObjectiveEvaluationTutorial?: boolean) {
    this.checkPassStructureTutorial();
    if (checkPersonalObjectiveEvaluationTutorial) {
      this.checkPersonalObjectiveEvaluationTutorial();
    }
    this.checkOrganizationalBehaviorsTutorial();
    this.checkTechSkillEvaluationTutorial();
    this.checkSetGoalsTutorial();
  }

  // inizializza il rating dei comportamenti 
  getBehaviourRating(rating: number, ratingScale: number) {
    let starRating = JSON.parse(JSON.stringify(this.starsModel[ratingScale]));
    for (let i = 0; i < rating; i++) {
      starRating[i].isClicked = true;
    }
    return starRating;
  }

  // Aggiornamento valutazione comportamento
  behaviourRatingChanged(competence: any) {
    let behaviorEvaluation: { behaviorId: string, evaluationScore: string };
    let score = 0;
    for (let i = 0; i < competence.rating.length; i++) {
      if (competence.rating[i].isClicked) {
        score = competence.rating[i].id + 1;
      }
    }
    behaviorEvaluation = { behaviorId: competence.behaviorId, evaluationScore: score.toString() };
    let comment;
    if (this.manageBehaviorComments != this.manageBehaviorCommentsCopy) {
      comment = this.manageBehaviorComments;
    }

    this.createOrUpdatePerformanceAssessmentBehaviorEvaluationForManager$ = this.peopleAppraisalService.createOrUpdatePerformanceAssessmentBehaviorEvaluationForManager(this.userId, behaviorEvaluation, comment)
      .subscribe((data: SenecaResponse<boolean>) => {
        if (data && data.error) {
          const messageObj: ApplicationModalMessage = {
            modalId: "pdm017",
            text: this.translate.instant("errors." + data.error),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        } else {
          this.updateUserPhaseStatus();
          this.showSaveSuccessfulToaster();
          this.getUserProfileTab(true);
        }
      }, (err?: any) => {
        const messageObj: ApplicationModalMessage = {
          modalId: "pdm018",
          text: this.translate.instant("errors." + err?.message),
          title: this.translate.instant("generic.WARNING")
        }
        this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        this.isLoadingBehaviours = false;
      });
  }

  onManageBehaviorCommentChanged(text: string) {
    this.manageBehaviorComments = text
  }

  getCompetenceToEvaluateForManager() {
    if (!this.selectedYear) {
      console.log("No selected year");
      return;
    }

    this.isLoadingTechSkills = true;
    this.techSkillCompetenceToChange = null;
    this.techSkillsFinalAvg = null;

    if (this.getCompetenceToEvaluateForManager$) {
      this.getCompetenceToEvaluateForManager$.unsubscribe();
    }

    this.getCompetenceToEvaluateForManager$ = this.peopleAppraisalService.getCompetenceToEvaluateForManager(this.selectedYear.perfYear, this.selectedYear.perfYearCode, this.userId)
      .subscribe((data: SenecaResponse<any>) => {
        if (data && data.error) {
          const messageObj: ApplicationModalMessage = {
            modalId: "pdm009",
            text: this.translate.instant("errors." + data.error),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        } else {
          /*
          this.techSkills.competences = []
          if (this.isPostCalibration || this.isFinalStepCompleted) {
            for (let j = 0; j < data.response.competences.length; j++) {
              this.techSkills.competences.push({
                ...data.response.competences[j],
                rating: this.getBehaviourRating(data.response.competences[j].evaluationRating, data.response.competences[j].evaluationScale.length),
                selfRating: this.getBehaviourRating(data.response.competences[j].colleagueEvaluationRating, data.response.competences[j].evaluationScale.length)
              })
            }
          } else {
            for (let j = 0; j < data.response.competences.length; j++) {
              this.techSkills.competences.push({
                ...data.response.competences[j],
                rating: this.getBehaviourRating(data.response.competences[j].evaluationRating, data.response.competences[j].evaluationScale.length),
              })
            }
          }
          this.competenceForModal = {};
          this.competenceForModal.evaluationScale = data.response.competences[0].evaluationScale;
          this.techSkillRatingScale = (this.techSkills.competences && this.techSkills.competences[0] && this.techSkills.competences[0].evaluationScale.length) || 5;*/

          // Parte aggiunta per modificare le competenze tech come le competenze soft
          let ratingCounter = 0;
          let ratingSum = 0;
          let selfRatingCounter = 0;
          let selfRatingSum = 0;
          let ratingDoneCounter = 0;

          let parentCompetences = data.response && data.response.competences && data.response.competences.map((x: any) => {
            return {
              competenceId: x.parentCompetence.competenceId,
              title: x.parentCompetence.text
            }
          })
          this.techSkillParentBehaviourIds = parentCompetences && parentCompetences.map((item: any) => item.competenceId)
            .filter((value: any, index: number, self: any) => self.indexOf(value) === index);
          let tempBehaviour: any = {};
          for (let i = 0; i < this.techSkillParentBehaviourIds.length; i++) {
            for (let j = 0; j < data.response.competences.length; j++) {
              let behavior = data.response.competences[j];
              if (behavior.parentCompetence.competenceId == this.techSkillParentBehaviourIds[i]) {
                if (tempBehaviour[this.techSkillParentBehaviourIds[i]] && tempBehaviour[this.techSkillParentBehaviourIds[i]].length && !tempBehaviour[this.techSkillParentBehaviourIds[i]].includes(behavior)) {
                  tempBehaviour[this.techSkillParentBehaviourIds[i]].push(behavior);
                } else if (!tempBehaviour[this.techSkillParentBehaviourIds[i]] || !tempBehaviour[this.techSkillParentBehaviourIds[i]].length) {
                  tempBehaviour[this.techSkillParentBehaviourIds[i]] = [behavior];
                }
              }
            }
          }

          this.techSkillArray = [];
          let isPostCalibrationOrFinalStepCompleted = this.isPostCalibration || this.isFinalStepCompleted;

          for (let i = 0; i < this.techSkillParentBehaviourIds.length; i++) {
            let currentCompetenceRatingCounter = 0;
            let currentCompetenceRatingSum = 0;
            let currentCompetenceSelfRatingCounter = 0;
            let currentCompetenceSelfRatingSum = 0;

            let currentCompetenceRatingAvgNum = 0;
            let currentCompetenceSelfRatingAvgNum = 0;
            let currentCompetenceRatingAvgString = null;
            let currentCompetenceSelfRatingAvgString = null;
            let currentCompetenceRatingDoneCounter = 0;

            let parent = tempBehaviour[this.techSkillParentBehaviourIds[i]] && tempBehaviour[this.techSkillParentBehaviourIds[i]].length && tempBehaviour[this.techSkillParentBehaviourIds[i]][0].parentCompetence;
            let tempCompetence = [];

            if (isPostCalibrationOrFinalStepCompleted) {
              for (let j = 0; j < tempBehaviour[this.techSkillParentBehaviourIds[i]].length; j++) {
                const currentRating = tempBehaviour[this.techSkillParentBehaviourIds[i]][j].evaluationRating;
                const currentSelfRating = tempBehaviour[this.techSkillParentBehaviourIds[i]][j].colleagueEvaluationRating;

                let formattedTitle = tempBehaviour[this.techSkillParentBehaviourIds[i]][j].title || '';
                if (formattedTitle && formattedTitle.length && formattedTitle.includes('|')) {
                  formattedTitle = formattedTitle.split('|')[0];
                }

                tempCompetence.push({
                  ...tempBehaviour[this.techSkillParentBehaviourIds[i]][j],
                  title: formattedTitle,
                  rating: this.getBehaviourRating(currentRating, tempBehaviour[this.techSkillParentBehaviourIds[i]][j].evaluationScale.length),
                  selfRating: this.getBehaviourRating(currentSelfRating, tempBehaviour[this.techSkillParentBehaviourIds[i]][j].evaluationScale.length)
                });

                // Media finale
                ratingCounter++;
                selfRatingCounter++;
                ratingSum = ratingSum + (currentRating || 0);
                selfRatingSum = selfRatingSum + (currentSelfRating || 0);

                // Media parziale
                currentCompetenceRatingCounter++;
                currentCompetenceSelfRatingCounter++;
                currentCompetenceRatingSum = currentCompetenceRatingSum + (currentRating || 0);
                currentCompetenceSelfRatingSum = currentCompetenceSelfRatingSum + (currentSelfRating || 0);

                if (currentRating && currentRating > 0) {
                  currentCompetenceRatingDoneCounter++;
                  ratingDoneCounter++;
                }
              }
            } else {
              for (let j = 0; j < tempBehaviour[this.techSkillParentBehaviourIds[i]].length; j++) {
                const currentRating = tempBehaviour[this.techSkillParentBehaviourIds[i]][j].evaluationRating;

                let formattedTitle = tempBehaviour[this.techSkillParentBehaviourIds[i]][j].title || '';
                if (formattedTitle && formattedTitle.length && formattedTitle.includes('|')) {
                  formattedTitle = formattedTitle.split('|')[0];
                }

                tempCompetence.push({
                  ...tempBehaviour[this.techSkillParentBehaviourIds[i]][j],
                  title: formattedTitle,
                  rating: this.getBehaviourRating(currentRating, tempBehaviour[this.techSkillParentBehaviourIds[i]][j].evaluationScale.length)
                });

                // Media finale
                ratingCounter++;
                ratingSum = ratingSum + (currentRating || 0);

                // Media parziale
                currentCompetenceRatingCounter++;
                currentCompetenceRatingSum = currentCompetenceRatingSum + (currentRating || 0);

                if (currentRating && currentRating > 0) {
                  currentCompetenceRatingDoneCounter++;
                  ratingDoneCounter++;
                }
              }
            }

            // Alla fine, aggiungo la media parziale
            if (tempCompetence && tempCompetence.length) {
              currentCompetenceRatingAvgNum = +(currentCompetenceRatingSum / currentCompetenceRatingCounter).toFixed(2) || 0;
              currentCompetenceSelfRatingAvgNum = +(currentCompetenceSelfRatingSum / currentCompetenceSelfRatingCounter).toFixed(2) || 0;

              const currentCompetenceRatingEvalScale = this.techSkillArray && this.techSkillArray[0] && this.techSkillArray[0].evaluationScale && this.techSkillArray[0].evaluationScale.length || 5;

              currentCompetenceRatingAvgString = currentCompetenceRatingAvgNum.toString();
              if (currentCompetenceRatingAvgString && currentCompetenceRatingAvgString.indexOf(".") >= 0) {
                currentCompetenceRatingAvgString = currentCompetenceRatingAvgString.replace(".", ",");
              }

              currentCompetenceSelfRatingAvgString = currentCompetenceSelfRatingAvgNum.toString();
              if (currentCompetenceSelfRatingAvgString && currentCompetenceSelfRatingAvgString.indexOf(".") >= 0) {
                currentCompetenceSelfRatingAvgString = currentCompetenceSelfRatingAvgString.replace(".", ",");
              }

              tempCompetence.push({
                disabled: true,
                isPartialAverage: true,
                avgTitle: this.translate.instant("generic.PARTIL_AVG"),
                ratingScale: currentCompetenceRatingEvalScale || 5,
                ratingAvgNum: currentCompetenceRatingAvgNum,
                ratingStars: Math.round(currentCompetenceRatingAvgNum),
                selfRatingAvgNum: currentCompetenceSelfRatingAvgNum,
                selfRatingStars: Math.round(currentCompetenceSelfRatingAvgNum),
                ratingAvgString: currentCompetenceRatingAvgString,
                selfRatingAvgString: currentCompetenceSelfRatingAvgString,
                allCompetencesValuated: currentCompetenceRatingDoneCounter && currentCompetenceRatingDoneCounter === currentCompetenceRatingCounter
              });
            }

            if (parent.text && parent.text.length && parent.text.includes('|')) {
              parent.text = parent.text.split('|')[0];
            }
            this.techSkillArray.push({
              category: parent.text,
              competences: tempCompetence,
              ratingScale: tempCompetence[0].evaluationScale.length || 5
            });
          }

          // Media finale
          let ratingAvgNum;
          let selfRatingAvgNum;
          let ratingAvgString;
          let selfRatingAvgString;

          ratingAvgNum = +(ratingSum / ratingCounter).toFixed(2) || 0;
          selfRatingAvgNum = +(selfRatingSum / selfRatingCounter).toFixed(2) || 0;

          const ratingEvalScale = this.techSkillArray && this.techSkillArray[0] && this.techSkillArray[0].evaluationScale && this.techSkillArray[0].evaluationScale.length || 5;

          ratingAvgString = ratingAvgNum.toString();
          if (ratingAvgString && ratingAvgString.indexOf(".") >= 0) {
            ratingAvgString = ratingAvgString.replace(".", ",");
          }

          selfRatingAvgString = selfRatingAvgNum.toString();
          if (selfRatingAvgString && selfRatingAvgString.indexOf(".") >= 0) {
            selfRatingAvgString = selfRatingAvgString.replace(".", ",");
          }

          // Indica se tutti i comportamenti hanno avuto almeno una valutazione
          let allBehavioursValuated = ratingDoneCounter && ratingDoneCounter === ratingCounter;

          this.techSkillsFinalAvg = {
            allBehavioursValuated: allBehavioursValuated,
            title: this.translate.instant('generic.FINAL_AVG'),
            subtitle: this.translate.instant('generic.TECH_COMPETENCES'),
            ratingAvgNum: ratingAvgNum,
            ratingStars: Math.round(ratingAvgNum),
            selfRatingAvgNum: selfRatingAvgNum,
            selfRatingStars: Math.round(selfRatingAvgNum),
            ratingAvgString: ratingAvgString,
            selfRatingAvgString: selfRatingAvgString,
            ratingScale: ratingEvalScale,
            isPostCalibrationOrFinalStepCompleted: isPostCalibrationOrFinalStepCompleted
          }

          this.techSkillComment = data.response.overallComment || '';
          if (this.isFinalStepCompleted && !this.techSkillComment) {
            //  this.techSkillComment = this.translations['generic.NO_COMMENT_INSERTED']
          }

          if (this.techSkillComment) {
            this.techSkillCommentCopy = JSON.parse(JSON.stringify(this.techSkillComment));
          }

          this.techSkillArrayCopy = JSON.parse(JSON.stringify(this.techSkillArray));
          if (this.techSkills) {
            this.techSkillsCopy = JSON.parse(JSON.stringify(this.techSkills));
          }

          this.isLoadingTechSkills = false;
          this.hasToOpenTutorial();


          // Calcolo le medie parziali di ogni comportamento
          /*
          let getCalculatedStarsCountByAvgPromise = this.getCalculatedStarsCountByAvg(this.techSkillArray);
          getCalculatedStarsCountByAvgPromise.then(() => {
            if (isPostCalibrationOrFinalStepCompleted) {
              // Calcolo le medie parziali del self assessment
              let getCalculatedStarsCountByAvgSelfPromise = this.getCalculatedStarsCountByAvg(this.techSkillArray, true);
              getCalculatedStarsCountByAvgSelfPromise.then(() => {
                this.techSkillArrayCopy = JSON.parse(JSON.stringify(this.techSkillArray));

                if (this.techSkills) {
                  this.techSkillsCopy = JSON.parse(JSON.stringify(this.techSkills));
                }

                // Se tutti i comportamentis sono stati valorizzati, calcolo la media finale
                if (allBehavioursValuated) {
                  let getFinalAvgStarRatingPromise = this.getFinalAvgStarRating(this.techSkillsFinalAvg);
                  getFinalAvgStarRatingPromise.then(() => {
                    // Calcolo la media finale del self assessment
                    let getFinalAvgStarRatingSelfPromise = this.getFinalAvgStarRating(this.techSkillsFinalAvg, true);
                    getFinalAvgStarRatingSelfPromise.then(() => {
                      this.isLoadingTechSkills = false;
                      this.hasToOpenTutorial();
                    });
                  });
                } else {

                  this.isLoadingTechSkills = false;
                  this.hasToOpenTutorial();
                }
              });
            } else {
              // Se tutti i comportamentis sono stati valorizzati, calcolo la media finale
              if (allBehavioursValuated) {
                let getFinalAvgStarRatingPromise = this.getFinalAvgStarRating(this.techSkillsFinalAvg);
                getFinalAvgStarRatingPromise.then(() => {
                  this.techSkillArrayCopy = JSON.parse(JSON.stringify(this.techSkillArray));
                  if (this.techSkills) {
                    this.techSkillsCopy = JSON.parse(JSON.stringify(this.techSkills));
                  }

                  this.isLoadingTechSkills = false;
                  this.hasToOpenTutorial();
                });
              } else {
                this.techSkillArrayCopy = JSON.parse(JSON.stringify(this.techSkillArray));
                if (this.techSkills) {
                  this.techSkillsCopy = JSON.parse(JSON.stringify(this.techSkills));
                }

                this.isLoadingTechSkills = false;
                this.hasToOpenTutorial();
              }
            }
          });

          */
        }
      }, (err?: any) => {
        const messageObj: ApplicationModalMessage = {
          modalId: "pdm010",
          text: this.translate.instant("errors." + err?.message),
          title: this.translate.instant("generic.WARNING")
        }
        this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        this.isLoadingTechSkills = false;
      });
  }

  onTechSkillCommentChanged(text: string) {
    this.techSkillComment = text
  }

  // Aggiornamento valutazione comportamento
  techSkillRatingChanged(competence: any) {
    let competenceEvaluation: { competenceId: string, evaluationScore: number };
    let score = 0;
    for (let i = 0; i < competence.rating.length; i++) {
      if (competence.rating[i].isClicked) {
        score = competence.rating[i].id + 1;
      }
    }
    competenceEvaluation = { competenceId: competence.competenceId, evaluationScore: score };
    let comment;
    if (this.techSkillComment != this.techSkillCommentCopy) {
      comment = this.techSkillComment;
    }

    this.peopleAppraisalService.createOrUpdatePerformanceAssessmentCompetenceEvaluationForManager(this.userId, [competenceEvaluation], comment)
      .subscribe((data: SenecaResponse<boolean>) => {
        if (data && data.error) {
          const messageObj: ApplicationModalMessage = {
            modalId: "pdm017",
            text: this.translate.instant("errors." + data.error),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        } else {
          this.updateUserPhaseStatus();
          this.showSaveSuccessfulToaster();
          this.getUserProfileTab(true);
        }
      }, (err?: any) => {
        const messageObj: ApplicationModalMessage = {
          modalId: "pdm018",
          text: this.translate.instant("errors." + err?.message),
          title: this.translate.instant("generic.WARNING")
        }
        this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        this.isLoadingBehaviours = false;
      });
  }

  showSaveSuccessfulToaster() {
    let x: any = document.getElementById("snackbarPersonDetails");
    if (x) {
      x.className = "show";
      setTimeout(() => { x.className = x.className.replace("show", ""); }, 2000);
    }
  }

  checkPassStructureTutorial() {
    const forcePasStructureTutorial = sessionStorage.getItem('openPasStructureTutorial');
    if (forcePasStructureTutorial) {
      this.forcePasTutorialStep = this.stepList[0];
      this.openPasStructureTutorial();
    }
  }

  checkPersonalObjectiveEvaluationTutorial() {
    const forcePasStructureTutorial = sessionStorage.getItem('openPersonalObjectiveEvaluationTutorial');
    if (forcePasStructureTutorial) {
      if (!this.currentStep.id && this.stepList && this.stepList[0]) {
        this.forcePasTutorialStep = this.stepList[0];
        this.onStepClicked(this.stepList[0], true);
      } else {
        this.openPersonalObjectiveEvaluationTutorial();
      }
    }
  }

  // Apre il tutorial della valutazione obiettivi di sviluppo personale
  openPersonalObjectiveEvaluationTutorial() {
    this.translate.get(
      [
        'tutorials.personObjectiveEvaluation.1.TITLE',
        'tutorials.personObjectiveEvaluation.1.DESC',
        'tutorials.personObjectiveEvaluation.2.TITLE',
        'tutorials.personObjectiveEvaluation.2.DESC',
        'tutorials.personObjectiveEvaluation.3.TITLE',
        'tutorials.personObjectiveEvaluation.3.DESC',
        'tutorials.personObjectiveEvaluation.4.TITLE',
        'tutorials.personObjectiveEvaluation.4.DESC'
      ])
      .subscribe(translations => {
        this.steps = [{
          /** Selettore html */
          selector: '#firstStepPageHeader',
          /** Titolo */
          title: translations['tutorials.personObjectiveEvaluation.1.TITLE'],
          /** Tour step text */
          content: translations['tutorials.personObjectiveEvaluation.1.DESC'],
          orientation: Orientation.Bottom,
          // /** Action that happens when the step is opened */
          // action?: () => void;
          // /** Action that happens when the step is closed */
          closeAction: () => {
            sessionStorage.removeItem('openPersonalObjectiveEvaluationTutorial');
          },
          // /** Skips this step, this is so you do not have create multiple tour configurations based on user settings/permissions */
          // skipStep?: boolean;
          // /** Adds some padding for things like sticky headers when scrolling to an element */
          // scrollAdjustment?: number;
          // /** Adds default padding around tour highlighting. Does not need to be true for highlightPadding to work */
          // useHighlightPadding?: boolean;
          // /** Adds padding around tour highlighting in pixels, this overwrites the default for this step. Is not dependent on useHighlightPadding being true */
          // highlightPadding?: number;
          highlightPadding: 15
        }];

        if (this.toEvaluateList && this.toEvaluateList.length) {
          this.steps.push({
            /** Selettore html */
            selector: '#toEvaluateItemIndex0-title',
            /** Titolo */
            title: translations['tutorials.personObjectiveEvaluation.2.TITLE'],
            /** Tour step text */
            content: translations['tutorials.personObjectiveEvaluation.2.DESC'],
            orientation: Orientation.Bottom,
            highlightPadding: 15
          }, {
            /** Selettore html */
            selector: '#toEvaluateItemIndex0-description',
            /** Titolo */
            title: translations['tutorials.personObjectiveEvaluation.3.TITLE'],
            /** Tour step text */
            content: translations['tutorials.personObjectiveEvaluation.3.DESC'],
            orientation: Orientation.Bottom,
            highlightPadding: 15
          }, {
            /** Selettore html */
            selector: '#saveGoalToEvaluateBtn',
            /** Titolo */
            title: translations['tutorials.personObjectiveEvaluation.4.TITLE'],
            /** Tour step text */
            content: translations['tutorials.personObjectiveEvaluation.4.DESC'],
            orientation: Orientation.Top,
            highlightPadding: 15
          }
          )
        }

        const intro = {
          /** Identifier for tour */
          tourId: 'PERSONAL OBJECTIVE EVALUATION TOUR',
          /** Use orb to start tour */
          useOrb: false,
          /** Steps for the tour */
          steps: this.steps,
          /** Function will be called when tour is skipped */
          skipCallback: (stepSkippedOn: number) => {
            sessionStorage.removeItem('openPersonalObjectiveEvaluationTutorial');
          }
          // /** Function will be called when tour is completed */
          // completeCallback?: () => void;
          // /** Minimum size of screen in pixels before the tour is run, if the tour is resized below this value the user will be told to resize */
          // minimumScreenSize?: number;
          // /** Dialog shown if the window width is smaller than the defined minimum screen size. */
          // resizeDialog?: {
          //   /** Resize dialog title text */
          //   title?: string;
          //   /** Resize dialog text */
          //   content: string;
          // }
        }
        // aggiunto il timeout altrimenti partiva prima che terminasse il loader dei dati
        setTimeout(() => this.guidedTour.startTour(intro), 500);
      });
  }

  checkOrganizationalBehaviorsTutorial() {
    const forcePasStructureTutorial = sessionStorage.getItem('openOrganizationalBehaviorsTutorial');
    if (forcePasStructureTutorial) {
      // Prima devo entrare nel secondo step
      if (this.currentStep.stepId !== 2 && this.stepList && this.stepList[1]) {
        this.forcePasTutorialStep = this.stepList[1];
        this.onStepClicked(this.stepList[1], forcePasStructureTutorial === "true");
      } else {
        this.openOrganizationalBehaviorsTutorial();
      }
    }
  }

  // Apre il tutorial della valutazione obiettivi di sviluppo personale
  openOrganizationalBehaviorsTutorial() {
    this.translate.get(
      [
        'tutorials.organizationalBehaviors.1.TITLE',
        'tutorials.organizationalBehaviors.1.DESC',
        'tutorials.organizationalBehaviors.2.TITLE',
        'tutorials.organizationalBehaviors.2.DESC',
        'tutorials.organizationalBehaviors.3.TITLE',
        'tutorials.organizationalBehaviors.3.DESC',
        'tutorials.organizationalBehaviors.4.TITLE',
        'tutorials.organizationalBehaviors.4.DESC'
      ])
      .subscribe(translations => {
        this.steps = [{
          /** Selettore html */
          selector: '#secondStepPageHeader',
          /** Titolo */
          title: translations['tutorials.organizationalBehaviors.1.TITLE'],
          /** Tour step text */
          content: translations['tutorials.organizationalBehaviors.1.DESC'],
          orientation: Orientation.Bottom,
          // /** Action that happens when the step is opened */
          // action?: () => void;
          // /** Action that happens when the step is closed */
          closeAction: () => {
            sessionStorage.removeItem('openOrganizationalBehaviorsTutorial');
          },
          // /** Skips this step, this is so you do not have create multiple tour configurations based on user settings/permissions */
          // skipStep?: boolean;
          // /** Adds some padding for things like sticky headers when scrolling to an element */
          // scrollAdjustment?: number;
          // /** Adds default padding around tour highlighting. Does not need to be true for highlightPadding to work */
          // useHighlightPadding?: boolean;
          // /** Adds padding around tour highlighting in pixels, this overwrites the default for this step. Is not dependent on useHighlightPadding being true */
          // highlightPadding?: number;
          highlightPadding: 15
        }];

        if (this.competenceArray && this.competenceArray.length) {
          this.steps.push({
            /** Selettore html */
            selector: '#competenceIndex0-container',
            /** Titolo */
            title: translations['tutorials.organizationalBehaviors.2.TITLE'],
            /** Tour step text */
            content: translations['tutorials.organizationalBehaviors.2.DESC'],
            orientation: Orientation.Bottom,
            highlightPadding: 15
          }, {
            /** Selettore html */
            selector: '#competenceIndex0-stars',
            /** Titolo */
            title: translations['tutorials.organizationalBehaviors.3.TITLE'],
            /** Tour step text */
            content: translations['tutorials.organizationalBehaviors.3.DESC'],
            orientation: Orientation.Bottom,
            highlightPadding: 15
          }, {
            /** Selettore html */
            selector: '#competenceEvaluationNotes',
            /** Titolo */
            title: translations['tutorials.organizationalBehaviors.4.TITLE'],
            /** Tour step text */
            content: translations['tutorials.organizationalBehaviors.4.DESC'],
            orientation: Orientation.Top,
            highlightPadding: 15
          }
          )
        }

        const intro = {
          /** Identifier for tour */
          tourId: 'ORGANIZATIONAL BEHAVIOURS TOUR',
          /** Use orb to start tour */
          useOrb: false,
          /** Steps for the tour */
          steps: this.steps,
          /** Function will be called when tour is skipped */
          skipCallback: (stepSkippedOn: number) => {
            sessionStorage.removeItem('openOrganizationalBehaviorsTutorial');
          }
          // /** Function will be called when tour is completed */
          // completeCallback?: () => void;
          // /** Minimum size of screen in pixels before the tour is run, if the tour is resized below this value the user will be told to resize */
          // minimumScreenSize?: number;
          // /** Dialog shown if the window width is smaller than the defined minimum screen size. */
          // resizeDialog?: {
          //   /** Resize dialog title text */
          //   title?: string;
          //   /** Resize dialog text */
          //   content: string;
          // }
        }
        this.guidedTour.startTour(intro);
      });
  }

  checkTechSkillEvaluationTutorial() {
    const forcePasStructureTutorial = sessionStorage.getItem('openTechSkillEvaluationTutorial');
    if (forcePasStructureTutorial) {
      // Prima devo entrare nel secondo step
      if (this.currentStep.stepId !== 3 && this.stepList && this.stepList[2]) {
        this.forcePasTutorialStep = this.stepList[2];
        this.onStepClicked(this.stepList[2], forcePasStructureTutorial === "true");
      } else {
        this.openTechSkillEvaluationTutorial();
      }
    }
  }

  checkSetGoalsTutorial() {
    const forcePasStructureTutorial = sessionStorage.getItem('openSetGoalsTutorial');
    if (forcePasStructureTutorial) {
      // Prima devo entrare nel secondo step
      if (this.currentStep.stepId !== 4 && this.stepList && (this.stepList.length > 4 ? this.stepList[3] : this.stepList[2])) {
        if (this.stepList.length > 4) {
          this.forcePasTutorialStep = this.stepList[3];
        } else {
          this.forcePasTutorialStep = this.stepList[2];
        }
        this.onStepClicked(this.forcePasTutorialStep, forcePasStructureTutorial === "true");
      } else {
        this.openSetGoalsTutorial();
      }
    }
  }

  // Apre il tutorial delle competenze tecniche
  openSetGoalsTutorial() {
    this.translate.get(
      [
        'tutorials.setGoals.1.TITLE',
        'tutorials.setGoals.1.DESC',
        'tutorials.setGoals.2.TITLE',
        'tutorials.setGoals.2.DESC',
        'tutorials.setGoals.3.TITLE',
        'tutorials.setGoals.3.DESC',
        'tutorials.setGoals.4.TITLE',
        'tutorials.setGoals.4.DESC'
      ])
      .subscribe(translations => {
        this.steps = [{
          /** Selettore html */
          selector: '#fourthStepPageHeader',
          /** Titolo */
          title: translations['tutorials.setGoals.1.TITLE'],
          /** Tour step text */
          content: translations['tutorials.setGoals.1.DESC'],
          orientation: Orientation.Bottom,
          // /** Action that happens when the step is opened */
          // action?: () => void;
          // /** Action that happens when the step is closed */
          closeAction: () => {
            sessionStorage.removeItem('openSetGoalsTutorial');
          },
          // /** Skips this step, this is so you do not have create multiple tour configurations based on user settings/permissions */
          // skipStep?: boolean;
          // /** Adds some padding for things like sticky headers when scrolling to an element */
          // scrollAdjustment?: number;
          // /** Adds default padding around tour highlighting. Does not need to be true for highlightPadding to work */
          // useHighlightPadding?: boolean;
          // /** Adds padding around tour highlighting in pixels, this overwrites the default for this step. Is not dependent on useHighlightPadding being true */
          // highlightPadding?: number;
          highlightPadding: 15
        }, {
          /** Selettore html */
          selector: '#addGoalBtn',
          /** Titolo */
          title: translations['tutorials.setGoals.2.TITLE'],
          /** Tour step text */
          content: translations['tutorials.setGoals.2.DESC'],
          orientation: Orientation.Top,
          highlightPadding: 15
        }, {
          /** Selettore html */
          selector: '#saveGoalBtn',
          /** Titolo */
          title: translations['tutorials.setGoals.3.TITLE'],
          /** Tour step text */
          content: translations['tutorials.setGoals.3.DESC'],
          orientation: Orientation.Top,
          highlightPadding: 15
        }];

        const intro = {
          /** Identifier for tour */
          tourId: 'SET GOALS TOUR',
          /** Use orb to start tour */
          useOrb: false,
          /** Steps for the tour */
          steps: this.steps,
          /** Function will be called when tour is skipped */
          skipCallback: (stepSkippedOn: number) => {
            sessionStorage.removeItem('openSetGoalsTutorial');
          }
          // /** Function will be called when tour is completed */
          // completeCallback?: () => void;
          // /** Minimum size of screen in pixels before the tour is run, if the tour is resized below this value the user will be told to resize */
          // minimumScreenSize?: number;
          // /** Dialog shown if the window width is smaller than the defined minimum screen size. */
          // resizeDialog?: {
          //   /** Resize dialog title text */
          //   title?: string;
          //   /** Resize dialog text */
          //   content: string;
          // }
        }
        this.guidedTour.startTour(intro);
      });
  }

  // Apre il tutorial delle competenze tecniche
  openTechSkillEvaluationTutorial() {
    this.translate.get(
      [
        'tutorials.techSkillEvaluation.1.TITLE',
        'tutorials.techSkillEvaluation.1.DESC',
        'tutorials.techSkillEvaluation.2.TITLE',
        'tutorials.techSkillEvaluation.2.DESC',
        'tutorials.techSkillEvaluation.3.TITLE',
        'tutorials.techSkillEvaluation.3.DESC',
        'tutorials.techSkillEvaluation.4.TITLE',
        'tutorials.techSkillEvaluation.4.DESC',
        'tutorials.otsEvaluation.1.TITLE',
        'tutorials.otsEvaluation.1.DESC',
        'tutorials.otsEvaluation.2.TITLE',
        'tutorials.otsEvaluation.2.DESC',
        'tutorials.otsEvaluation.3.TITLE',
        'tutorials.otsEvaluation.3.DESC',
        'tutorials.otsEvaluation.4.TITLE',
        'tutorials.otsEvaluation.4.DESC',
      ])
      .subscribe(translations => {
        this.steps = [{
          /** Selettore html */
          selector: '#thirdStepPageHeader',
          /** Titolo */
          title: this.yearsWithSpecialisticGoalsToEvaluate ? translations['tutorials.otsEvaluation.1.TITLE'] : translations['tutorials.techSkillEvaluation.1.TITLE'],
          /** Tour step text */
          content: this.yearsWithSpecialisticGoalsToEvaluate ? translations['tutorials.otsEvaluation.1.DESC'] : translations['tutorials.techSkillEvaluation.1.DESC'],
          orientation: Orientation.Bottom,
          // /** Action that happens when the step is opened */
          // action?: () => void;
          // /** Action that happens when the step is closed */
          closeAction: () => {
            sessionStorage.removeItem('openTechSkillEvaluationTutorial');
          },
          // /** Skips this step, this is so you do not have create multiple tour configurations based on user settings/permissions */
          // skipStep?: boolean;
          // /** Adds some padding for things like sticky headers when scrolling to an element */
          // scrollAdjustment?: number;
          // /** Adds default padding around tour highlighting. Does not need to be true for highlightPadding to work */
          // useHighlightPadding?: boolean;
          // /** Adds padding around tour highlighting in pixels, this overwrites the default for this step. Is not dependent on useHighlightPadding being true */
          // highlightPadding?: number;
          highlightPadding: 15
        }];

        if (this.techSkillArray && this.techSkillArray.length) {
          this.steps.push({
            /** Selettore html */
            selector: '#competenceIndex0-container',
            /** Titolo */
            title: this.yearsWithSpecialisticGoalsToEvaluate ? translations['tutorials.otsEvaluation.2.TITLE'] : translations['tutorials.techSkillEvaluation.2.TITLE'],
            /** Tour step text */
            content: this.yearsWithSpecialisticGoalsToEvaluate ? translations['tutorials.otsEvaluation.2.DESC'] : translations['tutorials.techSkillEvaluation.2.DESC'],
            orientation: Orientation.Bottom,
            highlightPadding: 15
          }, {
            /** Selettore html */
            selector: '#competenceIndex0-stars',
            /** Titolo */
            title: this.yearsWithSpecialisticGoalsToEvaluate ? translations['tutorials.otsEvaluation.3.TITLE'] : translations['tutorials.techSkillEvaluation.3.TITLE'],
            /** Tour step text */
            content: this.yearsWithSpecialisticGoalsToEvaluate ? translations['tutorials.otsEvaluation.3.DESC'] : translations['tutorials.techSkillEvaluation.3.DESC'],
            orientation: Orientation.Bottom,
            highlightPadding: 15
          }, {
            /** Selettore html */
            selector: '#techEvaluationNotes',
            /** Titolo */
            title: this.yearsWithSpecialisticGoalsToEvaluate ? translations['tutorials.otsEvaluation.4.TITLE'] : translations['tutorials.techSkillEvaluation.4.TITLE'],
            /** Tour step text */
            content: this.yearsWithSpecialisticGoalsToEvaluate ? translations['tutorials.otsEvaluation.4.DESC'] : translations['tutorials.techSkillEvaluation.4.DESC'],
            orientation: Orientation.Top,
            highlightPadding: 15
          }
          )
        }

        const intro = {
          /** Identifier for tour */
          tourId: 'TECH SKILL EVALUATION TOUR',
          /** Use orb to start tour */
          useOrb: false,
          /** Steps for the tour */
          steps: this.steps,
          /** Function will be called when tour is skipped */
          skipCallback: (stepSkippedOn: number) => {
            sessionStorage.removeItem('openTechSkillEvaluationTutorial');
          }
          // /** Function will be called when tour is completed */
          // completeCallback?: () => void;
          // /** Minimum size of screen in pixels before the tour is run, if the tour is resized below this value the user will be told to resize */
          // minimumScreenSize?: number;
          // /** Dialog shown if the window width is smaller than the defined minimum screen size. */
          // resizeDialog?: {
          //   /** Resize dialog title text */
          //   title?: string;
          //   /** Resize dialog text */
          //   content: string;
          // }
        }
        this.guidedTour.startTour(intro);
      });
  }

  // Apre il tutorial della struttura PAS
  openPasStructureTutorial() {
    this.translate.get(
      [
        'tutorials.pasStructure.1.TITLE',
        'tutorials.pasStructure.1.DESC',
        'tutorials.pasStructure.2.TITLE',
        'tutorials.pasStructure.2.DESC',
        'tutorials.pasStructure.3.TITLE',
        'tutorials.pasStructure.3.DESC',
        'tutorials.pasStructure.4.TITLE',
        'tutorials.pasStructure.4.DESC',
        'tutorials.pasStructure.5.TITLE',
        'tutorials.pasStructure.5.DESC'
      ])
      .subscribe(translations => {
        this.steps = [{
          /** Selettore html */
          selector: '#userAvatar',
          /** Titolo */
          title: translations['tutorials.pasStructure.1.TITLE'],
          /** Tour step text */
          content: translations['tutorials.pasStructure.1.DESC'],
          orientation: Orientation.Bottom,
          // /** Action that happens when the step is opened */
          // action?: () => void;
          // /** Action that happens when the step is closed */
          closeAction: () => {
            sessionStorage.removeItem('openPasStructureTutorial');
          },
          // /** Skips this step, this is so you do not have create multiple tour configurations based on user settings/permissions */
          // skipStep?: boolean;
          // /** Adds some padding for things like sticky headers when scrolling to an element */
          // scrollAdjustment?: number;
          // /** Adds default padding around tour highlighting. Does not need to be true for highlightPadding to work */
          // useHighlightPadding?: boolean;
          // /** Adds padding around tour highlighting in pixels, this overwrites the default for this step. Is not dependent on useHighlightPadding being true */
          // highlightPadding?: number;
          highlightPadding: 15
        }, {
          /** Selettore html */
          selector: '#takeNoteBtn',
          /** Titolo */
          title: translations['tutorials.pasStructure.2.TITLE'],
          /** Tour step text */
          content: translations['tutorials.pasStructure.2.DESC'],
          orientation: Orientation.Bottom,
          highlightPadding: 15
        }, {
          /** Selettore html */
          selector: '#pas',
          /** Titolo */
          title: translations['tutorials.pasStructure.3.TITLE'],
          /** Tour step text */
          content: translations['tutorials.pasStructure.3.DESC'],
          orientation: Orientation.Bottom,
          highlightPadding: 15
        }, {
          /** Selettore html */
          selector: '#notes',
          /** Titolo */
          title: translations['tutorials.pasStructure.4.TITLE'],
          /** Tour step text */
          content: translations['tutorials.pasStructure.4.DESC'],
          orientation: Orientation.Bottom,
          highlightPadding: 15
        }, {
          /** Selettore html */
          selector: '#stepper',
          /** Titolo */
          title: translations['tutorials.pasStructure.5.TITLE'],
          /** Tour step text */
          content: translations['tutorials.pasStructure.5.DESC'],
          orientation: Orientation.Top,
          highlightPadding: 25
        }];

        const intro = {
          /** Identifier for tour */
          tourId: 'PAS STRUCTURE TOUR',
          /** Use orb to start tour */
          useOrb: false,
          /** Steps for the tour */
          steps: this.steps,
          /** Function will be called when tour is skipped */
          skipCallback: (stepSkippedOn: number) => {
            sessionStorage.removeItem('openPasStructureTutorial');
          }
          // /** Function will be called when tour is completed */
          // completeCallback?: () => void;
          // /** Minimum size of screen in pixels before the tour is run, if the tour is resized below this value the user will be told to resize */
          // minimumScreenSize?: number;
          // /** Dialog shown if the window width is smaller than the defined minimum screen size. */
          // resizeDialog?: {
          //   /** Resize dialog title text */
          //   title?: string;
          //   /** Resize dialog text */
          //   content: string;
          // }
        }
        this.guidedTour.startTour(intro);
      });
  }


  anyEmptyEvaluation() {
    if (this.finalEvaluation.evaluatedData && this.finalEvaluation.evaluatedData.length) {
      for (let i = 0; i < this.finalEvaluation.evaluatedData.length; i++) {
        if (!this.finalEvaluation.evaluatedData[i].evaluation || !this.finalEvaluation.evaluatedData[i].evaluation.length) {
          return true;
        }
      }
      return false;
    }
    return true;
  }

  getFinalEvaluationData() {
    if (!this.selectedYear) {
      console.log("No selected year");
      return;
    }

    this.isLoadingFinalEvaluationData = true;

    if (this.getFinalEvaluationData$) {
      this.getFinalEvaluationData$.unsubscribe();
    }

    this.getFinalEvaluationData$ = this.peopleAppraisalService.getPerformanceAssessmentFinalEvaluationDataForManager(this.selectedYear.perfYear, this.selectedYear.perfYearCode, [this.userId])
      .subscribe((data: SenecaResponse<any>) => {
        if (data && data.error) {
          const messageObj: ApplicationModalMessage = {
            modalId: "pdm005",
            text: this.translate.instant("errors." + data.error),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          this.isLoadingToEvaluateGoals = false;
        }
        // In alcuni casi potrei aver effettuato la chiamata per un assessment che non esiste (nel caso il processo sia fake per la fase di goal update)
        if (data?.response?.perfAssessmentExists) {
          this.insertByUser = this.personDetails.forename;
          this.insertByManager = data.response.evaluationManager.forename;
          this.finalEvaluation = {};
          this.finalEvaluation.evaluatedData = [];
          // valutazione obiettivi
          if (data.response.evaluatedManagerUserGoals && data.response.evaluatedManagerUserGoals.length) {
            const evaluated = data.response.evaluatedManagerUserGoals;
            const subordinateEvaluated = data.response.evaluatedSubordinateUserGoals;
            for (let i = 0; i < evaluated.length; i++) {
              let subordinateEvaluation = null;
              if (subordinateEvaluated && subordinateEvaluated.length) {
                const subordinateObjective = subordinateEvaluated.find((goal: any) => goal.goalId == evaluated[i].goalId);
                subordinateEvaluation = subordinateObjective.evaluation || this.translations['generic.NO_COMMENT_INSERTED'];
              }
              this.finalEvaluation.evaluatedData.push({
                ...evaluated[i],
                selfEvaluation: subordinateEvaluation,
                isSelected: false
              })
            }
            if (this.finalEvaluation.evaluatedData && this.finalEvaluation.evaluatedData.length) {
              console.log(this.finalEvaluation.evaluatedData)
              this.selectedObjective = this.finalEvaluation.evaluatedData[0];
            }
          }
          this.finalEvaluation.emptyEvaluatedObjective = this.anyEmptyEvaluation();

          // valutazione comporamenti
          this.finalEvaluation.behaviorData = {};
          if (data.response.managerBehaviorEvaluation) {
            this.finalEvaluation.behaviorData.comment = data.response.managerBehaviorEvaluation.overallComment;
            this.finalEvaluation.behaviorData.ratingValue = RoundAverage.getAverage(data.response.managerBehaviorEvaluation.averageBehaviorManagerEvaluation);
            // this.finalEvaluation.behaviorData.rating = data.response.managerBehaviorEvaluation.stars;
            this.finalEvaluation.behaviorData.rating = Math.round(this.finalEvaluation.behaviorData.ratingValue);
          }

          if (this.finalEvaluation.behaviorData.comment) {
            this.finalEvaluationBehaviourCommentCopy = JSON.parse(JSON.stringify(this.finalEvaluation.behaviorData.comment));
          }

          if (data.response.subordinateBehaviorEvaluation) {
            this.finalEvaluation.behaviorData.selfComment = data.response.subordinateBehaviorEvaluation.overallComment;
            this.finalEvaluation.behaviorData.selfRatingValue = RoundAverage.getAverage(data.response.subordinateBehaviorEvaluation.averageBehaviorManagerEvaluation);
            // this.finalEvaluation.behaviorData.selfRating = data.response.subordinateBehaviorEvaluation.stars;
            this.finalEvaluation.behaviorData.selfRating = Math.round(this.finalEvaluation.behaviorData.selfRatingValue);
          }
          // valutazione competenze
          this.finalEvaluation.competenceData = {};
          if (data.response.managerCompetenceEvaluation) {
            this.finalEvaluation.competenceData.comment = data.response.managerCompetenceEvaluation.overallComment;
            this.finalEvaluation.competenceData.ratingValue = RoundAverage.getAverage(data.response.managerCompetenceEvaluation.averageCompetenceManagerEvaluation);
            // this.finalEvaluation.competenceData.rating = data.response.managerCompetenceEvaluation.stars;
            this.finalEvaluation.competenceData.rating = Math.round(this.finalEvaluation.competenceData.ratingValue);
          }
          if (this.finalEvaluation.competenceData.comment) {
            this.finalEvaluationCompetenceCommentCopy = JSON.parse(JSON.stringify(this.finalEvaluation.competenceData.comment));
          }
          if (data.response.subordinateCompetenceEvaluation) {
            this.finalEvaluation.competenceData.selfComment = data.response.subordinateCompetenceEvaluation.overallComment;
            this.finalEvaluation.competenceData.selfRatingValue = RoundAverage.getAverage(data.response.subordinateCompetenceEvaluation.averageCompetenceManagerEvaluation);
            // this.finalEvaluation.competenceData.selfRating = data.response.subordinateCompetenceEvaluation.stars;
            this.finalEvaluation.competenceData.selfRating = Math.round(this.finalEvaluation.competenceData.selfRatingValue);
          }
          // Commento finale
          this.finalEvaluation.comment = data.response.finalEvaluationManagerComment || '';
          if (this.isPostCalibration || this.isFinalStepCompleted) {
            this.finalEvaluation.selfComment = data.response.finalEvaluationSubordinateComment || this.translations['generic.NO_COMMENT_INSERTED'];
            this.finalEvaluation.adminComment = data.response.finalEvaluationAdminComment;
          }

          if ((this.isScheduleShared || this.isFinalStepCompleted) && !this.finalEvaluation.comment) {
            this.finalEvaluation.comment = this.translations['generic.NO_COMMENT_INSERTED'];
          }
          if ((this.isScheduleShared || this.isFinalStepCompleted) && !this.finalEvaluation.comment) {
            this.finalEvaluation.selfComment = this.translations['generic.NO_COMMENT_INSERTED'];
          }

          if (this.finalEvaluation.comment) {
            this.finalEvaluationCommentCopy = JSON.parse(JSON.stringify(this.finalEvaluation.comment));
          }

          // definizione obiettivi
          this.finalEvaluation.definedObjectives = [];
          if (data.response.definedManagerUserGoals && data.response.definedManagerUserGoals.length) {
            let defined = data.response.definedManagerUserGoals;
            for (let m = 0, resLength = defined.length; m < resLength; m++) {
              const currentRes = defined[m];
              this.finalEvaluation.definedObjectives.push({
                id: currentRes.goalId,
                title: currentRes.text,
                cloned: currentRes.cloned,
                forename: this.insertByManager
              });
            }
          }
          // obiettivi definiti dal collaboratore
          if (!this.isFinalStepCompleted) {
            this.finalEvaluation.selfDefinedObjectives = [];
            if (data.response.definedSubordinateUserGoals && data.response.definedSubordinateUserGoals.length) {
              let defined = data.response.definedSubordinateUserGoals;
              for (let m = 0, resLength = defined.length; m < resLength; m++) {
                const currentRes = defined[m];
                this.finalEvaluation.selfDefinedObjectives.push({
                  id: currentRes.goalId,
                  title: currentRes.text,
                  goalToBeApproved: currentRes.goalToBeApproved,
                  forname: this.insertByUser
                });
              }
            }
          }

          // Se il processo del semestre è stato completato raggruppo tutti gli obiettivi definiti
          if (this.isFinalStepCompleted) {
            if (data.response.definedSubordinateUserGoals && data.response.definedSubordinateUserGoals.length) {
              let defined = data.response.definedSubordinateUserGoals;
              for (let m = 0, resLength = defined.length; m < resLength; m++) {
                const currentRes = defined[m];
                this.finalEvaluation.definedObjectives.push({
                  id: currentRes.goalId,
                  title: currentRes.text,
                  cloned: currentRes.cloned,
                  forename: this.insertByUser
                });
              }
            }
          }

          // Pas performer
          this.finalEvaluation.pasPerformer = {};
          if (data.response.pasAvgEvaluation) {
            this.finalEvaluation.pasPerformer.value = RoundAverage.getAverage(data.response.pasAvgEvaluation);
          } else {
            this.finalEvaluation.pasPerformer.value = 0;
          }

          if (this.finalEvaluation.evaluatedData) {
            this.finalEvaluationDataCopy = JSON.parse(JSON.stringify(this.finalEvaluation.evaluatedData));
          }

          if (this.finalEvaluation.definedObjectives) {
            this.finalDefinedObjectivesCopy = JSON.parse(JSON.stringify(this.finalEvaluation.definedObjectives));
          }

          if (this.finalEvaluation.selfDefinedObjectives) {
            this.finalSelfDefinedObjectivesCopy = JSON.parse(JSON.stringify(this.finalEvaluation.selfDefinedObjectives));
          }
        }
        this.isLoadingFinalEvaluationData = false;
      }, (err?: any) => {
        const messageObj: ApplicationModalMessage = {
          modalId: "pdm006",
          text: this.translate.instant("errors." + err?.message),
          title: this.translate.instant("generic.WARNING")
        }
        this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        this.isLoadingToEvaluateGoals = false;
      });
  }

  onFinalEvaluationCommentChanged(text: string) {
    this.finalEvaluation.comment = text
  }


  sendFinalEvaluation() {
    this.isLoadingFinalEvaluationData = true;
    this.peopleAppraisalService.sendFinalEvaluationFormForManager(this.userId)
      .subscribe((data: SenecaResponse<any>) => {
        if (data && data.error) {
          const messageObj: ApplicationModalMessage = {
            modalId: "fe001",
            text: this.translate.instant("errors." + data.error),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        } else {
          this.updateUserPhaseStatus();
          this.showSaveSuccessfulToaster();
          this.getUserProfileTab();
        }
      })
  }

  updateUserPhaseStatus() {
    if (!this.selectedYear) {
      console.log("No selected year");
      return;
    }

    this.subordinatesService.getSubordinatesForManager(this.selectedYear.perfYear, this.selectedYear.perfYearCode, this.userId)
      .subscribe((data: SenecaResponse<any>) => {
        if (data && data.response) {
          this.store.dispatch(PeopleAppraisalActions.UpdateSelfAssessmentUserStatusType({
            payload: {
              userId: this.userId,
              userStatus: data.response[0].status
            }
          }));
        }
      })
  }

  manageFinalEvaluationAction(id: string) {
    if (id == 'save') {
      this.peopleAppraisalService.createOrUpdatePerformanceAssessmentFinalEvaluationCommentForManager(this.userId, this.finalEvaluation.comment)
        .subscribe((data: SenecaResponse<any>) => {
          if (data && data.error) {
            const messageObj: ApplicationModalMessage = {
              modalId: "em001",
              text: this.translate.instant("errors." + data.error),
              title: this.translate.instant("generic.WARNING")
            }
            this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          } else {
            this.showSaveSuccessfulToaster();
          }
          this.editFinalEvaluationComment = false;
        })
    } else if (id == 'delete') {
      this.finalEvaluation.comment = '';
      this.peopleAppraisalService.createOrUpdatePerformanceAssessmentFinalEvaluationCommentForManager(this.userId, null)
        .subscribe((data: SenecaResponse<any>) => {
          if (data && data.error) {
            const messageObj: ApplicationModalMessage = {
              modalId: "em002",
              text: this.translate.instant("errors." + data.error),
              title: this.translate.instant("generic.WARNING")
            }
            this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          } else {
            this.showSaveSuccessfulToaster();
          }
          this.editFinalEvaluationComment = false;
        })
    }
  }

  onEditFinalEvaluationComment() {
    this.editFinalEvaluationComment = !this.editFinalEvaluationComment;
  }

  onEditBehaviourComment() {
    this.editBehaviorComment = !this.editBehaviorComment;
  }

  onBehaviorCommentEdited(text: string) {
    this.finalEvaluation.behaviorData.comment = text;
  }


  onEditCompetenceComment(text: string) {
    this.editCompetenceComment = !this.editCompetenceComment;
  }

  onCompetenceCommentEdited(text: string) {
    this.finalEvaluation.competenceData.comment = text;
  }


  manageBehaviorEvaluationAction(id: string, comment?: string, isFinal?: boolean, preventToastr?: boolean) {
    if (id == 'save') {
      this.peopleAppraisalService.createOrUpdatePerformanceAssessmentBehaviorEvaluationForManager(this.userId, null, comment?.trim())
        .subscribe((data: SenecaResponse<boolean>) => {
          if (data && data.error) {
            const messageObj: ApplicationModalMessage = {
              modalId: "em003",
              text: this.translate.instant("errors." + data.error),
              title: this.translate.instant("generic.WARNING")
            }
            this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          } else {
            if (!preventToastr) {
              window.scroll({ top: 0, left: 0, behavior: 'smooth' });
              this.showSaveSuccessfulToaster();
            }
          }
          this.editBehaviorComment = false;
        });
    } else if (id == 'delete') {
      if (isFinal) {
        this.finalEvaluation.behaviorData.comment = '';
      } else {
        this.manageBehaviorComments = '';
      }
      comment = '';
      this.peopleAppraisalService.createOrUpdatePerformanceAssessmentBehaviorEvaluationForManager(this.userId, null, null)
        .subscribe((data: SenecaResponse<boolean>) => {
          if (data && data.error) {
            const messageObj: ApplicationModalMessage = {
              modalId: "em004",
              text: this.translate.instant("errors." + data.error),
              title: this.translate.instant("generic.WARNING")
            }
            this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          } else {
            this.showSaveSuccessfulToaster();
          }
          this.editBehaviorComment = false;
        });
    }
  }

  manageCompetenceEvaluationAction(id: string, comment?: string, isFinal?: boolean, preventToastr?: boolean) {
    if (id == 'save') {
      this.peopleAppraisalService.createOrUpdatePerformanceAssessmentCompetenceEvaluationForManager(this.userId, null, comment?.trim())
        .subscribe((data: SenecaResponse<boolean>) => {
          if (data && data.error) {
            const messageObj: ApplicationModalMessage = {
              modalId: "em005",
              text: this.translate.instant("errors." + data.error),
              title: this.translate.instant("generic.WARNING")
            }
            this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          } else {
            if (!preventToastr) {
              window.scroll({ top: 0, left: 0, behavior: 'smooth' });
              this.showSaveSuccessfulToaster();
            }
          }
          this.editCompetenceComment = false;
        });
    } else if (id == 'delete') {
      if (isFinal) {
        this.finalEvaluation.competenceData.comment = '';
      } else {
        this.techSkillComment = '';
      }
      comment = '';
      this.peopleAppraisalService.createOrUpdatePerformanceAssessmentCompetenceEvaluationForManager(this.userId, null, null)
        .subscribe((data: SenecaResponse<any>) => {
          if (data && data.error) {
            const messageObj: ApplicationModalMessage = {
              modalId: "em006",
              text: this.translate.instant("errors." + data.error),
              title: this.translate.instant("generic.WARNING")
            }
            this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          } else {
            this.showSaveSuccessfulToaster();
          }
          this.editCompetenceComment = false;
        })
    }
  }


  editCurrentObjective(objective: any) {
    objective.editObjectiveEvaluation = !objective.editObjectiveEvaluation;
  }

  onObjectiveEvaluationEdited(text: string) {
    this.selectedObjective.evaluation = text;
  }

  manageObjectiveEvaluationAction(id: string) {
    if (id == 'save') {
      let evaluationForService: { goalId: string, evaluationText: string }[] = [];
      for (let i = 0; i < this.finalEvaluation.evaluatedData.length; i++) {
        evaluationForService.push({
          goalId: this.finalEvaluation.evaluatedData[i].goalId,
          evaluationText: this.finalEvaluation.evaluatedData[i].evaluation
        })
      }
      this.peopleAppraisalService.createOrUpdatePerformanceAssessmentGalEvaluationForManager(this.userId, evaluationForService)
        .subscribe((data: SenecaResponse<boolean>) => {
          if (data && data.error) {
            const messageObj: ApplicationModalMessage = {
              modalId: "em007",
              text: this.translate.instant("errors." + data.error),
              title: this.translate.instant("generic.WARNING")
            }
            this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          } else {
            this.finalEvaluation.emptyEvaluatedObjective = this.anyEmptyEvaluation();
            this.getUserProfileTab();
            this.showSaveSuccessfulToaster();
          }
          this.selectedObjective.editObjectiveEvaluation = false;
        })
    } else if (id == 'delete') {
      this.selectedObjective.evaluation = null;
      let evaluationForService: { goalId: string, evaluationText: string }[] = [];
      for (let i = 0; i < this.finalEvaluation.evaluatedData.length; i++) {
        evaluationForService.push({
          goalId: this.finalEvaluation.evaluatedData[i].goalId,
          evaluationText: this.finalEvaluation.evaluatedData[i].evaluation
        })
      }
      this.peopleAppraisalService.createOrUpdatePerformanceAssessmentGalEvaluationForManager(this.userId, evaluationForService)
        .subscribe((data: SenecaResponse<boolean>) => {
          if (data && data.error) {
            const messageObj: ApplicationModalMessage = {
              modalId: "em008",
              text: this.translate.instant("errors." + data.error),
              title: this.translate.instant("generic.WARNING")
            }
            this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          } else {
            this.selectedObjective.evaluation = '';
            this.finalEvaluation.emptyEvaluatedObjective = this.anyEmptyEvaluation();
            this.getUserProfileTab();
            this.showSaveSuccessfulToaster();
          }
          this.selectedObjective.editObjectiveEvaluation = false;
        })
    }
  }

  onEditMenuOptionClicked(option: string, objective: any, isSelfDefined?: boolean) {
    if (option == 'edit') {
      this.editObjectiveData = JSON.parse(JSON.stringify(objective));
      this.editObjectiveData.isSelfDefined = isSelfDefined;
      this.modalService.open('edit-objective-modal');
    } else if (option == 'delete') {
      this.objectiveToDelete = objective;
      this.objectiveToDelete.isSelfDefined = isSelfDefined;
      if ((this.finalEvaluation?.definedObjectives?.length || 0) + (this.finalEvaluation?.selfDefinedObjectives?.length || 0) == 1) {
        const messageObj: ApplicationModalMessage = {
          modalId: "delobj001",
          title: this.translate.instant('finalEvaluation.modals.WARNING'),
          subtitle: this.translate.instant('finalEvaluation.modals.CANT_DELETE_SUB'),
          text: this.translate.instant('finalEvaluation.modals.CANT_DELETE_DESCR')
        }
        this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
      } else {
        this.modalService.open('delete-objective-modal');
      }
    } else if (option == 'approve') {
      this.objectiveToApprove = objective;
      this.modalService.open('approve-objective-modal');
    }
  }

  onObjectiveEdit(newText: any) {
    this.editObjectiveData.title = newText;
  }

  // Chiudi modale edit obiettivo
  closeEditObjectiveModal(confirm?: boolean) {
    this.modalService.close('edit-objective-modal');

    if (confirm) {
      if (this.editObjectiveData.isSelfDefined) {
        for (let i = 0; i < this.finalEvaluation.selfDefinedObjectives.length; i++) {
          if (!this.finalEvaluation.selfDefinedObjectives[i].cloned) {
            if (this.finalEvaluation.selfDefinedObjectives[i].id == this.editObjectiveData.id) {
              this.finalEvaluation.selfDefinedObjectives[i].title = this.editObjectiveData.title;
              this.finalEvaluation.selfDefinedObjectives[i].approved = true;
            }
          }
        }
        this.finalSelfDefinedObjectivesCopy = JSON.parse(JSON.stringify(this.finalEvaluation.selfDefinedObjectives));
        this.saveSubordinateDefinedObjective(true);
      } else {
        for (let i = 0; i < this.finalEvaluation.definedObjectives.length; i++) {
          if (!this.finalEvaluation.definedObjectives[i].cloned) {
            if (this.finalEvaluation.definedObjectives[i].id == this.editObjectiveData.id) {
              this.finalEvaluation.definedObjectives[i].title = this.editObjectiveData.title;
            }
          }
        }
        this.saveFinalDefinedObjectives().then(() => {
          if (this.finalEvaluation.definedObjectives) {
            this.finalDefinedObjectivesCopy = JSON.parse(JSON.stringify(this.finalEvaluation.definedObjectives));
          }
        });
      }
    }
    this.editObjectiveData = {};
  }

  // Chiudi modale cancellazione obiettivo
  closeDeleteObjectiveModal(confirm?: boolean) {
    this.modalService.close('delete-objective-modal');
    if (confirm) {
      this.isLoadingDefinedObjectives = true;
      if (this.objectiveToDelete.isSelfDefined) {
        this.deleteSubordinateObjective(this.objectiveToDelete);
      } else {
        this.deleteObjective(this.objectiveToDelete);
      }
      this.objectiveToDelete = null;
      setTimeout(() => { this.isLoadingDefinedObjectives = false; }, 2000);
    }
  }

  // Chiudi modale cancellazione obiettivo
  closeApproveObjectiveModal(confirm?: boolean) {
    this.modalService.close('approve-objective-modal');
    if (confirm) {
      for (let i = 0; i < this.finalEvaluation.selfDefinedObjectives.length; i++) {
        if (this.finalEvaluation.selfDefinedObjectives[i].id == this.objectiveToApprove.id) {
          this.finalEvaluation.selfDefinedObjectives[i].title = this.objectiveToApprove.title;
        }
      }
      this.manageSubordinateDefineObjectiveActions('save', this.objectiveToApprove);
    }
  }

  // Descrizione modale cancellazione obiettivo 
  getDeleteModalText() {
    if (this.objectiveToDelete && this.objectiveToDelete.title) {
      return this.translations['finalEvaluation.modals.DELETE_DESCR_1'] + ' <b>' + this.objectiveToDelete.title + '</b> ' + this.translations['finalEvaluation.modals.DELETE_DESCR_2'];
    } else {
      return '';
    }
  }

  getApproveModalText() {
    if (this.objectiveToApprove && this.objectiveToApprove.title) {
      return this.translations['finalEvaluation.modals.APPROVE_DESCR_1'] + ' <b>' + this.objectiveToApprove.title + '</b> ' + this.translations['finalEvaluation.modals.APPROVE_DESCR_2'];
    } else {
      return '';
    }
  }

  // Elimina ogni riferimento al tutorial dal session storage
  removeTutorialsRefFromSessionStorage() {
    const openPasStructureTutorial = sessionStorage.getItem('openPasStructureTutorial');

    if (openPasStructureTutorial) {
      sessionStorage.removeItem('openPasStructureTutorial');
    }

    const openPersonalObjectiveEvaluationTutorial = sessionStorage.getItem('openPersonalObjectiveEvaluationTutorial');

    if (openPersonalObjectiveEvaluationTutorial) {
      sessionStorage.removeItem('openPersonalObjectiveEvaluationTutorial');
    }

    const openOrganizationalBehaviorsTutorial = sessionStorage.getItem('openOrganizationalBehaviorsTutorial');

    if (openOrganizationalBehaviorsTutorial) {
      sessionStorage.removeItem('openOrganizationalBehaviorsTutorial');
    }

    const openTechSkillEvaluationTutorial = sessionStorage.getItem('openTechSkillEvaluationTutorial');

    if (openTechSkillEvaluationTutorial) {
      sessionStorage.removeItem('openTechSkillEvaluationTutorial');
    }

    const openSetGoalsTutorial = sessionStorage.getItem('openSetGoalsTutorial');

    if (openSetGoalsTutorial) {
      sessionStorage.removeItem('openSetGoalsTutorial');
    }
  }

  allStepCompleted() {
    if (this.isManagement) {
      for (let i = 0; i < 3; i++) {
        if (!this.stepList[i].isComplete) {
          return false;
        }
      }
      return true;
    } else {
      for (let i = 0; i < 4; i++) {
        if (!this.stepList[i].isComplete) {
          return false;
        }
      }
      return true;
    }
  }

  canSaveDefinedObjectives() {
    if (this.newObjectiveList && this.newObjectiveList.length) {
      for (let i = 0; i < this.newObjectiveList.length; i++) {
        if (!this.newObjectiveList[i].title || !this.newObjectiveList[i].title.trim().length) {
          return false;
        }
      }
    }
    return true;
  }

  openSendFinalEvaluation() {
    this.modalService.open('send-hr-schedule')
  }

  closeSendFinalEvaluation(confirm?: boolean) {
    this.modalService.close('send-hr-schedule');
    if (confirm) {
      this.isLoadingFinalEvaluationData = true;
      if (this.finalEvaluation.comment && this.finalEvaluation.comment.length) {
        let saveFinalEvaluationStepPromise = this.saveFinalEvaluationStep(true);
        saveFinalEvaluationStepPromise.then(() => {
          this.peopleAppraisalService.createOrUpdatePerformanceAssessmentFinalEvaluationCommentForManager(this.userId, this.finalEvaluation.comment)
            .subscribe((data: SenecaResponse<any>) => {
              if (data && data.error) {
                const messageObj: ApplicationModalMessage = {
                  modalId: "em001",
                  text: this.translate.instant("errors." + data.error),
                  title: this.translate.instant("generic.WARNING")
                }
                this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
              } else {
                this.sendFinalEvaluation();
              }
              this.editFinalEvaluationComment = false;
            })
        })
      } else {
        let saveFinalEvaluationStepPromise = this.saveFinalEvaluationStep(true);
        saveFinalEvaluationStepPromise.then(() => {
          this.sendFinalEvaluation();
        })
      }
    }
  }

  getYearName(): string {
    if (!this.selectedYear) {
      console.log("No selected year");
      return '';
    }
    let yearName = '';
    if (this.selectedYear.perfYearCode == 'H1') {
      yearName = 'H2 ' + this.selectedYear?.perfYear
    } else {
      yearName = 'H1 ' + (this.selectedYear.perfYear + 1);
    }
    return yearName;
  }

  openFinalShareModal() {
    if (this.isLoadingFinalEvaluationData || !this.feedbackCheck || !this.allStepCompleted() || this.checkNewObjectives()) {
      this.modalService.open('cannot-share-modal');
    } else {
      this.modalService.open('final-share-modal');
    }
  }

  closeFinalShareModal(confirm?: boolean) {
    this.modalService.close('final-share-modal');
    if (confirm) {
      this.confirmFinalShare();
    }
  }

  onCancelEditFinalEvaluation(objective: any) {
    objective.title = objective.originalTitle;
    objective.isEdit = false;
    objective.originalTitle = undefined;
  }

  closeCannotShareModal() {
    this.modalService.close('cannot-share-modal');
  }

  onFeedbackCheck() {
    this.feedbackCheck = !this.feedbackCheck;
  }


  // salva le modifiche agli obiettivi aggiunti self assessment
  saveSubordinateDefinedObjective(updateDataOnly?: boolean) {
    this.isLoadingDefinedObjectives = true;
    /* for (let i = 0; i < this.newObjectiveList.length; i++) {
       this.newObjectiveList[i].id = 'saved' + (this.newObjectiveList.length + i);
       this.newObjectiveList[i].isEdit = false;
     }*/

    let goals = [];
    for (let i = 0; i < this.finalEvaluation.selfDefinedObjectives.length; i++) {
      if (updateDataOnly) {
        if (this.finalEvaluation.selfDefinedObjectives[i].approved) {
          goals.push({
            goalId: this.finalEvaluation.selfDefinedObjectives[i].id,
            text: this.finalEvaluation.selfDefinedObjectives[i].title
          });
        }
      } else {
        goals.push({
          goalId: this.finalEvaluation.selfDefinedObjectives[i].id,
          text: this.finalEvaluation.selfDefinedObjectives[i].title
        });
      }
    }

    if (this.createOrUpdatePerformanceAssessmentGoalsForManager$) {
      this.createOrUpdatePerformanceAssessmentGoalsForManager$.unsubscribe();
    }

    this.createOrUpdatePerformanceAssessmentGoalsForManager$ = this.peopleAppraisalService.updatePerformanceAssessmentGoalsBySubordinateForManager(this.userId, goals)
      .subscribe((data: SenecaResponse<any>) => {
        if (data && data.error) {
          const messageObj: ApplicationModalMessage = {
            modalId: "pdm001",
            text: this.translate.instant("errors." + data.error),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        } else {
          this.updateUserPhaseStatus();
          this.showSaveSuccessfulToaster();
          this.getUserProfileTab();
        }
        this.isLoadingDefinedObjectives = false;
      }, (err?: any) => {
        this.isLoadingDefinedObjectives = false;
        const messageObj: ApplicationModalMessage = {
          modalId: "pdm002",
          text: this.translate.instant("errors." + err?.message),
          title: this.translate.instant("generic.WARNING")
        }
        this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
      });
  }
  // aggiorna obiettivi da valutazione finale 
  manageSubordinateDefineObjectiveActions(id: string, objective: any) {
    if (id == 'save') {
      let goals = [
        {
          goalId: objective.id,
          text: objective.title
        }
      ];

      if (this.createOrUpdatePerformanceAssessmentGoalsForManager$) {
        this.createOrUpdatePerformanceAssessmentGoalsForManager$.unsubscribe();
      }
      this.createOrUpdatePerformanceAssessmentGoalsForManager$ = this.peopleAppraisalService.updatePerformanceAssessmentGoalsBySubordinateForManager(this.userId, goals)
        .subscribe((data: SenecaResponse<any>) => {
          if (data && data.error) {
            const messageObj: ApplicationModalMessage = {
              modalId: "pdm001",
              text: this.translate.instant("errors." + data.error),
              title: this.translate.instant("generic.WARNING")
            }
            this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          } else {
            this.showSaveSuccessfulToaster();
            this.getFinalEvaluationData();
          }
          this.isLoadingDefinedObjectives = false;
        }, (err?: any) => {
          this.isLoadingDefinedObjectives = false;
          const messageObj: ApplicationModalMessage = {
            modalId: "pdm002",
            text: this.translate.instant("errors." + err?.message),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        });
    } else if (id == 'delete') {
      this.openSubordinateDeleteSavedObjective(objective);
    }
  }

  openSubordinateDeleteSavedObjective(objective: any, fromEdit?: boolean) {
    this.objectiveToDelete = objective;
    this.objectiveToDeleteFromEdit = fromEdit;
    this.modalService.open('confirm-delete-subordinate-saved-objective');
  }

  closeSubordinateDeleteSavedObjective(confirm?: boolean) {
    this.modalService.close('confirm-delete-subordinate-saved-objective');
    if (confirm) {
      this.deleteSubordinateObjective(this.objectiveToDelete);
      this.objectiveToDelete = null;
    }
  }


  deleteSubordinateObjective(objective: any) {
    // In caso contrario devo chiamare i servizi per l'eliminazione vera e propria
    if (this.deletePerformanceAssessmentGoalsForManager$) {
      this.deletePerformanceAssessmentGoalsForManager$.unsubscribe();
    }
    this.deletePerformanceAssessmentGoalsForManager$ = this.peopleAppraisalService.deletePerformanceAssessmentGoalsBySubordinateForManager(this.userId, [objective.id])
      .subscribe((data: SenecaResponse<any>) => {
        if (data && data.error) {
          const messageObj: ApplicationModalMessage = {
            modalId: "pdm003",
            text: this.translate.instant("errors." + data.error),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        } else {
          this.showSaveSuccessfulToaster();
          this.getUserProfileTab(true);
          this.getFinalEvaluationData();
        }
      }, (err?: any) => {
        const messageObj: ApplicationModalMessage = {
          modalId: "pdm004",
          text: this.translate.instant("errors." + err?.message),
          title: this.translate.instant("generic.WARNING")
        }
        this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
      });
  }

  confirmFinalShare() {
    let saveFinalDefinedObjectivesPromise = this.saveFinalDefinedObjectives();
    saveFinalDefinedObjectivesPromise.then(() => {
      if (this.finalEvaluation.selfDefinedObjectives && this.finalEvaluation.selfDefinedObjectives.length) {
        this.isLoadingFinalEvaluationData = true;
        let goals = [];
        for (let i = 0; i < this.finalEvaluation.selfDefinedObjectives.length; i++) {
          goals.push({
            goalId: this.finalEvaluation.selfDefinedObjectives[i].id,
            text: this.finalEvaluation.selfDefinedObjectives[i].title
          });
        }

        if (this.createOrUpdatePerformanceAssessmentGoalsForManager$) {
          this.createOrUpdatePerformanceAssessmentGoalsForManager$.unsubscribe();
        }
        this.createOrUpdatePerformanceAssessmentGoalsForManager$ = this.peopleAppraisalService.updatePerformanceAssessmentGoalsBySubordinateForManager(this.userId, goals)
          .subscribe((data: SenecaResponse<any>) => {
            if (data && data.error) {
              this.isLoadingFinalEvaluationData = false;
              const messageObj: ApplicationModalMessage = {
                modalId: "pdm001",
                text: this.translate.instant("errors." + data.error),
                title: this.translate.instant("generic.WARNING")
              }
              this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
            } else {
              this.finalShare();
            }
          }, (err?: any) => {
            this.isLoadingFinalEvaluationData = false;
            const messageObj: ApplicationModalMessage = {
              modalId: "pdm002",
              text: this.translate.instant("errors." + err?.message),
              title: this.translate.instant("generic.WARNING")
            }
            this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          });
      } else {
        this.finalShare();
      }
    })
  }

  finalShare() {
    this.isLoadingFinalEvaluationData = true;
    if (this.finalShare$) {
      this.finalShare$.unsubscribe();
    }
    this.saveFinalEvaluationComment();
    this.finalShare$ = this.peopleAppraisalService.sharePostCalibrationForManager(this.userId, this.feedbackCheck)
      .subscribe((data: SenecaResponse<any>) => {
        if (data && data.error) {
          const messageObj: ApplicationModalMessage = {
            modalId: "fs001",
            text: this.translate.instant("errors." + data.error),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          this.isLoadingFinalEvaluationData = false;
        } else {
          // Reset dati
          this.finalEvaluation = {};
          this.finalEvaluation.evaluatedData = [];
          this.finalEvaluation.behaviorData = {};
          this.finalEvaluation.competenceData = {};
          this.finalEvaluation.pasPerformer = {};
          this.finalEvaluation.evaluatedData = {};
          this.finalEvaluation.definedObjectives = [];
          this.finalEvaluation.selfDefinedObjectives = [];
          this.finalEvaluation.comment = null;
          this.finalEvaluationBehaviourCommentCopy = null;
          this.finalEvaluationCompetenceCommentCopy = null;
          this.finalEvaluationCommentCopy = null;
          this.finalEvaluationDataCopy = {};
          this.finalDefinedObjectivesCopy = [];
          this.finalSelfDefinedObjectivesCopy = [];
          this.updateUserPhaseStatus();
          this.showSaveSuccessfulToaster();
          this.getUserProfileTab(true);
          this.getFinalEvaluationData();
        }
      }, (err?: any) => {
        const messageObj: ApplicationModalMessage = {
          modalId: "fs003",
          text: this.translate.instant("errors." + err?.message),
          title: this.translate.instant("generic.WARNING")
        }
        this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        this.isLoadingFinalEvaluationData = false;
      });
  }

  // Controllo messaggio obiettivi
  checkNewObjectives() {
    if (this.finalEvaluation.selfDefinedObjectives && this.finalEvaluation.selfDefinedObjectives.length) {
      let anyNew = this.finalEvaluation.selfDefinedObjectives.find((x: any) => x.goalToBeApproved);
      if (anyNew && anyNew.id) {
        return true;
      }
    }
    return false;
  }

  scrollToNewObjective() {
    this.newObjectiveContainer.nativeElement.scrollIntoView({ behavior: "smooth", block: "start" });
  }

  // Salva le note e i commenti della valutazione
  saveBehaviourEvaluation() {
    this.isLoadingBehaviours = true;
    let saveBehavioursRatingsPromise = this.saveBehavioursRatings();

    saveBehavioursRatingsPromise.then(() => {
      this.competenceArrayCopy = JSON.parse(JSON.stringify(this.competenceArray));
      this.manageBehaviorCommentsCopy = JSON.parse(JSON.stringify(this.manageBehaviorComments));
      this.updateUserPhaseStatus();
      this.showSaveSuccessfulToaster();
      this.getUserProfileTab(true);
      this.isLoadingBehaviours = false;

      // Vado allo step successivo
      this.goToNextStep(true);
    })
      .catch((e?) => {
        console.log(e);
        this.isLoadingBehaviours = false;
      })
  }

  // Salva le note e i commenti della valutazione
  saveBehavioursRatings() {
    // Salvo il commento
    this.manageBehaviorEvaluationAction("save", this.manageBehaviorComments, false, true);

    // Salvo i valori del rating
    let promises = [];
    for (let j = 0, competenceArrayLength = this.competenceArray.length; j < competenceArrayLength; j++) {
      let competenceArray = this.competenceArray[j];

      if (competenceArray.competences && competenceArray.competences.length) {
        for (let t = 0, competencesLength = competenceArray.competences.length; t < competencesLength; t++) {
          let currentCompetence = competenceArray.competences[t];
          promises.push(new Promise((resolve: Function, reject: Function) => {

            if (currentCompetence.isPartialAverage) {
              resolve();
            } else {
              let behaviorEvaluation: { behaviorId: string, evaluationScore: string };
              let score = 0;
              for (let i = 0; i < currentCompetence.rating.length; i++) {
                if (currentCompetence.rating[i].isClicked) {
                  score = currentCompetence.rating[i].id + 1;
                }
              }
              behaviorEvaluation = { behaviorId: currentCompetence.behaviorId, evaluationScore: score.toString() };
              // let comment;
              // if (this.manageBehaviorComments != this.manageBehaviorCommentsCopy) {
              //   comment = this.manageBehaviorComments;
              // }

              this.peopleAppraisalService.createOrUpdatePerformanceAssessmentBehaviorEvaluationForManager(this.userId, behaviorEvaluation, this.manageBehaviorComments)
                .subscribe((data: SenecaResponse<boolean>) => {
                  if (data && data.error) {
                    const messageObj: ApplicationModalMessage = {
                      modalId: "pdm017",
                      text: this.translate.instant("errors." + data.error),
                      title: this.translate.instant("generic.WARNING")
                    }
                    this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
                    resolve();
                  } else {
                    this.behaviourCompetenceToChange = null;
                    resolve();
                  }
                }, (err?: any) => {
                  resolve();
                  const messageObj: ApplicationModalMessage = {
                    modalId: "pdm018",
                    text: this.translate.instant("errors." + err?.message),
                    title: this.translate.instant("generic.WARNING")
                  }
                  this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
                  this.isLoadingBehaviours = false;
                });
            }
          }));
        }
      }
    }
    // Risolvo le promesse coi dati recuperati
    return Promise.all(promises);
  }

  onBehaviourRatingChanged(competence: any) {
    if (competence) {
      let softSkill;

      for (let i = 0, competencesLength = this.competenceArrayCopy.length; i < competencesLength; i++) {
        let currentCompetence = this.competenceArrayCopy[i];
        let competenceFound;

        if (currentCompetence.competences && !competenceFound) {
          for (let m = 0, competencesLength = currentCompetence.competences.length; m < competencesLength; m++) {
            let originalCompetence = currentCompetence.competences[m];

            if (originalCompetence.behaviorId && originalCompetence.behaviorId === competence.behaviorId) {
              competenceFound = true;
              softSkill = currentCompetence;

              // Competenza trovata. Ora verifico lo score selezionato e quello nuovo
              let originalScore = 0;
              for (let t = 0; t < originalCompetence.rating.length; t++) {
                if (originalCompetence.rating[t].isClicked) {
                  originalScore = originalCompetence.rating[t].id + 1;
                }
              }
              let newScore = 0;
              for (let y = 0; y < competence.rating.length; y++) {
                if (competence.rating[y].isClicked) {
                  newScore = competence.rating[y].id + 1;
                }
              }

              if (originalScore !== newScore) {
                this.behaviourCompetenceToChange = originalCompetence;
              } else {
                this.behaviourCompetenceToChange = null;
              }

              break;
            }
          }
        }
      }
      this.updateSoftSkillsPartialAvg(softSkill);
    }
  }

  // Aggiorna in real time la media parziale delle soft skill
  updateSoftSkillsPartialAvg(softSkill: any) {
    let currentCompetenceRatingCounter = 0;
    let currentCompetenceRatingSum = 0;
    let currentCompetenceSelfRatingCounter = 0;
    let currentCompetenceSelfRatingSum = 0;

    let currentCompetenceRatingAvgNum = 0;
    let currentCompetenceSelfRatingAvgNum = 0;
    let currentCompetenceRatingAvgString = null;
    let currentCompetenceSelfRatingAvgString = null;

    let isPostCalibrationOrFinalStepCompleted = this.isPostCalibration || this.isFinalStepCompleted;

    for (let k = 0, skillsLength = this.competenceArray.length; k < skillsLength; k++) {
      const currentSoftkill = this.competenceArray[k];

      if (currentSoftkill.category === softSkill.category) {
        for (let q = 0, competencesLength = currentSoftkill.competences.length; q < competencesLength; q++) {
          const currentCompetence = currentSoftkill.competences[q];
          if (!currentCompetence.isPartialAverage) {
            let currentRating = 0;
            for (let i = 0; i < currentCompetence.rating.length; i++) {
              if (currentCompetence.rating[i].isClicked) {
                currentRating++;
              }
            }

            // Media parziale
            currentCompetenceRatingCounter++;
            currentCompetenceSelfRatingCounter++;
            currentCompetenceRatingSum = currentCompetenceRatingSum + (currentRating || 0);
          }
        }
        break;
      }
    }

    // Calcolo la media
    currentCompetenceRatingAvgNum = +(currentCompetenceRatingSum / currentCompetenceRatingCounter).toFixed(2) || 0;
    currentCompetenceSelfRatingAvgNum = +(currentCompetenceSelfRatingSum / currentCompetenceSelfRatingCounter).toFixed(2) || 0;

    currentCompetenceRatingAvgString = currentCompetenceRatingAvgNum.toString();
    if (currentCompetenceRatingAvgString && currentCompetenceRatingAvgString.indexOf(".") >= 0) {
      currentCompetenceRatingAvgString = currentCompetenceRatingAvgString.replace(".", ",");
    }

    currentCompetenceSelfRatingAvgString = currentCompetenceSelfRatingAvgNum.toString();
    if (currentCompetenceSelfRatingAvgString && currentCompetenceSelfRatingAvgString.indexOf(".") >= 0) {
      currentCompetenceSelfRatingAvgString = currentCompetenceSelfRatingAvgString.replace(".", ",");
    }

    for (let k = 0, skillsLength = this.competenceArray.length; k < skillsLength; k++) {
      const currentSoftkill = this.competenceArray[k];

      if (currentSoftkill.category === softSkill.category) {
        for (let q = 0, competencesLength = currentSoftkill.competences.length; q < competencesLength; q++) {
          const currentCompetence = currentSoftkill.competences[q];
          if (currentCompetence.isPartialAverage) {

            if (isPostCalibrationOrFinalStepCompleted) {
              currentCompetence.selfRatingAvgNum = currentCompetenceRatingAvgNum;
              currentCompetence.selfRatingAvgString = currentCompetenceRatingAvgString;
            } else {
              currentCompetence.ratingAvgNum = currentCompetenceRatingAvgNum;
              currentCompetence.ratingAvgString = currentCompetenceRatingAvgString;
            }
            break;
          }
        }

        break;
      }
    }
  }

  // Aggiorna in real time la media parziale delle tech skill
  updateTechSkillsPartialAvg(techSkill: any) {
    let currentCompetenceRatingCounter = 0;
    let currentCompetenceRatingSum = 0;
    let currentCompetenceSelfRatingCounter = 0;
    let currentCompetenceSelfRatingSum = 0;

    let currentCompetenceRatingAvgNum = 0;
    let currentCompetenceSelfRatingAvgNum = 0;
    let currentCompetenceRatingAvgString = null;
    let currentCompetenceSelfRatingAvgString = null;

    let isPostCalibrationOrFinalStepCompleted = this.isPostCalibration || this.isFinalStepCompleted;

    for (let k = 0, skillsLength = this.techSkillArray.length; k < skillsLength; k++) {
      const currentTechSkill = this.techSkillArray[k];
      if (currentTechSkill.category === techSkill.category) {
        for (let q = 0, competencesLength = currentTechSkill.competences.length; q < competencesLength; q++) {
          const currentCompetence = currentTechSkill.competences[q];
          if (!currentCompetence.isPartialAverage) {
            let currentRating = 0;
            for (let i = 0; i < currentCompetence.rating.length; i++) {
              if (currentCompetence.rating[i].isClicked) {
                currentRating++;
              }
            }

            // Media parziale
            currentCompetenceRatingCounter++;
            currentCompetenceSelfRatingCounter++;
            currentCompetenceRatingSum = currentCompetenceRatingSum + (currentRating || 0);
          }
        }
        break;
      }
    }

    // Calcolo la media
    currentCompetenceRatingAvgNum = +(currentCompetenceRatingSum / currentCompetenceRatingCounter).toFixed(2) || 0;
    currentCompetenceSelfRatingAvgNum = +(currentCompetenceSelfRatingSum / currentCompetenceSelfRatingCounter).toFixed(2) || 0;

    currentCompetenceRatingAvgString = currentCompetenceRatingAvgNum.toString();
    if (currentCompetenceRatingAvgString && currentCompetenceRatingAvgString.indexOf(".") >= 0) {
      currentCompetenceRatingAvgString = currentCompetenceRatingAvgString.replace(".", ",");
    }

    currentCompetenceSelfRatingAvgString = currentCompetenceSelfRatingAvgNum.toString();
    if (currentCompetenceSelfRatingAvgString && currentCompetenceSelfRatingAvgString.indexOf(".") >= 0) {
      currentCompetenceSelfRatingAvgString = currentCompetenceSelfRatingAvgString.replace(".", ",");
    }

    for (let k = 0, skillsLength = this.techSkillArray.length; k < skillsLength; k++) {
      const currentTechSkill = this.techSkillArray[k];

      if (currentTechSkill.category === techSkill.category) {
        for (let q = 0, competencesLength = currentTechSkill.competences.length; q < competencesLength; q++) {
          const currentCompetence = currentTechSkill.competences[q];
          if (currentCompetence.isPartialAverage) {

            if (isPostCalibrationOrFinalStepCompleted) {
              currentCompetence.selfRatingAvgNum = currentCompetenceRatingAvgNum;
              currentCompetence.selfRatingAvgString = currentCompetenceRatingAvgString;
            } else {
              currentCompetence.ratingAvgNum = currentCompetenceRatingAvgNum;
              currentCompetence.ratingAvgString = currentCompetenceRatingAvgString;
            }
            break;
          }
        }

        break;
      }
    }
  }

  onTechSkillRatingChanged(competence: any) {
    if (competence) {
      let techSkill;

      for (let i = 0, competencesLength = this.techSkillArrayCopy.length; i < competencesLength; i++) {
        let currentCompetence = this.techSkillArrayCopy[i];
        let competenceFound;

        if (currentCompetence.competences && !competenceFound) {
          for (let m = 0, competencesLength = currentCompetence.competences.length; m < competencesLength; m++) {
            let originalCompetence = currentCompetence.competences[m];

            if (originalCompetence.competenceId && originalCompetence.competenceId === competence.competenceId) {
              competenceFound = true;
              techSkill = currentCompetence;

              // Competenza trovata. Ora verifico lo score selezionato e quello nuovo
              let originalScore = 0;
              for (let t = 0; t < originalCompetence.rating.length; t++) {
                if (originalCompetence.rating[t].isClicked) {
                  originalScore = originalCompetence.rating[t].id + 1;
                }
              }
              let newScore = 0;
              for (let y = 0; y < competence.rating.length; y++) {
                if (competence.rating[y].isClicked) {
                  newScore = competence.rating[y].id + 1;
                }
              }

              if (originalScore !== newScore) {
                this.techSkillCompetenceToChange = originalCompetence;
              } else {
                this.techSkillCompetenceToChange = null;
              }

              break;
            }
          }
        }
      }

      this.updateTechSkillsPartialAvg(techSkill);
    }
  }

  // Verifica se qualcosa nello step 2 (valutazione comportamenti organizzativi) è cambiato e non è stato salvato
  anyBehaviourEvaluationChangesToSave() {
    if ((this.manageBehaviorComments != this.manageBehaviorCommentsCopy)
      || this.behaviourCompetenceToChange) {
      return true;
    }
    return false;
  }

  // Salva le note e i commenti della valutazione delle competenze tecniche
  saveTechSkills() {
    this.isLoadingTechSkills = true;
    let saveTechSkillsRatingsPromise = this.saveTechSkillsRatings();

    saveTechSkillsRatingsPromise.then(() => {
      this.techSkillArrayCopy = JSON.parse(JSON.stringify(this.techSkillArray));
      this.techSkillCommentCopy = JSON.parse(JSON.stringify(this.techSkillComment));
      this.techSkillCompetenceToChange = null;
      this.isLoadingTechSkills = false;

      this.updateUserPhaseStatus();
      this.showSaveSuccessfulToaster();
      this.getUserProfileTab(true);

      // Vado allo step successivo
      this.goToNextStep(true);
    })
      .catch((e?) => {
        console.log(e);
        this.isLoadingTechSkills = false;
      })
  }

  // Salva le note e i commenti della valutazione delle competenze tecniche
  saveTechSkillsRatings() {
    // Salvo il commento
    this.manageCompetenceEvaluationAction("save", this.techSkillComment, false, true);

    // Salvo i valori del rating
    let promises = [];
    for (let j = 0, techSkillArrayLength = this.techSkillArray.length; j < techSkillArrayLength; j++) {
      let techSkillArray = this.techSkillArray[j];

      if (techSkillArray.competences && techSkillArray.competences.length) {
        for (let t = 0, competencesLength = techSkillArray.competences.length; t < competencesLength; t++) {
          let currentCompetence = techSkillArray.competences[t];
          promises.push(new Promise((resolve: Function, reject: Function) => {
            if (currentCompetence.isPartialAverage) {
              resolve();
            } else {
              let competenceEvaluation: { competenceId: string, evaluationScore: number };
              let score = 0;
              for (let i = 0; i < currentCompetence.rating.length; i++) {
                if (currentCompetence.rating[i].isClicked) {
                  score = currentCompetence.rating[i].id + 1;
                }
              }

              if (score > 0) {
                // Se lo score fosse a 0, il servizio tornerebbe "INVALID_OBJECT"
                competenceEvaluation = { competenceId: currentCompetence.competenceId, evaluationScore: score };
                let comment;
                if (this.techSkillComment != this.techSkillCommentCopy) {
                  comment = this.techSkillComment;
                }

                this.peopleAppraisalService.createOrUpdatePerformanceAssessmentCompetenceEvaluationForManager(this.userId, [competenceEvaluation], comment)
                  .subscribe((data: SenecaResponse<boolean>) => {
                    if (data && data.error) {
                      const messageObj: ApplicationModalMessage = {
                        modalId: "pdm017",
                        text: this.translate.instant("errors." + data.error),
                        title: this.translate.instant("generic.WARNING")
                      }
                      this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
                      resolve();
                    } else {
                      resolve();
                    }
                  }, (err?: any) => {
                    resolve();
                    const messageObj: ApplicationModalMessage = {
                      modalId: "pdm018",
                      text: this.translate.instant("errors." + err?.message),
                      title: this.translate.instant("generic.WARNING")
                    }
                    this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
                    this.isLoadingTechSkills = false;
                  });
              } else {
                resolve();
              }
            }
          }));
        }
      }
    }
    // Risolvo le promesse coi dati recuperati
    return Promise.all(promises);
  }

  // Recupera il numero di stelline da valorizzare in base alla votazione
  getCalculatedStarsCountByAvg(arrayRef: any, isSelfRating?: boolean) {
    let promises = [];
    if (arrayRef && arrayRef.length) {
      for (let j = 0, competenceArrayLength = arrayRef.length; j < competenceArrayLength; j++) {
        let competenceArray = arrayRef[j];

        if (competenceArray.competences && competenceArray.competences.length) {
          for (let t = 0, competencesLength = competenceArray.competences.length; t < competencesLength; t++) {
            let currentCompetence = competenceArray.competences[t];
            promises.push(new Promise((resolve: Function, reject: Function) => {

              if (!currentCompetence.isPartialAverage) {
                resolve();
              } else {
                const avg = isSelfRating ? currentCompetence.selfRatingAvgNum : currentCompetence.ratingAvgNum;

                this.peopleAppraisalService.getCalculatedStarsCountByAvg(avg)
                  .subscribe((data: SenecaResponse<number>) => {
                    if (data && data.error) {
                      const messageObj: ApplicationModalMessage = {
                        modalId: "pdm017",
                        text: this.translate.instant("errors." + data.error),
                        title: this.translate.instant("generic.WARNING")
                      }
                      this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
                      resolve();
                    } else {
                      if (isSelfRating) {
                        currentCompetence.selfRatingStars = data.response;
                      } else {
                        currentCompetence.ratingStars = data.response;
                      }

                      resolve();
                    }
                  }, (err?: any) => {
                    resolve();
                    const messageObj: ApplicationModalMessage = {
                      modalId: "pdm018",
                      text: this.translate.instant("errors." + err?.message),
                      title: this.translate.instant("generic.WARNING")
                    }
                    this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
                  });
              }
            }));
          }
        }
      }
    }
    // Risolvo le promesse coi dati recuperati
    return Promise.all(promises);
  }

  // Recupera il numero di stelline da valorizzare per la media finale
  getFinalAvgStarRating(objRef: any, isSelfRating?: boolean) {
    return new Promise((resolve: Function, reject: Function) => {
      const avg = isSelfRating ? objRef.selfRatingAvgNum : objRef.ratingAvgNum;

      this.peopleAppraisalService.getCalculatedStarsCountByAvg(avg)
        .subscribe((data: SenecaResponse<number>) => {
          if (data && data.error) {
            const messageObj: ApplicationModalMessage = {
              modalId: "pdm017",
              text: this.translate.instant("errors." + data.error),
              title: this.translate.instant("generic.WARNING")
            }
            this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
            resolve();
          } else {
            if (isSelfRating) {
              objRef.selfRatingStars = data.response;
            } else {
              objRef.ratingStars = data.response;
            }

            resolve();
          }
        }, (err?: any) => {
          resolve();
          const messageObj: ApplicationModalMessage = {
            modalId: "pdm018",
            text: this.translate.instant("errors." + err?.message),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        });
    });
  }

  // Verifica se qualcosa nello step 3 è cambiato e non è stato salvato
  anyTechSkillEvaluationChangesToSave() {
    if ((this.techSkillComment != this.techSkillCommentCopy)
      || this.techSkillCompetenceToChange) {
      return true;
    }
    return false;
  }

  saveFinalBehaviourComment() {
    return new Promise<void>((resolve, reject) => {
      if (!this.isFinalBehaviourCommentChanged()) {
        resolve();
      } else {
        const comment = this.finalEvaluation.behaviorData && this.finalEvaluation.behaviorData.comment || '';
        this.peopleAppraisalService.createOrUpdatePerformanceAssessmentBehaviorEvaluationForManager(this.userId, null, comment?.trim())
          .subscribe((data: SenecaResponse<any>) => {
            if (data && data.error) {
              const messageObj: ApplicationModalMessage = {
                modalId: "em001",
                text: this.translate.instant("errors." + data.error),
                title: this.translate.instant("generic.WARNING")
              }
              this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
            }

            resolve();
          }, (err?: any) => {
            const messageObj: ApplicationModalMessage = {
              modalId: "pdm006",
              text: this.translate.instant("errors." + err?.message),
              title: this.translate.instant("generic.WARNING")
            }
            this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
            resolve();
          });
      }
    })
  }

  saveFinalBehaviourCompetenceComment() {
    return new Promise<void>((resolve, reject) => {
      if (!this.isFinalBehaviourCompetenceCommentChanged()) {
        resolve();
      } else {
        const comment = this.finalEvaluation.competenceData && this.finalEvaluation.competenceData.comment || '';
        this.peopleAppraisalService.createOrUpdatePerformanceAssessmentCompetenceEvaluationForManager(this.userId, null, comment?.trim())
          .subscribe((data: SenecaResponse<any>) => {
            if (data && data.error) {
              const messageObj: ApplicationModalMessage = {
                modalId: "em001",
                text: this.translate.instant("errors." + data.error),
                title: this.translate.instant("generic.WARNING")
              }
              this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
            }

            resolve();
          }, (err?: any) => {
            const messageObj: ApplicationModalMessage = {
              modalId: "pdm006",
              text: this.translate.instant("errors." + err?.message),
              title: this.translate.instant("generic.WARNING")
            }
            this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
            resolve();
          });
      }
    })
  }

  saveFinalEvaluationData() {
    return new Promise<void>((resolve, reject) => {
      if (!this.isFinalEvaluationDataChanged()) {
        resolve();
      } else {
        let evaluationForService: { goalId: string, evaluationText: string }[] = [];
        for (let i = 0; i < this.finalEvaluation.evaluatedData.length; i++) {
          evaluationForService.push({
            goalId: this.finalEvaluation.evaluatedData[i].goalId,
            evaluationText: this.finalEvaluation.evaluatedData[i].evaluation
          })
        }
        this.peopleAppraisalService.createOrUpdatePerformanceAssessmentGalEvaluationForManager(this.userId, evaluationForService)
          .subscribe((data: SenecaResponse<any>) => {
            if (data && data.error) {
              const messageObj: ApplicationModalMessage = {
                modalId: "em001",
                text: this.translate.instant("errors." + data.error),
                title: this.translate.instant("generic.WARNING")
              }
              this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
            }

            resolve();
          }, (err?: any) => {
            const messageObj: ApplicationModalMessage = {
              modalId: "pdm006",
              text: this.translate.instant("errors." + err?.message),
              title: this.translate.instant("generic.WARNING")
            }
            this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
            resolve();
          });
      }
    })
  }

  deleteFinalDefinedObjectives() {
    return new Promise<void>((resolve, reject) => {
      if (this.finalDefinedObjectivesToDelete && this.finalDefinedObjectivesToDelete.length) {
        let goalIds = [];
        for (let t = 0, finalDefinedObjectivesToDeleteLength = this.finalDefinedObjectivesToDelete.length; t < finalDefinedObjectivesToDeleteLength; t++) {
          let currentObj = this.finalDefinedObjectivesToDelete[t];
          goalIds.push(currentObj.id);
        }

        this.peopleAppraisalService.deletePerformanceAssessmentGoalsForManager(this.userId, goalIds)
          .subscribe((data: SenecaResponse<any>) => {
            if (data && data.error) {
              const messageObj: ApplicationModalMessage = {
                modalId: "pdm003",
                text: this.translate.instant("errors." + data.error),
                title: this.translate.instant("generic.WARNING")
              }
              this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
            }

            this.finalDefinedObjectivesToDelete.length = 0;

            resolve();
          }, (err?: any) => {
            resolve();
            const messageObj: ApplicationModalMessage = {
              modalId: "pdm004",
              text: this.translate.instant("errors." + err?.message),
              title: this.translate.instant("generic.WARNING")
            }
            this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          });
      } else {
        resolve();
      }
    })
  }

  saveFinalDefinedObjectives() {
    return new Promise<void>((resolve, reject) => {
      if (!this.isFinalDefinedObjectivesChanged()) {
        resolve();
      } else {
        let goals = [];
        for (let i = 0; i < this.finalEvaluation.definedObjectives.length; i++) {
          if (!this.finalEvaluation.definedObjectives[i].cloned) {
            goals.push({
              goalId: this.finalEvaluation.definedObjectives[i].id,
              text: this.finalEvaluation.definedObjectives[i].title
            });
          }
        }

        this.peopleAppraisalService.createOrUpdatePerformanceAssessmentGoalsForManager(this.userId, goals)
          .subscribe((data: SenecaResponse<any>) => {
            if (data && data.error) {
              const messageObj: ApplicationModalMessage = {
                modalId: "em001",
                text: this.translate.instant("errors." + data.error),
                title: this.translate.instant("generic.WARNING")
              }
              this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
            }
            resolve();
          }, (err?: any) => {
            const messageObj: ApplicationModalMessage = {
              modalId: "pdm006",
              text: this.translate.instant("errors." + err?.message),
              title: this.translate.instant("generic.WARNING")
            }
            this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
            resolve();
          });
      }
    })
  }

  deleteFinalSelfDefinedObjectives() {
    return new Promise<void>((resolve, reject) => {
      if (this.finalSelfDefinedObjectivesToDelete && this.finalSelfDefinedObjectivesToDelete.length) {
        let goalIds = [];
        for (let t = 0, finalSelfDefinedObjectivesToDeleteLength = this.finalSelfDefinedObjectivesToDelete.length; t < finalSelfDefinedObjectivesToDeleteLength; t++) {
          let currentObj = this.finalSelfDefinedObjectivesToDelete[t];
          goalIds.push(currentObj.id);
        }

        this.peopleAppraisalService.deletePerformanceAssessmentGoalsBySubordinateForManager(this.userId, goalIds)
          .subscribe((data: SenecaResponse<any>) => {
            if (data && data.error) {
              const messageObj: ApplicationModalMessage = {
                modalId: "pdm003",
                text: this.translate.instant("errors." + data.error),
                title: this.translate.instant("generic.WARNING")
              }
              this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
            }

            this.finalSelfDefinedObjectivesToDelete.length = 0;

            resolve();
          }, (err?: any) => {
            resolve();
            const messageObj: ApplicationModalMessage = {
              modalId: "pdm004",
              text: this.translate.instant("errors." + err?.message),
              title: this.translate.instant("generic.WARNING")
            }
            this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          });
      } else {
        resolve();
      }
    })
  }

  saveFinalSelfDefinedObjectives() {
    return new Promise<void>((resolve, reject) => {
      if (!this.isFinalSelfDefinedObjectivesChanged()) {
        resolve();
      } else {
        let deleteFinalSelfDefinedObjectivesPromise = this.deleteFinalSelfDefinedObjectives();

        deleteFinalSelfDefinedObjectivesPromise.then(() => {
          if (this.finalEvaluation.selfDefinedObjectives && this.finalEvaluation.selfDefinedObjectives.length) {
            let goals = [];
            for (let i = 0; i < this.finalEvaluation.selfDefinedObjectives.length; i++) {
              goals.push({
                goalId: this.finalEvaluation.selfDefinedObjectives[i].id,
                text: this.finalEvaluation.selfDefinedObjectives[i].title
              });
            }

            this.peopleAppraisalService.updatePerformanceAssessmentGoalsBySubordinateForManager(this.userId, goals)
              .subscribe((data: SenecaResponse<any>) => {
                if (data && data.error) {
                  const messageObj: ApplicationModalMessage = {
                    modalId: "em001",
                    text: this.translate.instant("errors." + data.error),
                    title: this.translate.instant("generic.WARNING")
                  }
                  this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
                }

                resolve();
              }, (err?: any) => {
                const messageObj: ApplicationModalMessage = {
                  modalId: "pdm006",
                  text: this.translate.instant("errors." + err?.message),
                  title: this.translate.instant("generic.WARNING")
                }
                this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
                resolve();
              });
          } else {
            resolve();
          }
        })
      }
    })
  }

  saveFinalEvaluationComment() {
    return new Promise<void>((resolve, reject) => {
      if (!this.isFinalCommentChanged()) {
        resolve();
      } else {
        this.editFinalEvaluationComment = false;
        this.peopleAppraisalService.createOrUpdatePerformanceAssessmentFinalEvaluationCommentForManager(this.userId, this.finalEvaluation.comment)
          .subscribe((data: SenecaResponse<any>) => {
            if (data && data.error) {
              const messageObj: ApplicationModalMessage = {
                modalId: "em001",
                text: this.translate.instant("errors." + data.error),
                title: this.translate.instant("generic.WARNING")
              }
              this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
            }

            resolve();
          }, (err?: any) => {
            const messageObj: ApplicationModalMessage = {
              modalId: "pdm006",
              text: this.translate.instant("errors." + err?.message),
              title: this.translate.instant("generic.WARNING")
            }
            this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
            resolve();
          });
      }
    })
  }

  // Salva i dati dell'utimo step
  saveFinalEvaluationStep(preventReload?: boolean) {
    return new Promise((resolve: Function, reject: Function) => {
      // Textarea delle note e dei commenti
      this.isLoadingFinalEvaluationData = true;
      let saveFinalEvaluationCommentPromise = this.saveFinalEvaluationComment();

      saveFinalEvaluationCommentPromise.then(() => {
        this.finalEvaluationCommentCopy = JSON.parse(JSON.stringify(this.finalEvaluation.comment));

        // Valutazione comportamenti organizzativi
        let saveFinalBehaviourCommentPromise = this.saveFinalBehaviourComment();
        saveFinalBehaviourCommentPromise.then(() => {
          this.editBehaviorComment = false;
          const comment = this.finalEvaluation.behaviorData && this.finalEvaluation.behaviorData.comment || '';
          this.finalEvaluationBehaviourCommentCopy = JSON.parse(JSON.stringify(comment));

          // Valutazione degli obiettivi
          let saveFinalEvaluationDataPromise = this.saveFinalEvaluationData();
          saveFinalEvaluationDataPromise.then(() => {
            this.finalEvaluation.emptyEvaluatedObjective = this.anyEmptyEvaluation();
            this.finalEvaluationDataCopy = JSON.parse(JSON.stringify(this.finalEvaluation.evaluatedData));

            // Valutazione competenze tecniche
            let saveFinalBehaviourCompetenceCommentPromise = this.saveFinalBehaviourCompetenceComment();
            saveFinalBehaviourCompetenceCommentPromise.then(() => {
              this.editCompetenceComment = false;
              const techComment = this.finalEvaluation.competenceData && this.finalEvaluation.competenceData.comment || '';
              this.finalEvaluationCompetenceCommentCopy = JSON.parse(JSON.stringify(techComment));

              // Obiettivi di sviluppo personale
              let saveFinalDefinedObjectivesPromise = this.saveFinalDefinedObjectives();
              saveFinalDefinedObjectivesPromise.then(() => {
                this.finalDefinedObjectivesCopy = JSON.parse(JSON.stringify(this.finalEvaluation.definedObjectives));

                // Obiettivi di sviluppo personale
                let saveFinalSelfDefinedObjectivesPromise = this.saveFinalSelfDefinedObjectives();
                saveFinalSelfDefinedObjectivesPromise.then(() => {
                  this.finalSelfDefinedObjectivesCopy = JSON.parse(JSON.stringify(this.finalEvaluation.selfDefinedObjectives));
                  if (!preventReload) {
                    this.getFinalEvaluationData();
                    this.showSaveSuccessfulToaster();
                  }

                  this.isLoadingFinalEvaluationData = false;
                  resolve();
                });
              });
            });
          });
        });
      });
    });
  }

  isSendEvalBtnVisibleAndEnabled() {
    return !this.isScheduleShared && !this.isPostCalibration && !this.isFinalStepCompleted
      && !this.isLoadingFinalEvaluationData && this.allStepCompleted();
  }

  isConfirmShareBtnVisibleAndEnabled() {
    return this.isPostCalibration && !this.isFinalStepCompleted
      && !this.isLoadingFinalEvaluationData;
  }

  isFinalEvaluationDataChanged() {
    let isChanged = false;

    if (this.finalEvaluation && this.finalEvaluation.evaluatedData && this.finalEvaluation.evaluatedData.length) {
      for (let i = 0, dataLength = this.finalEvaluation.evaluatedData.length; i < dataLength; i++) {
        const currentData = this.finalEvaluation.evaluatedData[i];

        if (isChanged) {
          break;
        }

        if (currentData && currentData.evaluationItemId) {
          for (let t = 0, originalDataLength = this.finalEvaluationDataCopy.length; t < originalDataLength; t++) {
            const currentOriginalData = this.finalEvaluationDataCopy[t];

            if (currentOriginalData && currentOriginalData.evaluationItemId
              && currentOriginalData.evaluationItemId === currentData.evaluationItemId) {

              if (currentOriginalData.evaluation !== currentData.evaluation) {
                isChanged = true;
              }
              break;
            }
          }
        }
      }
    }

    return isChanged;
  }

  isFinalDefinedObjectivesChanged() {
    let isChanged = false;

    // Controllo prima la lunghezza (magari qualche obiettivo è stato eliminato)
    const definedObjectivesCounter = this.finalEvaluation && this.finalEvaluation.definedObjectives && this.finalEvaluation.definedObjectives.length || 0;
    const originalDefinedObjectivesCounter = this.finalDefinedObjectivesCopy && this.finalDefinedObjectivesCopy.length || 0;

    if (definedObjectivesCounter != originalDefinedObjectivesCounter) {
      isChanged = true;
    } else {
      if (definedObjectivesCounter) {
        for (let i = 0; i < definedObjectivesCounter; i++) {
          const currentData = this.finalEvaluation.definedObjectives[i];

          if (isChanged) {
            break;
          }

          if (currentData && currentData.id) {
            for (let t = 0; t < originalDefinedObjectivesCounter; t++) {
              const currentOriginalData = this.finalDefinedObjectivesCopy[t];

              if (currentOriginalData && currentOriginalData.id
                && currentOriginalData.id === currentData.id) {

                if (currentOriginalData.title !== currentData.title) {
                  isChanged = true;
                }
                break;
              }
            }
          }
        }
      }
    }

    return isChanged;
  }

  isFinalSelfDefinedObjectivesChanged() {
    let isChanged = false;

    // Controllo prima la lunghezza (magari qualche obiettivo è stato eliminato)
    const definedObjectivesCounter = this.finalEvaluation && this.finalEvaluation.selfDefinedObjectives && this.finalEvaluation.selfDefinedObjectives.length || 0;
    const originalDefinedObjectivesCounter = this.finalSelfDefinedObjectivesCopy && this.finalSelfDefinedObjectivesCopy.length || 0;

    if (definedObjectivesCounter != originalDefinedObjectivesCounter) {
      isChanged = true;
    } else {
      if (definedObjectivesCounter) {
        for (let i = 0; i < definedObjectivesCounter; i++) {
          const currentData = this.finalEvaluation.selfDefinedObjectives[i];

          if (isChanged) {
            break;
          }

          if (currentData && currentData.id) {
            for (let t = 0; t < originalDefinedObjectivesCounter; t++) {
              const currentOriginalData = this.finalSelfDefinedObjectivesCopy[t];

              if (currentOriginalData && currentOriginalData.id
                && currentOriginalData.id === currentData.id) {

                if (currentOriginalData.title !== currentData.title) {
                  isChanged = true;
                }
                break;
              }
            }
          }
        }
      }
    }

    return isChanged;
  }

  isFinalBehaviourCommentChanged() {
    let isChanged = false;

    if (this.finalEvaluation && this.finalEvaluation.behaviorData && this.finalEvaluation.behaviorData.comment
      && this.finalEvaluation.behaviorData.comment !== this.finalEvaluationBehaviourCommentCopy) {
      isChanged = true;
    }

    return isChanged;
  }

  isFinalBehaviourCompetenceCommentChanged() {
    let isChanged = false;

    if (this.finalEvaluation && this.finalEvaluation.competenceData && this.finalEvaluation.competenceData.comment
      && this.finalEvaluation.competenceData.comment !== this.finalEvaluationCompetenceCommentCopy) {
      isChanged = true;
    }

    return isChanged;
  }

  isFinalCommentChanged() {
    let isChanged = false;

    if (this.finalEvaluation && this.finalEvaluation.comment
      && this.finalEvaluation.comment !== this.finalEvaluationCommentCopy) {
      isChanged = true;
    }

    return isChanged;
  }

  // Verifica se qualcosa nello step 5 (riepilogo) è cambiato e non è stato salvato
  anythingToSaveInFinalEvaluation() {
    if (this.isFinalCommentChanged() || this.isFinalBehaviourCommentChanged()
      || this.isFinalEvaluationDataChanged() || this.isFinalBehaviourCompetenceCommentChanged()
      || this.isFinalDefinedObjectivesChanged() || this.isFinalSelfDefinedObjectivesChanged()) {
      return true;
    }
    return false;
  }

  // Porta indietro di uno step
  goToPrevStep(skipCheck?: boolean) {
    this.onStepClicked(this.stepList[this.currentStep.number - 2], skipCheck);
  }

  goToNextStep(skipCheck?: boolean) {
    this.onStepClicked(this.stepList[this.currentStep.number], skipCheck);
  }

  goToAnswerPeerFeedback(feedbackId?: string) {
    this.router.navigate(['/peopleAppraisal/createUpdatePeerFeedback/response/' + this.userId + '/' + feedbackId])
  }

  /**  Type guard per definire se un feedback common è peer feedback */
  isPeerFeedback(feedback: PerfAlloyCommonFeedback): feedback is PerfAlloyPeerFeedback {
    return feedback.feedbackType === PerfAlloyFeedbackTypes.PERFALLOY_PEER_FEEDBACK;
  }
  isSmartFeedback(feedback: PerfAlloyCommonFeedback): feedback is PerfAlloySmartFeedback {
    return feedback.feedbackType === PerfAlloyFeedbackTypes.PERFALLOY_SMART_FEEDBACK;
  }
  isFeedbackToManager(feedback: PerfAlloyCommonFeedback): feedback is PerfAlloyManagerFeedback {
    return feedback.feedbackType === PerfAlloyFeedbackTypes.PERFALLOY_MANAGER_FEEDBACK;
  }

  // Recupero il count delle Tab
  getCounterFeedbackTabs() {
    if (!this.selectedYear) {
      console.log("No selected year");
      return;
    }

    //Inizio la chimata per recuperare gli utenti 
    this.peopleAppraisalService.getCounterFeedbackTabs(this.selectedYear.perfYear, this.selectedYear.perfYearCode, this.userId)
      .subscribe((data: SenecaResponse<PeerFeedbackServiceResponses.CountNotesAndFeedbacksForManager>) => {
        if (data && data.response) {
          this.counterTabs = data.response;
          this.menuButtons = [
            {
              id: 'pas',
              name: this.translate.instant('headerDossier.CARD')
            },
            {
              id: 'feedback',
              name: this.translate.instant('headerDossier.FEEDBACK_TITLE'),
              count: this.counterTabs.feedbacks.toString()
            },
            {
              id: 'notes',
              name: this.translate.instant('headerDossier.PRIVATE_NOTE'),
              count: this.counterTabs.privateNotes.toString()
            }]
          this.menuButtons = JSON.parse(JSON.stringify(this.menuButtons));
          this.isLoadingPersonDetails = false;
        } else {
          // Vedo se c'è la traduzione dell'errore
          const messageObj: ApplicationModalMessage = {
            modalId: "count2",
            text: this.translate.instant("errors." + data.error),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          this.isLoadingPersonDetails = false;
        }

      }, (err?: any) => {
        const messageObj: ApplicationModalMessage = {
          modalId: "count1",
          text: this.translate.instant("errors." + ((err && err.message) || err)),
          title: this.translate.instant("generic.WARNING")
        }
        this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        this.isLoadingPersonDetails = false;
      });
  }

  selectedItem(filter: any, selectInfo: { id: string, title: string }) {
    // cambio il filtro selezionato
    filter.selected = selectInfo;

    // Assegno il filtro in base al tipo di feedback
    if (selectInfo.id == PerfAlloyFeedbackTypes.PERFALLOY_SMART_FEEDBACK || selectInfo.id == PerfAlloyFeedbackTypes.PERFALLOY_PEER_FEEDBACK || selectInfo.id == PerfAlloyFeedbackTypes.PERFALLOY_MANAGER_FEEDBACK) {
      // Incremento il counter solo se non è stato selezionato nulla
      if (!this.feedbackType.length) {
        this.filterCounter++;
      }

      this.feedbackType = [selectInfo.id];
    }

    // Assegno il filtro in base allo stato del feedback
    if (selectInfo.id == CommonClasses.PERFALLOY_FEEDBACK_STATUS.RECEIVED ||
      selectInfo.id == CommonClasses.PERFALLOY_FEEDBACK_STATUS.SENT ||
      selectInfo.id == CommonClasses.PERFALLOY_FEEDBACK_STATUS.TO_SEND ||
      selectInfo.id == CommonClasses.PERFALLOY_FEEDBACK_STATUS.WAITING) {

      // Incremento il counter solo se non è stato selezionato nulla
      if (!this.feedbackStatus) {
        this.filterCounter++;
      }

      // imposto il nuovo id al filtro 
      this.feedbackStatus = selectInfo.id;
    }

    if (selectInfo.id == YEARS_FILTER.LAST_YEAR ||
      selectInfo.id == YEARS_FILTER.LAST_2_YEARS ||
      selectInfo.id == YEARS_FILTER.LAST_3_YEARS ||
      selectInfo.id == YEARS_FILTER.LAST_4_YEARS ||
      selectInfo.id == YEARS_FILTER.ALL) {

      switch (selectInfo.id) {
        case YEARS_FILTER.LAST_YEAR:
          if (this.feedbackMinCreationDate.id !== YEARS_FILTER.LAST_YEAR) {
            this.filterCounter--;
          }
          this.feedbackMinCreationDate = {
            id: YEARS_FILTER.LAST_YEAR,
            date: moment().subtract(1, 'years').toISOString()
          }
          break;
        case YEARS_FILTER.LAST_2_YEARS:
          if (this.feedbackMinCreationDate.id == YEARS_FILTER.LAST_YEAR) {
            this.filterCounter++;
          }
          this.feedbackMinCreationDate = {
            id: YEARS_FILTER.LAST_2_YEARS,
            date: moment().subtract(2, 'years').toISOString()
          }
          break;
        case YEARS_FILTER.LAST_3_YEARS:
          if (this.feedbackMinCreationDate.id == YEARS_FILTER.LAST_YEAR) {
            this.filterCounter++;
          }
          this.feedbackMinCreationDate = {
            id: YEARS_FILTER.LAST_3_YEARS,
            date: moment().subtract(3, 'years').toISOString()
          }
          break;
        case YEARS_FILTER.LAST_4_YEARS:
          if (this.feedbackMinCreationDate.id == YEARS_FILTER.LAST_YEAR) {
            this.filterCounter++;
          }
          this.feedbackMinCreationDate = {
            id: YEARS_FILTER.LAST_4_YEARS,
            date: moment().subtract(4, 'years').toISOString()
          }
          break;
        case YEARS_FILTER.ALL:
          if (this.feedbackMinCreationDate.id == YEARS_FILTER.LAST_YEAR) {
            this.filterCounter++;
          }
          this.feedbackMinCreationDate = {
            id: YEARS_FILTER.ALL,
            date: ''
          }
          break;
      }
    }

    // Reset filtri
    if (selectInfo.id == 'ALL_STATUS') {
      this.feedbackStatus = undefined;
      this.filterCounter--;
    }

    if (selectInfo.id == 'ALL_TYPE') {
      this.feedbackType = [];
      this.filterCounter--;
    }
    this.getFeedbacksForManager();
  }



  ngOnDestroy(): void {
    if (this.clonePerformanceAssessmentGoalsByGoalIds$) {
      this.clonePerformanceAssessmentGoalsByGoalIds$.unsubscribe();
    }
    if (this.getYearsList$) {
      this.getYearsList$.unsubscribe();
    }
    if (this.loggedUser$) {
      this.loggedUser$.unsubscribe();
    }
    if (this.routeParams$) {
      this.routeParams$.unsubscribe();
    }
    if (this.personDetails$) {
      this.personDetails$.unsubscribe();
    }
    if (this.getPersonDetails$) {
      this.getPersonDetails$.unsubscribe();
    }
    if (this.getUserProfileTab$) {
      this.getUserProfileTab$.unsubscribe();
    }
    if (this.getRunningPhase$) {
      this.getRunningPhase$.unsubscribe();
    }
    // if (this.getPrivateNotesList$) {
    //   this.getPrivateNotesList$.unsubscribe();
    // }
    if (this.getUserGoalToEvaluate$) {
      this.getUserGoalToEvaluate$.unsubscribe();
    }
    if (this.getUserGoalToDefine$) {
      this.getUserGoalToDefine$.unsubscribe();
    }
    if (this.createOrUpdatePerformanceAssessmentGoalsForManager$) {
      this.createOrUpdatePerformanceAssessmentGoalsForManager$.unsubscribe();
    }
    if (this.deletePerformanceAssessmentGoalsForManager$) {
      this.deletePerformanceAssessmentGoalsForManager$.unsubscribe();
    }
    if (this.createOrUpdatePerformanceAssessmentBehaviorEvaluationForManager$) {
      this.createOrUpdatePerformanceAssessmentBehaviorEvaluationForManager$.unsubscribe();
    }
    if (this.getFinalEvaluationData$) {
      this.getFinalEvaluationData$.unsubscribe();
    }
    if (this.createOrUpdatePerformanceAssessmentGalEvaluationForManager$) {
      this.createOrUpdatePerformanceAssessmentGalEvaluationForManager$.unsubscribe();
    }
    if (this.getBehavioursToEvaluateForManager$) {
      this.getBehavioursToEvaluateForManager$.unsubscribe();
    }
    if (this.finalShare$) {
      this.finalShare$.unsubscribe();
    }
    if (this.subordinatesWithPhaseStatuses$) {
      this.subordinatesWithPhaseStatuses$.unsubscribe();
    }
    if (this.getMySubordinateUserData$) {
      this.getMySubordinateUserData$.unsubscribe();
    }

    this.removeTutorialsRefFromSessionStorage();
  }
}