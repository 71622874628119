<div class="page-content-wrapper process">
    <!-- Se ho finito di caricare i dati dell'utente -->
    <header-dossier [isAdminHeader]="true" [disabled]="true" [hasTabs]="false"
        [adminPageTitle]="'process.TITLE' | translate" [adminPageSubtitle]="'setupCompetences.PROCESS_LIST' | translate"
        [adminBack]="true" [adminActionLabel]="'process.CREATE_NEW' | translate" (onAdminAction)="goToCreateProcess()"
        adminActionLabelType="success">
    </header-dossier>

    <!-- Home lista processi -->
    <div class="main-content-wrapper admin">
        <!-- contenitore tabella -->
        <ng-container *ngIf="!isLoadingProcessData">
            <div class="admin-table-container">
                <div class="table-content">
                    <table>
                        <thead>
                            <tr>
                                <th translate="{{ ('adminTable.SEMESTER' | translate)}}"  style="width:20%"></th>
                                <th translate="{{ ('adminTable.PROCESS_NAME' | translate)}}"  style="width:50%"></th>
                                <th translate="{{ ('adminTable.PEOPLE_INVOLVED' | translate)}}"  style="width:20%"></th>
                                <th translate="{{ ('adminTable.STATUS' | translate)}}"></th>
                                <th translate="{{ ('adminTable.ACTIONS' | translate)}}" ></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let process of processData.list | 
                                slice:0:processData.numRecords | 
                                paginate: { id: 'processList',
                                            itemsPerPage: processData.numRecords,
                                            currentPage: processData.page,
                                            totalItems: processData.counter }">
                                <td>{{ process.processYearCode + ' ' + process.processYear }}</td>
                                <td>{{ process.name }}</td>
                                <td>{{ process.totalParticipants || 0 }}</td>
                                <td>
                                    <item-list-team-status [type]="process.statusType"
                                        [message]="process.statusMessage" styleItem="fill"></item-list-team-status>
                                </td>
                                <td class="detail" (click)="goToProcessDetails(process)">
                                    {{ 'adminTable.ACCESS' | translate }}
                                </td>
                            </tr>

                        </tbody>
                    </table>
                </div>
                <div class="bottom-actions">
                    <table-pagination id="processList" (pageChanged)="processPageChanged($event)" [noPadding]="true">
                    </table-pagination>
                    <div class="per-page-selector">
                        <p class="select-label" translate="generic.PER_PAGE_ROW"></p>
                        <alloy-select [inMinWidthForIe]="true" (onSelectedItem)="changeNumRecords($event)"
                            [selectedItem]="selectedRows" [items]="rowPerPageOptions" [searchable]="false"
                            bindLabel="id" [tablePagination]="true">
                        </alloy-select>
                    </div>
                </div>
            </div>
        </ng-container>
        <!-- loader -->
        <ng-container *ngIf="isLoadingProcessData">
            <div class="loader-container">
                <div class="loader spinner-big"></div>
            </div>
        </ng-container>
    </div>
</div>