<div class="page-content">

    <div id="snackbar">
        <p translate="generic.SAVE_SUCCESSFUL"></p>
    </div>

    <div id="snackbar-delete">
        <p translate="generic.DELETE_SUCCESSFUL"></p>
    </div>

    <ng-container *ngIf="!isLoadingData">
        <div class="competence-type-container">
            <ng-container *ngFor="let tab of competenceTypeList">
                <tab-light [tabTitle]="tab.title" [isDisabled]="tab.isDisabled"
                    (onClick)="changeCurrentCompetenceType(tab)" [blackHighlight]="true"></tab-light>
            </ng-container>
        </div>

        <!-- Barra di ricerca -->
        <div class="search-filters-container">
            <div class="admin-search-container bottom-filters">
                <div class="search-wrapper">
                    <alloy-input (onIconClicked)="onSearch()" typeInput="text" [bindValue]="searchedText"
                        (onModelChanged)="searchedTextChanged($event)" iconRight="assets/img/icons/search.svg"
                        [placeholder]="'setupCompetences.SEARCH_BY_COMPETENCE' | translate"
                        [isSearchBar]="true">
                    </alloy-input>
                </div>
            </div>
        </div>

        <div class="action-row">
            <div class="actions-container">
                <svg-icon [src]="'assets/img/icons/xls.svg'"></svg-icon>
                <p translate="setupCompetences.DOWNLOAD_TRACK" (click)="downloadTrack()"></p>
            </div>
            <div *ngIf="isBehaviorList" class="actions-container">
                <svg-icon [src]="'assets/img/icons/xls.svg'"></svg-icon>
                <p translate="setupCompetences.IMPORT_BEHAVIOR" (click)="openImportModal('soft')"></p>
            </div>
            <div *ngIf="!isBehaviorList" class="actions-container">
                <svg-icon [src]="'assets/img/icons/xls.svg'"></svg-icon>
                <p translate="setupCompetences.IMPORT_SKILL" (click)="openImportModal('tech')"></p>
            </div>
            <alloy-button
                [label]="'setupCompetences.ADD_SKILL' | translate"
                type="black-border" (onButtonClicked)="goToNewCompetence()" [disabled]="isLoadingData"
                size="auto-width">
            </alloy-button>
        </div>
        <!-- Tabella tutte le competenze - Behavior -->
        <div class="admin-table-container">
            <ng-container *ngIf="!isLoadingData && dataObject && dataObject.list && dataObject.list.length > 0">
                <div class="table-content">
                    <table>
                        <thead>
                            <tr>
                                <th>
                                    <alloy-check (onCheck)="selectAll()"></alloy-check>
                                </th>
                                <ng-container >
                                    <th *ngIf="isBehaviorList" translate="{{ ('adminTable.TITLE_SOFT' | translate)}}" style="width:40%"></th>
                                    <th *ngIf="!isBehaviorList" translate="{{ ('adminTable.TITLE_TECH' | translate)}}" style="width:40%"></th>
                                </ng-container>
                                <th translate="{{ ('adminTable.MACRO_COMPETENCE' | translate)}}" style="width:25%"></th> 
                                <th translate="{{ ('adminTable.DESCRIPTION' | translate)}}" class="xs-col"></th> 
                                <th translate="{{ ('adminTable.ROLES' | translate)}}" class="xs-col"></th>
                                <th translate="{{ ('adminTable.ACTIONS' | translate)}}"></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let data of dataObject.list | slice:0:dataObject.numRecords | paginate: { id: 'dataList',
                            itemsPerPage: dataObject.numRecords,
                            currentPage: dataObject.page,
                            totalItems: dataObject.counter }">
                                <td>
                                    <alloy-check id="data.id" [checked]="data.isChecked" (click)="selectData(data)">
                                    </alloy-check>
                                </td>
                                <td>{{data.title}}</td>
                                <td>--</td>
                                <td class="xs-col">{{ (data.hasDescription ? 'generic.YES' : 'generic.NO') | translate}}</td>
                                <td class="xs-col">
                                    {{ data.roleCount }}
                                </td>
                                <td class="action">
                                    <svg-icon class="more-icon" [applyClass]="true" (click)="openCloseMenu(data)"
                                        (clickOutside)="closeMenu(data)" src="/assets/img/icons/more-horizontal.svg">
                                    </svg-icon>
                                    <ng-container *ngIf="data.isMenuOpen">
                                        <div class="dropdown-menu larger">
                                            <ng-container *ngFor="let option of menuOptions">
                                                <alloy-dropdown-item (click)="onMenuOptionsClicked(option, data)"
                                                    class="full-width" [item]="option">
                                                </alloy-dropdown-item>
                                            </ng-container>
                                        </div>
                                    </ng-container>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="bottom-actions">
                    <table-pagination id="dataList" (pageChanged)="pageChanged($event)" [noPadding]="true">
                    </table-pagination>
                    <div class="per-page-selector">
                        <p class="select-label" translate="generic.PER_PAGE_ROW"></p>
                        <alloy-select [inMinWidthForIe]="true" (onSelectedItem)="changeNumRecords($event)"
                            [selectedItem]="selectedRows" [items]="rowPerPageOptions" [searchable]="false"
                            bindLabel="id" [tablePagination]="true">
                        </alloy-select>
                    </div>
                </div>
            </ng-container>

            <ng-container *ngIf="!isLoadingData && (!dataObject || !dataObject.list || !dataObject.list.length)">
                <div class="no-data-placeholder">
                    <p translate="generic.NO_RESULTS"></p>
                </div>
            </ng-container>
            <ng-container *ngIf="isLoadingData">
                <div class="loader-container">
                    <div class="loader spinner-big"></div>
                </div>
            </ng-container>
        </div>
    </ng-container>

    <!-- loader contenuto tab all -->
    <ng-container *ngIf="isLoadingData">
        <div class="loader-container">
            <div class="loader spinner-big"></div>
        </div>
    </ng-container>
</div>


<!-- Modale elimina competenza / comportamento -->
<modal id="delete-data" (onClose)="closeDeleteDataModal()" (onConfirm)="closeDeleteDataModal(true)"
    [confirmLabel]="'generic.CONFIRM' | translate" (onCancel)="closeDeleteDataModal()"
    [cancelLabel]="'generic.CANCEL' | translate">
    <ng-container *ngIf="selectedData && selectedData.length <= 1">
        <modal-text-content modalId="delsc001"
            [title]="(isBehaviorList ? 'setupCompetences.modals.DELETE_SOFT_COMPETENCE_TITLE' : 'setupCompetences.modals.DELETE_TECH_COMPETENCE_TITLE') | translate"
            [text]="'setupCompetences.modals.DELETE_COMPETENCE_DESCR' | translate">
        </modal-text-content>
    </ng-container>
    <ng-container *ngIf="selectedData && selectedData.length > 1">
        <modal-text-content modalId="delsc001"
            [title]="(isBehaviorList ? 'setupCompetences.modals.DELETE_BEHAVIOR_TITLE_MANY' : 'setupCompetences.modals.DELETE_COMPETENCE_TITLE_MANY') | translate"
            [text]="(isBehaviorList ? 'setupCompetences.modals.DELETE_BEHAVIOR_DESCR_MANY' : 'setupCompetences.modals.DELETE_COMPETENCE_DESCR_MANY') | translate">
        </modal-text-content>
    </ng-container>
</modal>

<!-- Modale inclusione persone -->
<modal id="import-modal" (onClose)="closeImportModal()" (onConfirm)="closeImportModal(true)"
    [confirmLabel]="( (isBehaviorList ? 'setupCompetences.IMPORT_BEHAVIOR' : 'setupCompetences.IMPORT_SKILL') | translate)"
    [cancelLabel]="'generic.CANCEL' | translate"
    (onCancel)="closeImportModal()">
    <div class="sti-modal-container">
        <p class="title"
            [translate]="isBehaviorList ? 'setupCompetences.modals.IMPORT_SOFT_SKILL_TITLE' : 'setupCompetences.modals.IMPORT_TECH_SKILL_TITLE'">
        </p>
        <div class="divisor-line"></div>

        <div class="modal-action-container">
            <alloy-button [label]="'setupCompetences.DOWNLOAD_TRACK' | translate" type="success bordless" size="medium"
                (onButtonClicked)="downloadTrack()">
            </alloy-button>
        </div>

        <!-- Upload lista persone da includere -->
        <input-container [isWithoutInfoIcon]="true" [isValued]="importFile" [isObbligatory]="true"
            [title]="'setupCompetences.modals.IMPORT_SKILL_DESCR' | translate">
            <alloy-upload [isToReset]="resetIncludeUpload" (onUpload)="assignFile($event)"
                [acceptedMimeTypes]="'.xls .xlsx'"></alloy-upload>
        </input-container>

        <ng-container *ngIf="importFile">
            <div class="confirm-info">
                <ng-container *ngIf="isLoadingImport">
                    <div class="loader spinner-medium"></div>
                </ng-container>

                <ng-container *ngIf="!isLoadingImport">
                    <p class="title">{{ (isBehaviorList ? 'setupCompetences.modals.WANT_IMPORT_BEHAVIOR' :
                        'setupCompetences.modals.WANT_IMPORT_SKILL') | translate }}</p>
                    <p class="text" [innerHTML]="importNotice"></p>
                </ng-container>
            </div>
        </ng-container>
    </div>
</modal>